import React from 'react';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFile, faTimes } from '@fortawesome/pro-solid-svg-icons';
import DataContext from '../../DataContext/DataContext';
import './Header.scss';

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  
  render() {
    // const { toggleReportMode, reportMode, hideAnalyses, } = this.context;
    const { title } = this.props;
    return (
      <div className="header">
        <div className="header__menu">
          <div className="header__menu-left">
            { title }
          </div>
        { /**   
          <div className="header__menu-right">
            {!reportMode && hideAnalyses
            && (
              <div className="header__menu-report-button" onClick={() => toggleReportMode()}>
                <FontAwesomeIcon icon={faFile} />
                <span className="header__button-text">Create Report</span>
              </div>
            )}
            {reportMode
            && (
              <div className="header__menu-exit-report-button" onClick={() => toggleReportMode()}>
                <span className="header__button-text">Exit Report</span>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
          </div> **/
        }
        </div>
      </div>
    );
  }
}

HeaderContainer.defaultProps = {
  title: 'IPC Visualization Dashboard',
};

HeaderContainer.propTypes = {
  title: PropTypes.string,
};

HeaderContainer.contextType = DataContext;

export default HeaderContainer;
