import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';

import './AtlasMarkerDraggable.scss';

import { ReactComponent as bag } from '../../../../icons/bag.svg';
import { ReactComponent as bag2 } from '../../../../icons/bag2.svg';
import { ReactComponent as idp } from '../../../../icons/idp.svg';
import { ReactComponent as urb } from '../../../../icons/urb.svg';
import { ReactComponent as rfg } from '../../../../icons/urb.svg';
import { ReactComponent as exclaimation } from '../../../../icons/exclamation.svg';

class AtlasMarkerDraggable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      latitude: this.props.latitude,
      longitude: this.props.longitude,
    };
  }

  onMarkerDrag = event => {
    this.setState({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });
  };

  onMarkerDragEnd = event => {
    this.setState({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        analysisId: this.props.anl_id,
        analysisAreaId: this.props.area,
        ipcWorksheet6SummaryId: this.props.id,
        ipcPeriod: this.props.period,
        iconType: this.props.icon,
        latitude: event.lngLat[1],
        longitude: event.lngLat[0],
      }),
    };

    // fetch('http://iss.ipcinfo.org/gsu/api/analysisicon.json/', requestOptions)
    fetch(
      `${process.env.REACT_APP_ISS_URL}${this.props.country}/api/analysisicon.json/`,
      requestOptions
    )
      .then(r => r.json())
      .then(d => console.log(d));
  };

  componentDidMount = () => {
    const { latitude, longitude } = this.props;
    this.setState({ latitude, longitude });
  };

  render() {
    const icons = { bag, bag2, idp, urb, exclaimation, rfg };
    const Icon = icons[this.props.icon];
    return (
      <Marker
        key={this.props.area}
        offsetLeft={this.props.icon === 'idp' ? -25 : -10}
        offsetTop={-10}
        longitude={this.state.longitude}
        latitude={this.state.latitude}
        draggable
        onDragEnd={this.onMarkerDragEnd}
        // onDrag={this.onMarkerDrag}
      >
        <div className={`iconMarker phase${this.props.phase} ${this.props.icon}`}>
          <Icon style={{ maxWidth: '60%', maxHeight: '60%' }} />
          <div className="probe">{this.props.name}</div>
        </div>
      </Marker>
    );
  }
}

AtlasMarkerDraggable.propTypes = {
  id: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  area: PropTypes.string,
  icon: PropTypes.string,
  anl_id: PropTypes.string,
  period: PropTypes.string,
  country: PropTypes.string,
  name: PropTypes.string,
};

export default AtlasMarkerDraggable;
