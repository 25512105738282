import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import LegendTable from '../../LegendTable/LegendTable';

class ChronicPhaseTable extends React.Component {
  renderRows() {
    const {
      populationByPhase,
      hover,
      updateHighlight,
    } = this.props;
    const populationFormat = d3.format(',d');
    // const reversed = populationByPhase.concat().reverse();
    const reversed = populationByPhase.concat();
    return reversed.map((d) => {
      const {  phase, population, percent,} = d;
      return (
        <div
          className={`${phase === hover ? 'hover' : ''}`}
          key={phase}
          onMouseOver={() => { updateHighlight(phase); }}
          onFocus={() => { updateHighlight(phase); }}
          onMouseOut={() => { updateHighlight(null); }}
          onBlur={() => { updateHighlight(null); }}
        >
          <div className={`swatch chronic-phase${phase}`} />
          <div className="phase">{`Level ${phase}`}</div>
          <div className="population-data flex">{populationFormat(population)}</div>
          <div className="percent-data">{d3.format('d')(percent * 100)}</div>
        </div>
      );
    });
  }

  render() {
    const headerRow = (
      <div className="header-row" key="header-row">
        <div className="chronic-phase">Level</div>
        <div className="population-header flex">Pop</div>
        <div className="percent">%</div>
      </div>
    );
    return (
      <LegendTable header>
        {[headerRow].concat(this.renderRows())}
      </LegendTable>
    );
  }
}

ChronicPhaseTable.propTypes = {
  populationByPhase: PropTypes.arrayOf(PropTypes.object).isRequired,
  hover: PropTypes.number,
  updateHighlight: PropTypes.func.isRequired,
};

ChronicPhaseTable.defaultProps = {
  hover: null,
};

export default ChronicPhaseTable;
