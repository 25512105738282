import React from "react";
import PropTypes from 'prop-types';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { Tick , Handle, Track } from '../../../RangeSlider/RangeSlider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import './PopulationTrackingToolFilters.scss';
const _ = require('underscore');

function containsObject(obj, list) {
 var res = _.find(list, function(val){ return _.isEqual(obj, val)});
 return (_.isObject(res))? true : false;
}

const sliderStyle = {
  position: 'relative',
  width: '70%',
  height: 50,
  margin: '0px',
}
const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 10,
  marginTop: 10,
  borderRadius: 5,
  // backgroundColor: '#8B9CB6',
  backgroundColor: '#ddd',
  cursor: 'pointer',
}
const defaultValues = [2017, new Date().getFullYear()]
class PopulationTrackingSheetFilters extends React.Component {
    constructor(props) {
        super(props);
        const {regions, analysisPeriod, analysisCondition} = this.props;
        let countries = [];
        _.each(regions, (k, v) => k.map(e => { if( ! containsObject(e, countries) ) { countries.push(e)} return null; }))
        this.state = {
            condition: analysisCondition, 
            period: analysisPeriod,
            countries: _.sortBy(countries, 'name'),
            yearRange: defaultValues,
            defaultRegion: '',
            defaultCountry: '',
            values: defaultValues, //[defaultValues[1] - 1, defaultValues[1]],
            update: defaultValues.slice(),
            reversed: false,
        }
    }
    onRegionChange = (e) => {
        const {regions, updateSelectedRegion} = this.props;
        let countries = [];
        if(e.target.value !== '') {
           countries = regions[e.target.value].map(e => e)
        }
        else { 
            _.each(regions, (k, v) => k.map(e => { if( ! containsObject(e, countries) ) { countries.push(e)} return null; }))
        }
        this.setState({ defaultCountry: '',  defaultRegion: e.target.value, countries: _.sortBy(countries, 'name') });
        updateSelectedRegion(e.target.value);
    }
    onCountryChange = (e) => {
        const {regions, updateSelectedCountry, updateSelectedRegion} = this.props;
        if( ! e.target.value ) {
            const {defaultRegion} = this.state;
            let countries = [];
            if(defaultRegion){
                countries = regions[defaultRegion].map(e => e)
                updateSelectedRegion(defaultRegion);
            }
            else {
                _.each(regions, (k, v) => k.map(e => { if( ! containsObject(e, countries) ) { countries.push(e)} return null; }))
                updateSelectedCountry('');
            }
            this.setState({ defaultCountry: '' });
        }
        else {
            this.setState({ defaultCountry: e.target.value });
            updateSelectedCountry(e.target.value);
        }
    }
    toggleCondition = (e) => {
        const {updateCondition} = this.props;
        this.setState({ condition: e });
        updateCondition(e);
    }
    downloadExcel = () => {
        const {downloadCSV} = this.props;
        downloadCSV();
    }
    showDisclaimer = () => {
        const disclaimerDropdown = document.getElementById("pop-figures-disclaimer-button").nextSibling;
        if (disclaimerDropdown.classList) { 
            disclaimerDropdown.classList.toggle("show"); 
        } 
        else {
            // For IE9
          const classes = disclaimerDropdown.className.split(" ");
          const classIndex = classes.indexOf("show");
          if (classIndex >= 0) {
            classes.splice(classIndex, 1);
          }
          else {            
            classes.push("show");
            disclaimerDropdown.className = classes.join(" ");             
          }
        }
    }
    onUpdate = (range) => {
        this.setState({ update:range })
    }
    onChange = (values) => {
        const {updateYear} = this.props;
        this.setState({ values })
        updateYear(values);
    }
    render() {
        const {regions, activeCountries} = this.props;
        const {countries, yearRange, condition, values, reversed, defaultRegion, defaultCountry} = this.state;
        return (
          <React.Fragment>
            <div className="population-tracking-sheet-top-filters row">
              <div className="col-md-4">
                <span className="selectdiv">
                  <select onChange={this.onRegionChange} defaultValue={defaultRegion}>
                    <option value="">All Regions</option>
                    {Object.keys(regions).map(r => (
                      <option key={r} value={r}>
                        {r}
                      </option>
                    ))}
                  </select>
                </span>
                &nbsp;
                <span className="selectdiv">
                  <select onChange={this.onCountryChange} value={defaultCountry}>
                    <option value="">All Countries</option>
                    {countries.length &&
                      countries.map(c => {
                        if (activeCountries && activeCountries.includes(c.code)) {
                          return (
                            <option key={c.code} value={c.code}>
                              {c.name}
                            </option>
                          );
                        }
                        return '';
                      })}
                  </select>
                </span>
              </div>
              <div className="col-md-8">
                <Slider
                  mode={1}
                  step={1}
                  domain={yearRange}
                  reversed={reversed}
                  rootStyle={sliderStyle}
                  onUpdate={this.onUpdate}
                  onChange={this.onChange}
                  values={values}
                >
                  <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
                  <Handles>
                    {({ handles, getHandleProps }) => (
                      <div className="slider-handles">
                        {handles.map(handle => (
                          <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                        ))}
                      </div>
                    )}
                  </Handles>
                  <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                      <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                          <Track
                            key={id}
                            source={source}
                            target={target}
                            getTrackProps={getTrackProps}
                          />
                        ))}
                      </div>
                    )}
                  </Tracks>
                  <Ticks count={yearRange[1] - yearRange[0]}>
                    {({ ticks }) => (
                      <div className="slider-ticks">
                        {ticks.map(tick => (
                          <Tick key={tick.id} tick={tick} count={ticks.length} />
                        ))}
                      </div>
                    )}
                  </Ticks>
                </Slider>
              </div>
            </div>
            <div className="population-tracking-sheet-analysis-condition-period row">
              <div className="col-md-7 text-right">
                <div className="btn-group" role="group" aria-label="Analysis Condition">
                  <button
                    type="button"
                    className={`btn btn-sm btn-sm ${
                      condition === 'A' ? 'btn-secondary' : 'btn-outline-secondary'
                    }`}
                  >
                    Acute Food Insecurity
                  </button>
                  {/** <button onClick={() => this.toggleCondition('C')} type="button" className={`btn btn-sm btn-sm ${condition === 'C' ? 'btn-secondary' : 'btn-outline-secondary'}`}>Chronic Food Insecurity</button> **/}
                </div>
              </div>

              <div className="col-md-5 text-right" role="toolbar">
                <div className="btn-group dropleft" role="group">
                  <button
                    id="pop-figures-disclaimer-button"
                    onMouseEnter={e => this.showDisclaimer()}
                    onMouseLeave={e => this.showDisclaimer()}
                    className="btn btn-sm btn-outline-secondary"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    &nbsp;
                  </button>
                  <div className="dropdown-menu pop-figures-disclaimer-menu">
                    <div className="pop-figures-disclaimer">
                      <p>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        &nbsp; Disclaimer: All national population figures are based on official
                        country population estimates. IPC estimates are those published in country
                        IPC reports. It is acknowledged that, in some cases, due to rounding and
                        process related issues, figures at subnational level or for specific IPC
                        Phases may not add up to totals.{' '}
                      </p>
                      <p>
                        For IPC analyses conducted in Somalia, IDP population data is only included
                        at state level (administrative unit 1), not at district level.
                      </p>
                      <p>
                        For some IPC analyses conducted in Burundi (2017 and 2018 projections) and
                        DRC (June 2017), IPC estimates were not produced for each IPC Phase but
                        instead merged across two phases (e.g. IPC Phase 3 and 4 combined). In these
                        specific cases, IPC estimates only appear in one of the two IPC
                        Phases/columns.
                      </p>
                      <p>
                        At the request of the humanitarian community in Afghanistan, since the
                        October 2021 IPC analysis, the IPC has used Flowminder population estimates,
                        which are used for the annual HRP. Previous IPC analyses employed National
                        Statistics and Information Agency of Afghanistan (NSIA) population
                        estimates. This change ensures complete alignment with future HRPs.
                      </p>
                    </div>
                  </div>
                </div>
                &nbsp; &nbsp;
                <button
                  title="Export table to Excel"
                  onClick={() => {
                    this.downloadExcel();
                  }}
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                  &nbsp; Excel
                </button>
              </div>
            </div>
          </React.Fragment>
        );
    }
}

PopulationTrackingSheetFilters.defaultProps = {
    regions: [],
    activeCountries: [],
    years: [],
    analysisCondition: 'A',
    analysisPeriod: '',
    countryCode: []
};

PopulationTrackingSheetFilters.propTypes = {
    regions: PropTypes.object,
    activeCountries: PropTypes.array,
    years: PropTypes.arrayOf(PropTypes.object),
    analysisCondition: PropTypes.string,
    analysisPeriod: PropTypes.string,
    countryCode: PropTypes.array,
    updatePeriod: PropTypes.func.isRequired,
    updateCondition: PropTypes.func.isRequired,   
    downloadCSV: PropTypes.func.isRequired,   
    updateSelectedRegion: PropTypes.func.isRequired,   
    updateSelectedCountry: PropTypes.func.isRequired,   
    updateYear: PropTypes.func.isRequired,   
};

export default PopulationTrackingSheetFilters;
