import React from 'react';
import PropTypes from 'prop-types';
// import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import AtlasStyleSlider from '../AtlasStyleSlider/AtlasStyleSlider.jsx';
import AtlasStyleRadio from '../AtlasStyleRadio/AtlasStyleRadio.jsx';
// import AtlasBasemapChooser from '../AtlasBasemapChooser/AtlasBasemapChooser.jsx';
import './AtlasStyling.scss';

class AtlasStyling extends React.Component {
  render() {
    const allLayers = [
      'water-point-label',
      'water-line-label',
      'waterway-label',
      'country-label',
      'settlement-major-label',
      'settlement-minor-label',
      'landcover',
      'national-park',
      'landuse',
      'land-structure-polygon',
      'land-structure-line',
      'waterway-shadow copy',
      'water-shadow copy',
      'waterway copy',
      'water copy',
      'water border',
      'areas-label',
      'chcountries',
      'admin-0-boundary-bg copy',
      'admin-0-boundary copy',
      'admin-0-boundary-disputed copy',
    ];
    const showAll = {
      layers: [...allLayers],
      type: ['layout'],
      attributes: ['visibility'],
      value: 'visible',
    };
    const maskRadios = [
      {
        defaultChecked: true,
        label: 'Show neighboring labels',
        layers: ['country-label', 'settlement-major-label', 'settlement-minor-label'],
        type: 'paint',
        attributes: ['text-opacity', 'icon-opacity'],
        value: 1,
        linked: [{ ...showAll }],
      },
      {
        label: 'Hide neighboring labels',
        layers: ['country-label', 'settlement-major-label', 'settlement-minor-label'],
        type: 'paint',
        attributes: ['text-opacity', 'icon-opacity'],
        value: ['case', ['within', this.props.selectedGeom], 1, 0],
        linked: [{ ...showAll }],
      },
      {
        label: 'Hide all labels',
        layers: [...allLayers],
        type: 'layout',
        attributes: ['visibility'],
        value: 'none',
      },
    ];
    const renderTooltip = props => {
      return (
        <Tooltip id="button-tooltip" {...props}>
          {`${this.props.styleActive ? 'Close customize' : 'Customize this map'}`}
        </Tooltip>
      );
    };
    return (
      <React.Fragment>
        <div className={`atlasStylingContainer ${this.props.styleActive ? 'active' : ''}`}>
          <Form className="atlasStyling">
            {/* <AtlasBasemapChooser handler={this.props.basemapSelect} /> */}
            <AtlasStyleSlider
              title="Country Labels"
              handler={this.props.handler}
              layers={['country-label']}
              id="country-label"
              type="layout"
              attributes={['text-size']}
              min={0}
              max={2}
              step={0.01}
              defaultValue={1}
            />
            <AtlasStyleSlider
              title="Analysis Area Labels"
              handler={this.props.handler}
              layers={['areas-label']}
              id="areas-label"
              type="layout"
              attributes={['text-size']}
              min={0}
              max={2}
              step={0.01}
              defaultValue={1}
            />
            <AtlasStyleSlider
              title="Settlement Labels"
              handler={this.props.handler}
              layers={['settlement-major-label', 'settlement-minor-label']}
              id="settlement-label"
              type="layout"
              attributes={['text-size']}
              min={0}
              max={2}
              step={0.01}
              defaultValue={1}
            />
            <AtlasStyleSlider
              title="Settlement Points"
              handler={this.props.handler}
              layers={['settlement-major-label', 'settlement-minor-label']}
              id="settlement-point"
              type="layout"
              attributes={['icon-size']}
              min={0}
              max={2}
              step={0.01}
              defaultValue={1}
            />
            {this.props.selectedGeom && !this.props.ar && (
              <AtlasStyleRadio
                buttons={[...maskRadios]}
                name="label-mask"
                handler={this.props.handler}
              />
            )}

            {!this.props.group && !this.props.ar && (
              <AtlasStyleSlider
                title="Additional Area Opacity"
                handler={this.props.handler}
                layers={['areas-negative']}
                id="areas-negative"
                type="paint"
                attributes={['fill-opacity']}
                min={0}
                max={0.75}
                step={0.01}
                defaultValue={0}
              />
            )}

            <AtlasStyleSlider
              title={`${this.props.group ? 'CH' : 'IPC'}  Phase Boundaries`}
              handler={this.props.handler}
              layers={['areas-border']}
              id="areas-border"
              type="paint"
              attributes={['line-width']}
              min={0}
              max={6}
              step={0.1}
              defaultValue={1}
            />
            {!this.props.group && (
              <AtlasStyleSlider
                title="Country Background Mask"
                handler={this.props.handler}
                layers={['countries-mask']}
                id="countries-mask"
                type="paint"
                attributes={['fill-opacity']}
                min={0}
                max={1}
                step={0.1}
                defaultValue={0}
              />
            )}
          </Form>
        </div>
        <OverlayTrigger overlay={renderTooltip} placement="right">
          <Button
            size="lg"
            variant="light"
            className="atlas-style__toggle d-none d-md-block"
            onClick={this.props.toggle}
          >
            {this.props.styleActive ? (
              <FontAwesomeIcon icon={faChevronLeft} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </Button>
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

AtlasStyling.propTypes = {
  handler: PropTypes.func.isRequired,
  selectedGeom: PropTypes.object,
  toggle: PropTypes.func,
  styleActive: PropTypes.bool,
  group: PropTypes.number,
  ar: PropTypes.bool,
};

AtlasStyling.defaultProps = {
  selectedGeom: {},
  styleActive: false,
  group: null,
  ar: false,
};

export default AtlasStyling;
