import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';

import './YearFilter.scss';

const currentYear = new Date().getFullYear();

class YearFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: props.current,
      year: props.year,
    };
  }

  increment = e => {
    e.preventDefault();
    const { year } = this.state;
    this.setState({ year: Math.min(currentYear, year + 1) });
  };

  decrement = e => {
    e.preventDefault();
    const { year } = this.state;
    const startYear = this.props.condition === 'C' ? 2015 : 2017;
    // const startYear = 2017;
    this.setState({ year: Math.max(startYear, year - 1) });
  };

  setCurrent = () => {
    const { current } = this.state;
    this.setState({ current: !current });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!_.isEqual(prevState, this.state)) {
      this.props.handler(this.state);
    }
  };

  render() {
    const { condition } = this.props;
    const yearRange =
      condition !== 'C'
        ? `${currentYear - 2} - ${currentYear}`
        : `${currentYear - 5} - ${currentYear}`;
    return (
      <form className="form-inline yearFilter">
        <input type="checkbox" checked={this.state.current} onChange={this.setCurrent} /> &nbsp;
        <label>Latest Analyses</label> &nbsp;
        <span>
          <button
            className="btn outline-secondary"
            onClick={this.decrement}
            disabled={this.state.current}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <input
            className="yearNumber"
            value={this.state.current ? yearRange : this.state.year}
            step={1}
            readOnly={true}
          />
          <button
            className="btn outline-secondary"
            onClick={this.increment}
            disabled={this.state.current}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </span>
      </form>
    );
  }
}

YearFilter.propTypes = {
  handler: PropTypes.func.isRequired,
  condition: PropTypes.string.isRequired,
  year: PropTypes.number,
  current: PropTypes.bool,
};

export default YearFilter;
