import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import DataContext from '../../DataContext/DataContext';
import Tab from './Tab/Tab';
import AtlasDropdown from '../ContentBlock/Atlas/AtlasDropdown/AtlasDropdown';
import './ReportTabs.scss';

class ReportTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [],
    };
    this.addTab = this.addTab.bind(this);
    this.onTabRemove = this.onTabRemove.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);
  }

  componentDidMount() {
    const {
      clearExportData,
    } = this.context;
    clearExportData();
    /*
    const topFive = population.map((d) => {
      const { aar_id } = d;
      const pop3 = d.phase3.population + d.phase4.population + d.phase5.population;
      return {
        aar_id,
        pop3,
      };
    }).sort((a, b) => b.pop3 - a.pop3)
      .slice(0, 5)
      .map(d => ({
        aar_id: d.aar_id,
        title: areasGeo.features.find(a => a.properties.aar_id === d.aar_id).properties.title,
      }));

    this.setState({
      areas: topFive,
    });
    */
  }

  onTabRemove(d) {
    const {
      areas,
    } = this.state;
    const {
      updateSelectedArea,
      selectedArea,
      removeExportData,
    } = this.context;
    const { aar_id } = d;
    removeExportData({ aar_id });
    const newAreas = areas.concat();
    newAreas.splice(newAreas.indexOf(d), 1);
    if (selectedArea === d.aar_id) updateSelectedArea(null);
    this.setState({
      areas: newAreas,
    });
  }

  async onTabSelect(d) {
    const {
      country,
      updateSelectedArea,
      addExportData,
    } = this.context;
    await addExportData();
    if (d.aar_id === country) updateSelectedArea(null);
    else updateSelectedArea(d.aar_id);
    const { areas } = this.state;
    const index = areas.indexOf(d);
    if (index > 4) {
      const newAreas = areas.slice();
      newAreas.splice(index, 1);
      newAreas.splice(4, 0, d);
      this.setState({
        areas: newAreas,
      });
    }
  }

  async addTab(d) {
    const { areas } = this.state;
    const { updateSelectedArea, addExportData } = this.context;
    const newAreas = areas.slice();
    if (areas.length > 4) {
      newAreas.splice(4, 0, d);
    } else {
      newAreas.push(d);
    }
    await addExportData();
    updateSelectedArea(d.aar_id);
    this.setState({
      areas: newAreas,
    });
  }

  render() {
    const {
      areasGeo,
      country,
      countryName,
      selectedArea,
      addExportData,
      drawExports,
    } = this.context;
    const {
      areas,
    } = this.state;
    const allAreas = areasGeo.features.map(d => d.properties);
    const countryTab = <Tab key={country} active={selectedArea === null} data={{ aar_id: country, title: countryName }} onTabSelect={this.onTabSelect} type="country" />;
    const areaTabs = areas.slice(0, 5).map(d => <Tab key={d.aar_id} active={selectedArea === d.aar_id} data={d} type="area" onTabRemove={this.onTabRemove} onTabSelect={this.onTabSelect} />);
    const mainTabs = [countryTab].concat(areaTabs);

    const more = areas.slice(5);
    const moreDropdown = (
      <AtlasDropdown
        className="tab__dropdown"
        menuList={more}
        onMenuClick={this.onTabSelect}
        search={false}
      />
    );
    const moreTab = more.length
      ? <Tab key="more" active={false} data={{ aar_id: 'more', title: '...' }} dropdown={moreDropdown} type="more" />
      : [];

    const addedIds = areas.map(d => d.aar_id);
    const toAdd = allAreas.filter(d => !addedIds.includes(d.aar_id));
    const addDropdown = (
      <AtlasDropdown
        className="tab__dropdown"
        menuList={toAdd}
        onMenuClick={this.addTab}
      />
    );
    const addTab = toAdd.length
      ? <Tab key="add" active={false} data={{ aar_id: 'add', title: 'Add area' }} dropdown={addDropdown} type="add" />
      : [];
    const allTabs = mainTabs.concat(moreTab).concat(addTab);
    return (
      <div>
        <div className="report-tabs-header">
          <div
            className="download-report-button"
            onClick={async () => {
              await addExportData();
              drawExports();
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
            <span className="header__button-text">Download Report</span>
          </div>
        </div>
        <div className="report-tabs">
          {allTabs}
        </div>
      </div>
    );
  }
}

ReportTabs.contextType = DataContext;

export default ReportTabs;
