import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt } from '@fortawesome/pro-solid-svg-icons';

import './AtlasStyleSlider.scss';

class AtlasStyleSlider extends React.Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
  }

  render() {
    return (
      <div className="stylingGroup d-sm-none d-md-block">
        <h3>{this.props.title}</h3>
        <Row noGutters={true}>
          <Col sm={2}>
            <Form.Label as="h4">Off</Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Control
              ref={this.slider}
              type="range"
              className="styleSlider"
              custom
              onChange={() => {
                this.props.handler(this.props.id, {
                  value: parseFloat(this.slider.current.value),
                  layers: this.props.layers,
                  type: this.props.type,
                  attributes: this.props.attributes,
                });
              }}
              min={this.props.min}
              max={this.props.max}
              step={this.props.step}
              defaultValue={this.props.defaultValue}
            />
          </Col>
          <Col sm={2}>
            <Button
              variant="outline secondary"
              size="lg"
              onClick={() => {
                this.props.handler(this.props.id, {
                  value: parseFloat(this.props.defaultValue),
                  layers: this.props.layers,
                  type: this.props.type,
                  attributes: this.props.attributes,
                });
                this.slider.current.value = parseFloat(this.props.defaultValue);
              }}
            >
              <FontAwesomeIcon icon={faUndoAlt} />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

AtlasStyleSlider.propTypes = {
  title: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
};

export default AtlasStyleSlider;
