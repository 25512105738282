import React from 'react';
import './NoDataScreen.scss';

function NoDataScreen() {
  return (
    <div className="no-data-screen">
      <div className="loader__text">No data exists to display. Try selecting a different country and/or year.</div>
    </div>
  );
}

export default NoDataScreen;
