import React from 'react';
import PropTypes from 'prop-types';
import './LegendTable.scss';

function LegendTable(props) {
  const {
    children,
    header,
    className,
  } = props;
  //key={node.key}
  const rows = children.length && children.length > 1
    ? children.map((node, i) => (
      <div key={Math.random().toString(36).substring(1)} className={`legend-table-row${header && i === 0 ? ' header' : ''}`}>
        {node}
      </div>
    )) : children;

  return (
    <div className={`legend-table ${className}`}>
      {rows}
    </div>
  );
}

LegendTable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.bool,
  className: PropTypes.string,
};

LegendTable.defaultProps = {
  header: false,
  className: '',
};

export default LegendTable;