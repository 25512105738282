/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faMap,
  faTable,
  faDrawPolygon,
  faBinoculars,
  faHandPointer,
} from '@fortawesome/pro-solid-svg-icons';
import _ from 'underscore';

import DataContext from '../../DataContext/DataContext';
import AtlasPublic from './Atlas/AtlasPublic.jsx';
import AtlasLegend from './Atlas/AtlasLegend/AtlasLegendNew.jsx';
import AtlasStyling from './Atlas/AtlasStyling/AtlasStyling.jsx';
import PhaseFilter from '../Header/PhaseFilter/PhaseFilter.jsx';
import YearFilter from '../Header/YearFilter/YearFilter.jsx';
import ConditionFilter from '../Header/ConditionFilter/ConditionFilter.jsx';
import PhaseDonutChart from './AcutePhase/AcutePhaseNew.jsx';
import PhaseIndexPlus from './PhaseIndexPlus/PhaseIndexPlusNew.jsx';
import './ContentBlock.scss';
import exportMap from '../Export/ExportNew';
import DownloadButton from '../Export/DownloadButton/DownloadButton.jsx';

class ContentBlockPublic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePhases: [6, 5, 4, 3, 2, 1, 0, 9],
      activeYear: props.params.year || new Date().getFullYear(),
      activeCondition: props.params.condition || 'A',
      yearCurrent: !props.params.year,
      legendActive: true,
      styleActive: false,
      legendView: 'details',
      exportMode: false,
      styleOverrides: {},
      basemapStyle: 'light.json',
      activeAnalysisPeriod: null,
      showFullAnalysisNote: false,
      highlightedFeature: undefined,
      selectedCountry: undefined,
      selectedGeom: undefined,
      highlightedPhases: null,
      analysesPickList: null,
    };
    this.updateLegendView = this.updateLegendView.bind(this);
    this.downloadMapImage = this.downloadMapImage.bind(this);
    this.mapboxMaps = {};
  }

  componentDidMount() {
    const { analysisId, includeTable, period } = this.context;
    // const p = period.match(/([a-z])$/gi)
    const p = period ? period.match(/(.*)=(.*)/) : 'C'; // [ "?period=P", "?period", "P" ]
    this.setState({
      legendActive: analysisId === null || includeTable,
      activeAnalysisPeriod: Array.isArray(p) && p.length === 3 ? p[2] : null,
    });
  }

  componentDidUpdate() {
    const { activeCondition, selectedCountry } = this.state;
    // if latestest analyses checked active year = 0;
    // if( selectedCountry && ((selectedCountry.condition !== activeCondition) || ! ([0, activeYear].includes(selectedCountry.year)))) {
    if (selectedCountry && selectedCountry.condition !== activeCondition) {
      this.setState({
        selectedCountry: undefined,
      });
    }
  }

  getSingleView() {
    const {
      legendActive,
      styleActive,
      legendView,
      exportMode,
      highlightedFeature,
      activeCondition,
      activeYear,
      yearCurrent,
      selectedCountry,
      analysesPickList,
    } = this.state;

    const { iframeHeight } = this.context;

    const toggle = () => {
      this.setState({ legendActive: !this.state.legendActive });
    };

    const toggleStylePanel = () => {
      this.setState({ styleActive: !this.state.styleActive });
    };

    const updateStyle = (key, value) => {
      const newStyle = Array.isArray(value) ? value : [value];
      const styleOverrides = { ...this.state.styleOverrides };
      styleOverrides[key] = newStyle;
      this.setState({ styleOverrides });
    };

    const basemapSelect = e => {
      const basemapStyle = e.target.getAttribute('value');
      if (basemapStyle) {
        this.setState({ basemapStyle });
      }
    };

    const filterPhases = (phases, active) => {
      let { activePhases } = this.state;
      if (active) activePhases = [...activePhases, ...phases];
      else activePhases = _.without(activePhases, ...phases);
      this.setState({ activePhases });
    };

    const filterYears = years => {
      const { current, year } = years;
      this.setState({ activeYear: year, yearCurrent: current });
      // setAnalysesPickList();
    };

    const filterCondition = condition => {
      this.setState({ activeCondition: condition });
      // setAnalysesPickList();
    };

    const addMapRef = ({ type, position, map }) => {
      this.mapboxMaps = {
        ...this.mapboxMaps,
        [`${type}-${position || ''}`]: { type, position, map },
      };
    };

    const deleteMapRef = ({ type, position }) => {
      delete this.mapboxMaps[`${type}-${position || ''}`];
    };

    const setHighlightedFeature = feature => {
      this.setState({ highlightedFeature: feature });
    };

    const setSelectedCountry = feature => {
      if (feature) {
        this.setState({
          selectedCountry: feature.properties,
          selectedGeom: feature.geometry,
        });
        // setAnalysesPickList();
      } else {
        this.setState({
          selectedCountry: null,
          selectedGeom: null,
          analysesPickList: null,
        });
      }
    };

    // const setAnalysesPickList = () => {
    //   const featureToUse = highlightedFeature || selectedCountry;

    //   let analysesPickList = null;
    //   if (featureToUse) {
    //     let year = activeYear;
    //     if (!year) {
    //       const d = featureToUse.analysis_create_date;
    //       year = d && d.substring(d.length, d.length - 4);
    //     }
    //     analysesPickList = _.sortBy(JSON.parse(featureToUse.additional), 'analysis_date').filter(
    //       a => {
    //         // a.year === +year
    //         const minValidityYear = a.analysis_period_from.substring(
    //           a.analysis_period_from.length,
    //           a.analysis_period_from.length - 4
    //         );
    //         const maxValidityYear = a.analysis_period_thru.substring(
    //           a.analysis_period_thru.length,
    //           a.analysis_period_thru.length - 4
    //         );
    //         return [+minValidityYear, +maxValidityYear].includes(+year);
    //       }
    //     );
    //   }
    //   this.setState({ analysesPickList });
    // };

    const onAnalysesPickListChange = e => {
      e.preventDefault();
      const selectedOption = e.target.options[e.target.selectedIndex];
      const link = selectedOption.getAttribute('data-link');
      if (link) {
        // window.open(link, '_blank');
        window.top.location.href = link;
      }
    };

    const colclassxl = legendActive && styleActive ? 8 : !legendActive && !styleActive ? 12 : 10;
    const colclassmd = legendActive && styleActive ? 6 : !legendActive && !styleActive ? 12 : 9;
    return (
      <React.Fragment>
        <Container fluid>
          <Row noGutters={true}>
            <Col xl={10} md={9} sm={12}>
              <Row noGutters={true}>
                <Col md={4} sm={12}>
                  <ConditionFilter handler={filterCondition} analysisCondition={activeCondition} />
                </Col>
                <Col md={4} sm={12}>
                  <PhaseFilter handler={filterPhases} analysisCondition={activeCondition} />
                </Col>
                <Col md={4} sm={12}>
                  <YearFilter
                    handler={filterYears}
                    condition={activeCondition}
                    year={activeYear}
                    current={yearCurrent}
                  />
                </Col>
                {!!(
                  !this.props.params.splash &&
                  selectedCountry &&
                  analysesPickList &&
                  analysesPickList.length
                ) && (
                  <Col md={2} sm={12}>
                    <span className="selectdiv-label">Select Another Analysis:</span>
                    <div className="selectdiv">
                      <select onChange={onAnalysesPickListChange} value={selectedCountry.anl_id}>
                        {analysesPickList.map(a => {
                          if (a.link && a.link.length) {
                            return (
                              <option key={a.anl_id} data-link={a.link} value={a.anl_id}>
                                {a.condition === 'C'
                                  ? a.analysis_date
                                  : `${a.analysis_period_from} - ${a.analysis_period_thru}`}
                              </option>
                            );
                          }
                          return '';
                        })}
                      </select>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            {legendActive && <Col xl={2} md={3} />}
          </Row>
          <Row noGutters={true} className="mainContent">
            {!this.props.params.splash && (
              <AtlasStyling
                handler={updateStyle}
                toggle={toggleStylePanel}
                basemapSelect={basemapSelect}
                selectedGeom={this.state.selectedGeom}
              />
            )}
            <Col xl={colclassxl} md={colclassmd} sm={12} style={{ height: '100%' }}>
              <AtlasPublic
                styleOverrides={this.state.styleOverrides}
                activePhases={this.state.activePhases}
                activeCondition={this.state.activeCondition}
                activePeriod={selectedCountry ? selectedCountry.period : 'C'}
                current={this.state.yearCurrent}
                year={this.state.activeYear}
                saveMapRef={addMapRef}
                deleteMapRef={deleteMapRef}
                setHighlightedFeature={setHighlightedFeature}
                setSelectedCountry={setSelectedCountry}
                highlightedPhases={this.state.highlightedPhases}
                basemapStyle={this.state.basemapStyle}
                highlightedFeature={highlightedFeature}
                insets={!this.props.params.splash}
                initialCountry={this.props.params.country}
                initialBounds={this.props.params.bounds}
                clickOverride={
                  this.props.params.splash
                    ? properties => {
                        const { link } = properties;
                        if (link) {
                          window.top.location.href = link;
                        }
                      }
                    : null
                }
              />
            </Col>
            {legendActive && (
              <Col xl={2} md={3}>
                <AtlasLegend
                  legendView={legendView}
                  updateLegendView={this.updateLegendView}
                  active={legendActive}
                  toggle={toggle}
                  position={exportMode ? 'bottom' : 'right'}
                  publicViewDetails={this.props.params.splash ? null : this.getDetailsPanelNew()}
                  splash={this.props.params.splash}
                  publicMap
                  analysisCondition={this.state.activeCondition}
                />
                {this.props.params.splash && this.getDownloadButton()}
              </Col>
            )}
            {!legendActive && (
              <div
                className="content__col-header__legend-toggle"
                onClick={() => {
                  toggle();
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            )}
          </Row>
        </Container>
        {!window.location.href.match('http://localhost') && (
          <iframe
            title="cross_title_property"
            height={iframeHeight}
            style={{ display: 'none' }}
            id="hiddenIframe"
            src={`https://www.ipcinfo.org/common/empty/?iframe=true&nofocus=y&height=${iframeHeight}`}
          />
        )}
      </React.Fragment>
    );
  }

  getDetailsPanelNew() {
    /*
      Example COUNTRY properties available:

      active_period: "P"
      analysis_name: "Acute Food Insecurity October 2019"
      anl_id: "14063811"
      condition: "A"
      country: "NA"
      current_from_date: "2020-03-31T23:00:00.000Z"
      current_thru_date: "2020-09-29T23:00:00.000Z"
      link: "http://www.ipcinfo.org/ipc-country-analysis/details-map/en/c/1152360/"
      mask: false
      notes: "Between October 2019 and March 2020, an estimated 430,000 people are facing severe acute food insecurity (IPC Phase 3+) and require urgent humanitarian action. The most affected regions are Hardap, Kavango West, Khomas, Kunene, Ohangwena, Omaheke, Omusati, and Zambezi, which are classified as IPC Phase 3 (Crisis). Crisis conditions are mainly due to the poor rainfall in the previous planting season which led to a significant decline in cereal production in 2019. Water shortages also contributed to significant livestock deaths in the northwestern and southern regions. In the projection period between April and September 2020, an estimated 360,000 people are expected to experience severe acute food insecurity.  The most affected regions are Hardap, Khomas, Kunene, Omusati and Zambezi, which are classified as IPC Phase 3 (Crisis). A slight improvement of the situation is expected in Kavango West, Ohangwena and Omusati that are attributable to the improved rains, and favourable cereal prospects in 2020. "
      period: "["C","P"]"
      phase1_pop: 1217595
      phase2_pop: 841599
      phase3_pop: 354431
      phase4_pop: 0
      phase5_pop: 0
      pop_graphics: true
      title: "Namibia"
      year: 2020
    */

    /*
      Example AREA properties available:

      aar_id: 13588181
      anl_id: "13588146"
      condition: "A"
      confidence_level: 3
      country: "MZ"
      created_by: "buene"
      current: true
      id: 1824
      ipc_period: "P"
      overall_phase: 3
      phase1_per: 0.3
      phase1_pop: 13301
      phase2_per: 0.25
      phase2_pop: 11084
      phase3_per: 0.35
      phase3_pop: 15518
      phase4_per: 0.1
      phase4_pop: 4434
      phase5_per: 0
      phase5_pop: 0
      population: 44336
      population_min: "19952"
      population_percentage_min: "0.4500"
      population_percentage_phase_worse: "0.4500"
      population_phase_worse: "19952"
      prolonged_crisis: true
      title: "Funhalouro"
      w5s_id: "13588484"
      year: 2019
    */
    const {
      highlightedFeature,
      selectedCountry,
      showFullAnalysisNote,
      activeCondition,
    } = this.state;
    const { notes, link } = selectedCountry || {};
    let {
      analysis_name,
      active_period,
      analysis_create_date,
      current_from_date,
      current_thru_date,
      projected_from_date,
      projected_thru_date,
      second_projected_from_date,
      second_projected_thru_date,
    } = selectedCountry || {};

    const downloadMap = () => {
      exportMap(
        this.mapboxMaps,
        8,
        selectedCountry,
        this.state.yearCurrent ? '2017-2020' : this.state.activeYear,
        active_period,
        activeCondition
      ); // <- inches of width (@300 dpi), defaults to 12
    };

    // show highlighted area if any, otherwise selected country if any, otherwise nothing
    const featureToUse = highlightedFeature || selectedCountry;
    // when we have a selected country we dont want to show anything on areas not analyzed hover
    if (
      featureToUse &&
      (selectedCountry ? (highlightedFeature ? highlightedFeature.overall_phase : true) : true)
    ) {
      const {
        phase1_pop,
        phase2_pop,
        phase3_pop,
        phase4_pop,
        phase5_pop,
        overall_phase,
        // title,
        condition,
        country_fullname,
      } = featureToUse;

      // we are confirming this; highlight can be of
      // 1. global view (giving country analysis object)
      // 2. country view (giving area objects)
      analysis_name = analysis_name || featureToUse.analysis_name;
      analysis_create_date = current_from_date || featureToUse.analysis_create_date;
      current_from_date = current_from_date || featureToUse.current_from_date;
      current_thru_date = current_thru_date || featureToUse.current_thru_date;
      projected_from_date = projected_from_date || featureToUse.projected_from_date;
      projected_thru_date = projected_thru_date || featureToUse.projected_thru_date;
      second_projected_from_date =
        second_projected_from_date || featureToUse.second_projected_from_date;
      second_projected_thru_date =
        second_projected_thru_date || featureToUse.second_projected_thru_date;

      // areas highlight have ipc_period
      active_period = active_period || featureToUse.active_period || featureToUse.ipc_period;

      let population = [phase1_pop, phase2_pop, phase3_pop, phase4_pop, phase5_pop];
      if (activeCondition === 'C') population = population.slice(0, 4);

      // excel exports are now required only to be post 2017
      // ofcourse the stupid date format always come to bite;
      const analysisCreateYear = analysis_create_date
        ? analysis_create_date.substring(
            analysis_create_date.length,
            analysis_create_date.length - 4
          )
        : 0;
      const iscreatedAfter2016 = analysisCreateYear >= 2017;

      const setHighlightedPhases = phases => {
        this.setState({ highlightedPhases: phases });
      };

      const toggleAnalysisNote = () => {
        this.setState({ showFullAnalysisNote: !showFullAnalysisNote });
      };

      const downloadData = (type = 'xlsx') => {
        const { year, country, anl_id } = featureToUse;
        const periodUrl = active_period ? `?period=${active_period}` : null;
        const countryUrl = country ? `&country=${country}` : null;
        const conditionUrl = activeCondition ? `&condition=${activeCondition}` : null;

        let url = anl_id ? `/api/export/${type}/${anl_id}` : `/api/export/public/${type}/${year}`;
        if (periodUrl) {
          url += periodUrl;
          if (countryUrl) url += countryUrl;
          if (conditionUrl) url += conditionUrl;
        }
        window.open(url);
      };

      // full analysis link;
      // let fullAnalysisLink = link && link.replace(/\/details-map\//gi, '/details-map-test/');
      let fullAnalysisLink = link;
      fullAnalysisLink = fullAnalysisLink && `${fullAnalysisLink}?period=${active_period}`;

      const acuteDates =
        active_period === 'A'
          ? `From: ${second_projected_from_date} - ${second_projected_thru_date}`
          : active_period === 'P'
          ? `From: ${projected_from_date} - ${projected_thru_date}`
          : `From: ${current_from_date} - ${current_thru_date}`;
      return (
        <div className={`atlas-legend__details`}>
          <div className="atlas-legend__details__title">
            {country_fullname}: {analysis_name}
          </div>
          <div>
            {activeCondition === 'C'
              ? 'Created:'
              : `${
                  active_period === 'A'
                    ? 'Second Projected:'
                    : active_period === 'P'
                    ? 'Projected:'
                    : 'Current:'
                }`}{' '}
            &nbsp;
            <span className="blue">
              {activeCondition === 'C' ? `${analysis_create_date}` : `${acuteDates}`}
            </span>
          </div>

          {/* <div className="atlas-legend__details__subtitle">{title}</div> */}
          {/* PhaseDonutChart is an alias for the AcutePhase component */}
          <PhaseDonutChart
            population={population}
            overallPhase={overall_phase}
            className={activeCondition === 'C' ? 'chronic' : 'acute'}
            setHighlightedPhases={setHighlightedPhases}
            analysisCondition={activeCondition}
          />
          <PhaseIndexPlus
            population={population}
            setHighlightedPhases={setHighlightedPhases}
            analysisCondition={activeCondition}
          />

          {!highlightedFeature && (
            <React.Fragment>
              <div className="notesLink">
                {notes && (
                  <React.Fragment>
                    {!showFullAnalysisNote ? (
                      <div>
                        {notes.replace(/^(.{150}[^\s\d]*).*/gi, '$1')} <br />
                        <span
                          className="show-more"
                          onClick={() => {
                            toggleAnalysisNote();
                          }}
                        >
                          Read the full note...
                        </span>
                      </div>
                    ) : (
                      <div>
                        {notes} <br />
                        <span
                          className="show-more"
                          onClick={() => {
                            toggleAnalysisNote();
                          }}
                        >
                          Show less...
                        </span>
                      </div>
                    )}
                  </React.Fragment>
                )}

                {fullAnalysisLink && (
                  <Button
                    as="a"
                    block
                    size="lg"
                    className="atlas-legend__full-analysis-button"
                    href={fullAnalysisLink}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div>
                      <FontAwesomeIcon icon={faBinoculars} />
                      View Full Analysis
                    </div>
                  </Button>
                )}
              </div>

              <DownloadButton handler={downloadMap} icon={faMap} text="Download map image" />

              {iscreatedAfter2016 && condition !== 'C' && (
                <React.Fragment>
                  <div className="margin-top-10px margin-bottom-10px">
                    <DownloadButton
                      handler={() => downloadData('xlsx')}
                      icon={faTable}
                      text="Download population excel"
                    />
                  </div>
                  <DownloadButton
                    handler={() => downloadData('geojson')}
                    icon={faDrawPolygon}
                    text="Download GIS format"
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      );
    }

    return (
      <div className={`atlas-legend__details`}>
        {featureToUse ? (
          <div className="atlas-legend__details__title">
            {featureToUse.title ? `${featureToUse.title}:` : ''} Area Not analyzed
          </div>
        ) : (
          <Alert variant="secondary" style={{ margin: '15px auto', textAlign: 'center' }}>
            <FontAwesomeIcon icon={faHandPointer} />
            &nbsp;Hover on the map to see phase population data.
          </Alert>
        )}
        <DownloadButton handler={downloadMap} icon={faMap} text="Download map image" />
      </div>
    );
  }

  getDownloadButton() {
    const { selectedCountry, activeCondition } = this.state;
    const { active_period } = selectedCountry || {};

    const downloadMap = () => {
      const currentYear = new Date().getFullYear();
      const fromYear = currentYear - (activeCondition === 'C' ? 5 : 2);
      exportMap(
        this.mapboxMaps,
        8,
        selectedCountry,
        this.state.yearCurrent ? `${fromYear}-${currentYear}` : this.state.activeYear,
        active_period,
        activeCondition
      ); // <- inches of width (@300 dpi), defaults to 12
    };

    return (
      <div className={`atlas-splash-dowload-buttons inner-padding-md margin-t-20`}>
        {/* {!highlightedFeature && ( */}
        <React.Fragment>
          <DownloadButton handler={downloadMap} icon={faMap} text="Download map image" />
        </React.Fragment>
        {/* )} */}
      </div>
    );
  }

  togglePeriod(activePeriod) {
    const { updatePeriod } = this.context;

    this.setState({
      activeAnalysisPeriod: activePeriod,
    });

    updatePeriod(`?period=${activePeriod}`);
  }

  updateLegendView(legendView) {
    this.setState({ legendView });
  }

  async downloadMapImage() {
    const { downloadMap } = this.context;
    await downloadMap();
  }

  render() {
    return this.getSingleView();
  }
}

ContentBlockPublic.contextType = DataContext;

ContentBlockPublic.propTypes = {
  params: PropTypes.shape({
    year: PropTypes.number,
    condition: PropTypes.string,
    splash: PropTypes.bool,
    country: PropTypes.arrayOf(PropTypes.string),
    bounds: PropTypes.arrayOf(PropTypes.number),
  }),
};

export default ContentBlockPublic;
