import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import ContentBlockPublic from './ContentBlock/ContentBlockPublic.jsx';
import ContentBlockPublicAnalysis from './ContentBlock/ContentBlockPublicAnalysis.jsx';
import ContentBlockPublicAnalysisGroup from './ContentBlock/ContentBlockPublicAnalysisGroup.jsx';
import countryBounds from './ContentBlock/Atlas/country-bounds';

function getUrlParam(name) {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
}

const extendBounds = (b1, b2) => [
  Math.min(b1[0], b2[0]),
  Math.min(b1[1], b2[1]),
  Math.max(b1[2], b2[2]),
  Math.max(b1[3], b2[3]),
];

const getBounds = countries => {
  const bbox = countries.reduce(
    (bounds, country) => {
      const newBounds = countryBounds.find(c => c.country.toLowerCase() === country.toLowerCase());
      if (newBounds) return extendBounds(bounds, newBounds.bbox);
      return bounds;
    },
    [Infinity, Infinity, -Infinity, -Infinity]
  );
  if (bbox !== [Infinity, Infinity, -Infinity, -Infinity]) {
    return [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];
  }
  return null;
};

const PublicApp = () => {
  const [publicParams, setPublicParams] = useState({
    condition: getUrlParam('anl_type') || getUrlParam('condition'),
    year: parseInt(getUrlParam('year'), 10),
    splash: !getUrlParam('ap'),
    country: getUrlParam('country') ? getUrlParam('country').split(',') : [],
    bounds: getUrlParam('country') ? getBounds(getUrlParam('country').split(',')) : [],
  });
  useEffect(() => {
    setPublicParams({
      condition: getUrlParam('anl_type') || getUrlParam('condition'),
      year: parseInt(getUrlParam('year'), 10),
      splash: !getUrlParam('ap'),
      country: getUrlParam('country') ? getUrlParam('country').split(',') : [],
      bounds: getUrlParam('country') ? getBounds(getUrlParam('country').split(',')) : [],
    });
  }, [window.location]);

  const [analysisParams, setAnalysisParams] = useState({
    analysisId: getUrlParam('anl_id') || getUrlParam('analysis_id'),
    period: getUrlParam('period'),
    includeTable: getUrlParam('table'),
    mapOnly: getUrlParam('mapOnly') || getUrlParam('maponly'),
    group_id: getUrlParam('group_id'),
    view: getUrlParam('view') || 'split',
  });
  useEffect(() => {
    setAnalysisParams({
      analysisId: getUrlParam('anl_id') || getUrlParam('analysis_id'),
      period: getUrlParam('period'),
      includeTable: getUrlParam('table'),
      mapOnly: getUrlParam('mapOnly') || getUrlParam('maponly'),
      group_id: getUrlParam('group_id'),
      view: getUrlParam('view') || 'split',
    });
  }, [window.location]);

  if (analysisParams.group_id) return <ContentBlockPublicAnalysisGroup params={analysisParams} />;
  if (analysisParams.analysisId) return <ContentBlockPublicAnalysis params={analysisParams} />;
  return <ContentBlockPublic params={publicParams} />;
};

export default PublicApp;
