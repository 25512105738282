import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import './LoadScreen.scss';

class LoadScreen extends React.PureComponent {
  render() {
    const { loading } = this.props;
    return (
      <div className="loader">
        <div className="loader__icon">
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin"
          />
        </div>
        <div className="loader__text">{loading}</div>
      </div>
    );
  }
}

LoadScreen.propTypes = {
  loading: PropTypes.string.isRequired,
};

export default LoadScreen;
