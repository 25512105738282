import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import LegendTable from '../../LegendTable/LegendTable.jsx';

class AcutePhaseTable extends React.Component {
  renderRows() {
    const { populationByPhase, hover, updateHighlight, analysisCondition } = this.props;
    const populationFormat = d3.format(',d');
    // const reversed = populationByPhase.concat().reverse();
    const reversed = populationByPhase.concat();
    return reversed.map(d => {
      const { phase, population, percent } = d;
      const title = analysisCondition === 'C' ? 'Level' : 'Phase';
      return (
        <div
          className={`${phase === hover ? 'hover' : ''}`}
          key={phase}
          onMouseOver={() => {
            updateHighlight(phase);
          }}
          onMouseOut={() => {
            updateHighlight(null);
          }}
        >
          <div className={`swatch phase${phase}`} />
          <div className="phase">{`${title} ${phase}`}</div>
          <div className="population-data flex">{populationFormat(population)}</div>
          <div className="percent-data">{d3.format('d')(percent * 100)}</div>
        </div>
      );
    });
  }

  render() {
    const { analysisCondition } = this.props;
    const title = analysisCondition === 'C' ? 'Level' : 'Phase';
    const headerRow = (
      <div className="header-row" key="header-row">
        <div className="phase">{title}</div>
        <div className="population-header flex">Population</div>
        <div className="percent">%</div>
      </div>
    );
    return (
      <LegendTable header>
        {[headerRow].concat(this.renderRows())}
      </LegendTable>
    );
  }
}

AcutePhaseTable.propTypes = {
  populationByPhase: PropTypes.arrayOf(PropTypes.object).isRequired,
  hover: PropTypes.number,
  updateHighlight: PropTypes.func.isRequired,
};

AcutePhaseTable.defaultProps = {
  hover: null,
  updateHighlight: () => {},
};

export default AcutePhaseTable;
