import React from 'react';
import DataContext from '../../DataContext/DataContext';
import PopulationTrackingTool from './PopulationTrackingTool/PopulationTrackingTool';

class ContentBlockPopulationTrackingTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAnalysisPeriod: null,
      activeAnalysisCondition: null,
    };
  }

  componentDidMount() {
    const { resizeIFrame, period, anlType } = this.context;
    const p = ! period ? null : period.match(/(.*)=(.*)/); // [ "?period=P", "?period", "P" ]
    this.setState({
      activeAnalysisPeriod: (Array.isArray(p) && p.length === 3 ) ? p[2] : null,
      activeAnalysisCondition: anlType
    });

    if (resizeIFrame instanceof Function) {
      setTimeout(() => {
        resizeIFrame();
      }, 0);
    }
  }

  getSingleView() {
    return (
      <React.Fragment>
        <PopulationTrackingTool />
        {!window.location.href.match('http://localhost') && (
          <iframe
            title="cross_title_property"
            style={{ display: 'none' }}
            id="hiddenIframe"
            src="https://www.ipcinfo.org/common/empty/"
          />
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="content">
        <div className="content__main">
          {this.getSingleView()}
        </div>
      </div>
    );
  }
}

ContentBlockPopulationTrackingTool.contextType = DataContext;

export default ContentBlockPopulationTrackingTool;
