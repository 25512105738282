import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  // faTable,
  faTimes,
  faPlus,
  faMinus,
  faCloudDownloadAlt,
} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';
import Exportable from '../Exportable/Exportable';
import { downloadImage } from '../../Export/Export';
import exportMap from '../../Export/ExportNew';
import './LayoutBox.scss';

class LayoutBox extends React.Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();
    this.export = this.export.bind(this);
    this.downloadMap = this.downloadMap.bind(this);
    this.onReportClose = this.onReportClose.bind(this);
    this.onReportAdd = this.onReportAdd.bind(this);
    this.addReportText = this.addReportText.bind(this);
    this.removeReportText = this.removeReportText.bind(this);
    this.setReportText = this.setReportText.bind(this);
    this.state = {
      reportExcluded: false,
      reportText: null,
    };
  }

  onReportClose() {
    this.setState({
      reportExcluded: true,
      reportText: null,
    });
  }

  onReportAdd() {
    this.setState({
      reportExcluded: false,
    });
  }

  setReportText(value) {
    this.setState({
      reportText: value,
    });
  }

  addReportText() {
    this.setState({
      reportText: '',
    });
  }

  removeReportText() {
    this.setState({
      reportText: null,
    });
  }

  downloadMap() {
    const { analysisPeriod, analysis, mapboxMaps } = this.props;
    const { updateLoading } = this.context;
    updateLoading('downloading map....');
    exportMap(mapboxMaps, 8, analysis, '', analysisPeriod); // <- inches of width (@300 dpi), defaults to 12
    updateLoading(null);
  }

  export() {
    const { title, exportFull } = this.props;
    const filename = title.toLowerCase().replace(/\s/g, '-');
    const { updateLoading } = this.context;
    updateLoading('exporting....');
    downloadImage(this.boxRef.current, filename, exportFull);
    updateLoading(null);
  }

  downloadData = type => {
    const { analysisId, analysisPeriod, downloadData } = this.props;
    const { updateLoading } = this.context;
    updateLoading('downloading data....');
    downloadData(type, analysisId, analysisPeriod);
    updateLoading(null);
  };

  render() {
    const {
      title,
      children,
      analysisPeriod,
      exportable,
      reportMode,
      toggle,
      editToggle,
      analysisId,
      geojsonExport,
      downloadData,
      mapTitle,
      exportImage,
    } = this.props;
    const { reportExcluded, reportText } = this.state;
    const getClass = className => {
      const projected = analysisPeriod !== 'C';
      let layoutClass = className;
      if (projected) {
        layoutClass += ` ${className}--projected`;
      }
      if (exportable) {
        layoutClass += ` ${className}--exportable`;
      }
      if (reportMode) {
        layoutClass += ` ${className}--report`;
        if (reportExcluded) layoutClass += ` ${className}--report-excluded`;
      }
      return layoutClass;
    };

    const projected = analysisPeriod !== 'C';
    return (
      <div className={getClass('layout-box')} ref={this.boxRef}>
        {reportMode && !reportExcluded && (
          <div className="layout-box__close-button" onClick={this.onReportClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {reportMode && reportExcluded && (
          <div className="layout-box__add-button" onClick={this.onReportAdd}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
        {reportMode && !reportExcluded && reportText === null && (
          <div className="layout-box__add-text-button" onClick={this.addReportText}>
            <div className="layout-box__plus">
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <span>Add text</span>
          </div>
        )}
        {reportMode && !reportExcluded && reportText !== null && (
          <div className="layout-box__add-text-button" onClick={this.removeReportText}>
            <div className="layout-box__plus">
              <FontAwesomeIcon icon={faMinus} />
            </div>
            <span>Remove text</span>
          </div>
        )}
        <div className={getClass('layout-box__title-outer')}>
          <div className={getClass(`layout-box__title-inner ${mapTitle ? 'text-left' : title}`)}>
            {mapTitle || title}
          </div>
          {toggle}
          {editToggle}
          {!!analysisId && downloadData && geojsonExport && (
            <div
              className={getClass('layout-box__download-button')}
              title="Download GIS format"
              onClick={() => {
                this.downloadData('geojson');
              }}
            >
              <FontAwesomeIcon icon={faCloudDownloadAlt} />
            </div>
          )}

          {/** 
          { !! analysisId && downloadData && xlsxExport && (
              <div className={getClass('layout-box__download-button')} title="Download Population Data" onClick={()=>{this.downloadData('xlsx')}}>
                <FontAwesomeIcon icon={faTable} />
              </div>
          )}
          * */}

          <div
            className={getClass('layout-box__download-button')}
            style={{ opacity: exportImage ? 1 : 0.25, cursor: exportImage ? 'pointer' : 'default' }}
            title="Download Image"
            onClick={() => {
              if (!exportImage) return null;
              return mapTitle ? this.downloadMap() : this.export();
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </div>
        {(!reportMode || !reportExcluded) && (
          <Exportable
            suffix={projected ? '_projected' : ''}
            includeImage={false}
            name={title}
            reportText={reportText}
            className={getClass('layout-box__content')}
          >
            {children}
            {reportText !== null && (
              <div className={getClass('layout-box__report-text')}>
                <textarea
                  placeholder="Enter text to accompany this graphic"
                  value={reportText}
                  onChange={e => {
                    this.setReportText(e.target.value);
                  }}
                />
              </div>
            )}
          </Exportable>
        )}
      </div>
    );
  }
}

LayoutBox.defaultProps = {
  analysisPeriod: 'C',
  exportable: true,
  exportFull: false,
  reportMode: false,
  toggle: null,
  editToggle: null,
  analysisId: 0,
  geojsonExport: false,
  xlsxExport: false,
  downloadData: null,
  analysis: null,
  mapboxMaps: null,
  exportImage: true,
};

LayoutBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  analysisPeriod: PropTypes.string,
  exportable: PropTypes.bool,
  exportFull: PropTypes.bool,
  reportMode: PropTypes.bool,
  toggle: PropTypes.node,
  editToggle: PropTypes.node,
  analysisId: PropTypes.number,
  geojsonExport: PropTypes.bool,
  xlsxExport: PropTypes.bool,
  downloadData: PropTypes.func,
  analysis: PropTypes.object,
  mapboxMaps: PropTypes.object,
  exportImage: PropTypes.bool,
};

LayoutBox.contextType = DataContext;
export default LayoutBox;
