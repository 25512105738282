import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import './TooltipChart.scss';
// import DataContext from '../../../../DataContext/DataContext';

class TooltipChart extends React.Component {
  getBar({
    phase,
    percent,
    barScale,
    conditionClassPrefix,
  }) {
    const { size } = this.props;
    const { height } = size;

    const barStyle = {
      height: `${height}px`,
      width: `${barScale(percent)}px`,
    };
    return (
      <div
        key={phase}
        className={`tooltip-chart__bar tooltip-chart__bar--${conditionClassPrefix}phase${phase}`}
        style={barStyle}
      />
    );
  }

  getBars({
	  conditionClassPrefix,
	  }) {
    const {
      size,
      population,
    } = this.props;
    const {
      width,
    } = size;

    const barScale = d3.scaleLinear()
      .domain([0, 1])
      .range([0, width]);

    const totalPop = d3.sum(population);

    if (!totalPop) return <div className="tooltip-chart__bars"></div>;

    return (
      <div className="tooltip-chart__bars">
        {population.map((p, i) => this.getBar({
          phase: i + 1,
          percent: p / totalPop,
          barScale,
          conditionClassPrefix,
        }))}
      </div>
    );
  }

  render() {
    const {
      size,
      population,
      conditionClassPrefix,
    } = this.props;
    const {
      width,
      height,
    } = size;
    const chartStyle = {
      width: `${width}px`,
      height: `${height}px`,
      // background: 'grey',
    };
    if (population.every(d => d === 0 || d === undefined )) return '';
    return (
      <div style={chartStyle} className="tooltip-chart tooltip__chart">
        {this.getBars({conditionClassPrefix})}
      </div>
    );
  }
}

TooltipChart.defaultProps = {
  size: {
    width: 130,
    height: 15,
  },
  conditionClassPrefix:'',
  population: [1, 2, 4, 2, 1]
};

// TooltipChart.contextType = DataContext;

TooltipChart.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  population: PropTypes.arrayOf(PropTypes.number).isRequired,
  conditionClassPrefix: PropTypes.string,
};

export default TooltipChart;
