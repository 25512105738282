import React from 'react';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';
import Tooltip from '../../Tooltip/Tooltip';
import StackedBarContent from './StackedBarContent/StackedBarContent';
import './StackedBarChart.scss';

import * as d3 from 'd3';
import _ from 'underscore';

const formatPct = d3.format('.0%');
const formatDec = d3.format(',d');

class StackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    this.state = {
      tooltip: null,
    };

    this.logged = {
      screenWidth: null,
      width: null,
      height: null,
    };
    this.updateTooltip = this.updateTooltip.bind(this);
    this.removeTooltip = this.removeTooltip.bind(this);
  }

  getChartContent() {
    const { padding, analysisCondition, chartType } = this.props;
    const {analyses, updateAnalysisId, analysisId, dimensions, } = this.context;
    if (analyses === null || this.chartRef.current === null) return <div />;

    if (this.logged.screenWidth !== dimensions.width) {
      this.logged.screenWidth = dimensions.width;
      const container = this.chartRef.current;
      const { width, height, } = container.getBoundingClientRect();
      Object.assign(this.logged, { width, height });
    }

    const {width, height, } = this.logged;
    let filteredAnalyses = analyses.filter(d => d.condition === analysisCondition);
    filteredAnalyses = _.sortBy(filteredAnalyses, 'fanalysis_date');   
    return (
      <StackedBarContent
        {...{
          chartType,
          padding,
          'analyses': filteredAnalyses,
          width,
          height,
          updateAnalysisId,
          analysisId,
          removeTooltip: this.removeTooltip,
          updateTooltip: this.updateTooltip,
        }}
      />
    );
  }

  updateTooltip({ e, analysis, phase }) {
    const pos = {
      x: e.clientX,
      y: e.clientY,
    };
    const {chartType} = this.props;
    let tooltip;
    if( ! phase) {
      tooltip = (
        <Tooltip pos={pos}>
          <div className="tooltip__title">
            {analysis.title}
          </div>
          <div className="tooltip__date">
            { analysis && (
              `{ analysis.condition !== 'C' ? ${analysis.current_from_date} - ${analysis.current_thru_date} : analysis.analysis_date }`
            )}
          </div>
          <div className="tooltip__prompt">
            Click to select this analysis…
          </div>
        </Tooltip>
      );
    }
    else { 
      const phaseBorderClass = analysis.condition === 'C' ? 'border-phase--chronic-' : 'border-phase--';   
      tooltip = (
        <Tooltip pos={pos}>
          <div className="tooltip__title">
            {analysis.title} <br />
            { phase && ( 
              <strong>Phase: {phase.phase};  Est Pop: { chartType === 'percentage' ? formatPct(phase.population_percentage) : formatDec(phase.population) } </strong>
            )}
          </div>
          <div className={`phase-shade ${phaseBorderClass}${phase.phase}`}></div>
        </Tooltip>
      );
    }

    this.setState({ tooltip });
  }

  removeTooltip() {
    this.setState({
      tooltip: null,
    });
  }

  renderTooltip() {
    const {
      tooltip,
    } = this.state;

    if (tooltip === null) return null;
    return tooltip;
  }

  render() {
    return (
      <div className="stacked-bar">
        <div className="stacked-bar__content" ref={this.chartRef}>
          {this.getChartContent()}
          {this.renderTooltip()}
        </div>
      </div>
    );
  }
}

StackedBarChart.defaultProps = {
  padding: {
    top: 20,
    bottom: 30,
    left: 70,
    right: 20,
  },
  analysisCondition: 'A',
  chartType: 'percentage',
};

StackedBarChart.propTypes = {
  padding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  analysisCondition: PropTypes.string,
  chartType: PropTypes.string,
};

StackedBarChart.contextType = DataContext;

export default StackedBarChart;
