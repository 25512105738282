import React from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, { Source, Layer, NavigationControl } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';

import AtlasButton from '../AtlasButton/AtlasButton.jsx';
import AtlasResizable from '../AtlasResizable/AtlasResizable.jsx';
import './AtlasInset.scss';

class AtlasInset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      viewport: {
        width: 200,
        height: 200,
        latitude: 0,
        longitude: 0,
        zoom: 1,
      },
    };
    this.map = React.createRef();
    this.onResize = this.onResize.bind();
  }

  componentDidMount = () => {
    const { saveMapRef } = this.props;
    saveMapRef({
      type: 'inset',
      position: this.props.position,
      map: this.map.current.getMap(),
      period: this.props.activePeriod,
    });
  };

  componentWillUnmount = () => {
    const { deleteMapRef } = this.props;
    deleteMapRef({ type: 'inset', position: this.props.position });
  };

  getPosition = dir => {
    switch (dir) {
      case 'sw':
        return {
          bottom: 40,
          left: 10,
        };
      case 'se':
        return {
          bottom: 40,
          right: 10,
        };
      case 'nw':
        return {
          top: 0,
          left: 50,
        };
      case 'ne':
        return {
          top: 0,
          right: 10,
        };
      default:
        return {};
    }
  };

  showInset = () => {
    const visible = !this.state.visible;
    this.setState({ visible });
    if (visible) this.onResize(null, { width: 200, height: 200 });
  };

  onResize = (e, data) => {
    if (e) e.stopPropagation();
    const [longitude, latitude] = this.props.bounds.getCenter().toArray();
    const viewport = { ...this.state.viewport };
    this.setState({
      viewport: {
        ...viewport,
        width: data.width,
        height: data.height,
        longitude: viewport.longitude || longitude,
        latitude: viewport.latitude || latitude,
      },
    });
  };

  render() {
    let geojson = null;
    if (this.props.bounds) {
      geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  this.props.bounds.getNorthWest().toArray(),
                  this.props.bounds.getNorthEast().toArray(),
                  this.props.bounds.getSouthEast().toArray(),
                  this.props.bounds.getSouthWest().toArray(),
                  this.props.bounds.getNorthWest().toArray(),
                ],
              ],
            },
          },
        ],
      };
    }
    const zoomPosition = this.props.swapZoom ? { right: 10 } : { left: 10 };
    const closePosition = { ...this.getPosition(this.props.position) };
    if (closePosition.bottom) closePosition.bottom = 10;
    if (this.props.swapZoom && this.props.position === 'nw') closePosition.left = 10;
    return (
      <div
        className={`insetContainer ${this.state.visible ? 'visible' : ''} ${this.props.position}`}
        style={this.getPosition(this.props.position)}
      >
        {this.state.visible ? (
          <AtlasButton
            showButton={true}
            handler={this.showInset}
            captureClick={true}
            captureDrag={true}
            icon={<FontAwesomeIcon icon={faTimes} />}
            close={true}
            position={closePosition}
            tooltip="Close map inset"
            tooltipPosition={
              this.props.position === 'ne' || this.props.position === 'se' ? 'left' : 'right'
            }
          />
        ) : (
          <AtlasButton
            showButton={true}
            handler={this.showInset}
            captureClick={true}
            captureDrag={true}
            icon={<FontAwesomeIcon icon={faLayerPlus} />}
            tooltip="Show map inset"
            tooltipPosition={
              this.props.position === 'ne' || this.props.position === 'se' ? 'left' : 'right'
            }
          />
        )}
        <AtlasResizable
          width={this.state.viewport.width}
          height={this.state.viewport.height}
          onResize={this.onResize}
          dir={this.props.handle}
          visible={this.state.visible}
        >
          <ReactMapGL
            ref={this.map}
            {...this.state.viewport}
            attributionControl={false}
            mapboxApiAccessToken="pk.eyJ1IjoiYXhpc21hcHMiLCJhIjoieUlmVFRmRSJ9.CpIxovz1TUWe_ecNLFuHNg"
            mapStyle={`${process.env.PUBLIC_URL}/styles/light.json`}
            onViewportChange={newViewport => this.setState({ viewport: { ...newViewport } })}
          >
            {this.props.layers}
            <Source type="geojson" data={geojson}>
              <Layer
                id="extent"
                type="line"
                paint={{
                  'line-width': 4,
                  'line-color': '#c52c1b',
                }}
              />
            </Source>

            <div style={{ position: 'absolute', top: 10, ...zoomPosition }}>
              <NavigationControl showCompass={false} />
            </div>
          </ReactMapGL>
        </AtlasResizable>
      </div>
    );
  }
}

AtlasInset.propTypes = {
  filter: PropTypes.array,
  handle: PropTypes.string,
  position: PropTypes.string,
  saveMapRef: PropTypes.func,
  deleteMapRef: PropTypes.func,
  swapZoom: PropTypes.bool,
  bounds: PropTypes.object,
  layers: PropTypes.element,
  activePeriod: PropTypes.string,
};

AtlasInset.defaultProps = {
  saveMapRef: () => {},
  deleteMapRef: () => {},
};

export default AtlasInset;
