import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class AcutePhaseDonut extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.logged = {
      populationByPhase: [],
      hover: null,
      currentSelected: null,
    };
  }

  componentDidMount() {
    this.container = d3.select(this.chartRef.current);
    this.svg = this.container.append('svg');
    this.pieContainer = this.svg.append('g');
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const {
      populationByPhase,
      hover,
      updateHighlight,
    } = this.props;

    let changed = hover !== this.logged.hover;
    populationByPhase.forEach((d, i) => {
      if (this.logged.populationByPhase[i] === undefined
        || this.logged.populationByPhase[i].population !== d.population) {
        changed = true;
      }
    });
    if (changed !== true) return;
    Object.assign(this.logged, {
      populationByPhase,
      hover,
    });

    const { height } = this.container.node().getBoundingClientRect();

    const chartSize = height * 0.9;
    this.svg
      .attr('width', height)
      .attr('height', height);

    this.pieContainer
      .attr('transform', `translate(${height / 2}, ${height / 2})`);

    const arc = d3.arc()
      .outerRadius(chartSize * 0.5)
      .innerRadius(chartSize * 0.2);

    const pie = d3
      .pie()
      .sort((a, b) => a.phase - b.phase)
      .value(d => d.population);

    const pieData = pie(populationByPhase);

    const path = this.pieContainer.selectAll('path')
      .data(pieData, d => d.data.phase);

    const arcTween = function arcTween(d) {
      const i = d3.interpolate(this.current, d);
      this.current = i(0);
      return function tween(t) { return arc(i(t)); };
    };

    path.enter()
      .append('path')
      .attr('class', d => `donut-segment phase${d.data.phase}`)
      .on('mouseover', (d) => {
        updateHighlight(d.data.phase);
      })
      .on('mouseout', () => {
        updateHighlight(null);
      })
      .each(function setCurrent(d) { this.current = d; })
      .merge(path)
      .classed('hover', d => d.data.phase === hover)
      .classed('zero', d => d.data.population === 0)
      .transition()
      .attrTween('d', arcTween);
  }

  getContainerClass() {
    const { view } = this.props;
    let className = 'acute-phase-chart';
    if (view === 'split') {
      className += ' acute-phase-chart--split';
    }
    return className;
  }

  render() {
    return (
      <div className={this.getContainerClass()} ref={this.chartRef} />
    );
  }
}

AcutePhaseDonut.propTypes = {
  populationByPhase: PropTypes.arrayOf(PropTypes.object).isRequired,
  hover: PropTypes.number,
  updateHighlight: PropTypes.func.isRequired,
};

AcutePhaseDonut.defaultProps = {
  hover: null,
  updateHighlight: () => {}
};

export default AcutePhaseDonut;
