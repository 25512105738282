import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import './DownloadButton.scss';

class DownloadButton extends React.Component {
  render() {
    return (
      <Button
        size="lg"
        block
        variant="outline-secondary"
        className="atlas-legend__download-button"
        onClick={this.props.handler}
        disabled={this.props.loading}
      >
        <FontAwesomeIcon
          icon={this.props.loading ? faSpinnerThird : this.props.icon}
          spin={this.props.loading}
        />
        {this.props.text}
      </Button>
    );
  }
}

DownloadButton.propTypes = {
  handler: PropTypes.func.isRequired,
  icon: PropTypes.object,
  loading: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default DownloadButton;
