import React from 'react';
import DataContextPTTProvider from '../DataContext/DataContextPTTProvider';
import ContentBlockPopulationTrackingTool from './ContentBlock/ContentBlockPopulationTrackingTool';
import LoadScreen from './LoadScreen/LoadScreen';
import './App.scss';
import DataContext from '../DataContext/DataContext';
import NoDataScreen from './NoDataScreen/NoDataScreen';

const PopulationTrackingToolApp = () => (
  <DataContextPTTProvider>
    <DataContext.Consumer>
      {context => (
        <div className="outer-container">
          <ContentBlockPopulationTrackingTool />
          {context.loading !== null && (<LoadScreen loading={context.loading} /> )}
          {context.noData && (<NoDataScreen />)}
        </div>
      )}
    </DataContext.Consumer>
  </DataContextPTTProvider>
);

export default PopulationTrackingToolApp;