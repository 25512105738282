import React from 'react';
import DataContext from '../../../DataContext/DataContext';
import './AnalysesMenu.scss';

class AnalysesMenu extends React.Component {
  render() {
    const {
      analyses,
      analysisId,
      updateAnalysisId,
      country
    } = this.context;

    const onClick = (d) => {
      updateAnalysisId(d.anl_id);
    };

    const getList = () => {
      if (analyses === undefined || analyses === null) {
        return (<div />);
      }
      return analyses.map((d) => {
        let thisClass = `analyses-menu__row ${d.anl_id}`;
        if (d.anl_id === analysisId) {
          thisClass += ' analyses-menu__row--selected';
        }
        return <div onClick={() => onClick(d)} key={d.anl_id} className={thisClass}>{ country === 'gsu' && (d.country + ' - ') } {d.title}</div>;
      });
    };
    return (
      <div className="analyses-menu">
        <div className="analyses-block__header">Select by name:</div>
        <div className="analyses-menu__inner">
          {getList()}
        </div>
      </div>
    );
  }
}

AnalysesMenu.contextType = DataContext;
export default AnalysesMenu;
