import React from "react";
import './RangeSlider.scss';

export function Handle({
  handle: { id, value, percent },
  getHandleProps
}) {
  return (
    <div
      className="rangeslider-handle-wrapper"
      style={{ left: `${percent}%` }}
      {...getHandleProps(id)}
    >
      {/**<div className="rangeslider-handle">
        {value}
      </div> **/}
    </div>
  )
}

export function Track({ source, target, getTrackProps }) {
  return (
    <div 
    className="rangeslider-track"
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps() }
    />
  )
}

export function Tick({ tick, count }) {
  return (
    <div>
      <div
        className="rangeslider-track-top-tick"
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className="rangeslider-track-bottom-tick"
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value}
      </div>
    </div>
  )
}
