import * as rasterizeHTML from 'rasterizehtml';
// import { timeFormat } from 'd3';
import partners from '../Footer/IPC15PartnersFR.png';
import ipcLogo from '../Header/IPC_Logo_sm.png';

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function value(callback, type, quality) {
      const binStr = atob(this.toDataURL(type, quality).split(',')[1]);
      const len = binStr.length;
      const arr = new Uint8Array(len);

      for (let i = 0; i < len; i += 1) {
        arr[i] = binStr.charCodeAt(i);
      }

      callback(new Blob([arr], { type: type || 'image/png' }));
    },
  });
}

const exportedStyles = [
  'color',
  'fill',
  'stroke',
  'strokeWidth',
  'opacity',
  'fillOpacity',
  'strokeOpacity',
  'background',
  'backgroundColor',
  'width',
  'height',
  'maxWidth',
  'maxHeight',
  'display',
  'verticalAlign',
  'visibility',
  'position',
  'top',
  'left',
  'bottom',
  'right',
  'zIndex',
  'flex',
  'flexDirection',
  'flexShrink',
  'flexGrow',
  'flexWrap',
  'justifyContent',
  'alignItems',
  'font',
  'fontFamily',
  'fontStyle',
  'fontSize',
  'fontWeight',
  'whiteSpace',
  'textDecoration',
  'textIndent',
  'lineHeight',
  'textAlign',
  'transform',
  'transformOrigin',
  'overflow',
  'boxShadow',
  'boxSizing',
  'borderCollapse',
  'borderRadius',
  'borderTopRightRadius',
  'borderTopLeftRadius',
  'borderBottomRightRadius',
  'borderBottomLeftRadius',
  'filter',
];

const getBoxStyles = prefix => ['Top', 'Right', 'Bottom', 'Left'].map(s => prefix.concat(s));

const addInlineStyles = (node, deep) => {
  const borderSuffixes = ['Style', 'Color', 'Width'];
  const borderStyles = getBoxStyles('border')
    .map(style => borderSuffixes.map(suffix => style.concat(suffix)))
    .reduce((accumulator, value) => accumulator.concat(value), []);

  const stylesToCopy = exportedStyles
    .concat(getBoxStyles('margin'))
    .concat(getBoxStyles('padding'))
    .concat(borderStyles);
  const inlineStyles = {};
  for (let i = 0; i < node.style.length; i += 1) {
    const prop = node.style[i];
    inlineStyles[prop] = node.style[prop];
  }
  const computedStyles = window.getComputedStyle(node);
  const computedStylesToCopy = {};
  stylesToCopy.forEach((style) => {
    computedStylesToCopy[style] = computedStyles[style];
  });
  Object.assign(node.style, computedStylesToCopy);
  Object.assign(node.style, inlineStyles);
  if (deep) {
    Array.from(node.children).forEach((child) => {
      addInlineStyles(child, true);
    });
  }
};

const trimCanvas = (canvas) => {
  function rowBlank(imageData, width, y) {
    for (let x = 0; x < width; x += 1) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
    }
    return true;
  }

  function columnBlank(imageData, width, x, top, bottom) {
    for (let y = top; y < bottom; y += 1) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
    }
    return true;
  }

  const ctx = canvas.getContext('2d');
  const { width, height } = canvas;
  const imageData = ctx.getImageData(0, 0, width, height);
  let top = 0;
  let bottom = height;
  let left = 0;
  let right = width;

  while (top < bottom && rowBlank(imageData, width, top)) top += 1;
  while (bottom - 1 > 0 && rowBlank(imageData, width, bottom - 1)) bottom -= 1;
  while (left < right && columnBlank(imageData, width, left, top, bottom)) left += 1;
  while (right - 1 > 0 && columnBlank(imageData, width, right - 1, top, bottom)) right -= 1;

  if (left >= right || top >= bottom) return canvas;

  const trimmed = ctx.getImageData(left, top, right - left, bottom - top);
  const copy = document.createElement('canvas');
  copy.width = trimmed.width;
  copy.height = trimmed.height;
  // console.log('original size', width, height);
  // console.log('trimmed size', copy.width, copy.height);
  const copyCtx = copy.getContext('2d');
  copyCtx.putImageData(trimmed, 0, 0);

  return copy;
};

const rasterize = (node, exportFull = false, offset = [0, 0], trim = true) => {
  if (!node) return Promise.resolve(null);
  const clone = node.cloneNode(true);
  clone.classList.add('export');
  const bbox = node.getBoundingClientRect();
  const {
    width,
    height,
  } = bbox;
  document.body.appendChild(clone);
  if (!exportFull) {
    clone.style.width = `${width}px`;
    clone.style.height = `${height || 3000}px`;
  }

  addInlineStyles(clone, true);
  const cloneBBox = clone.getBoundingClientRect();
  const cloneWidth = cloneBBox.width;
  const cloneHeight = cloneBBox.height;
  const canvas = document.createElement('canvas');
  canvas.width = cloneWidth;
  canvas.height = cloneHeight;
  return new Promise((resolve) => {
    // const { className } = node;
    const delay = 0; // className.indexOf('atlas-outer') !== -1 && className.indexOf('public') !== -1 ? 5000 : 0;
    setTimeout(() => {
      rasterizeHTML.drawHTML(clone.outerHTML).then((renderResult) => {
        canvas.getContext('2d').drawImage(renderResult.image, ...offset);
        const trimmed = trim ? trimCanvas(canvas) : canvas;
        document.body.appendChild(trimmed);
        document.body.removeChild(clone);
        resolve(trimmed);
        document.body.removeChild(trimmed);
      });
    }, delay);
  });
};

const rasterizeOn = async ({ destination, sourceNode, bbox }) => {
  const sourceCanvas = await rasterize(sourceNode, false, [0, 0], false);
  const newCanvas = document.createElement('canvas');
  newCanvas.width = destination.width;
  newCanvas.height = destination.height;
  newCanvas.getContext('2d').drawImage(destination, 0, 0);
  const bounds = bbox || {
    x: 0,
    y: 0,
    width: sourceCanvas.width,
    height: sourceCanvas.height,
  };
  newCanvas.getContext('2d').drawImage(sourceCanvas,
    -bounds.x,
    -bounds.y);
  return newCanvas;
};

async function downloadImage(node, filename, exportFull) {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';

  const canvas = await rasterize(node, exportFull);

  canvas.toBlob((blob) => {
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = `${filename || 'ipc-dashboard'}.png`;
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }, 'image/png');
}

async function getCurrentExport({
  exportFunctions,
}) {
  const tooltipNode = document.querySelector('#tooltip-root');
  const tooltipBBox = tooltipNode.getBoundingClientRect();
  const exportData = await Promise.all(
    exportFunctions
      .map(exportFunc => exportFunc())
      .map(async (d) => {
        if (d.includeImage) {
          const exportFull = d.exportFull || false;
          const offset = d.name === 'population estimates graphic' ? [-8, -8] : [0, 0];
          const canvas = await rasterize(d.ref.current, exportFull, offset);
          let imageData = null;
          if (d.name === 'areas graphic' && d.ref.current) {
            const areasBBox = d.ref.current.getBoundingClientRect();
            const bbox = {
              x: areasBBox.x - tooltipBBox.x,
              y: areasBBox.y - tooltipBBox.y,
              width: areasBBox.width,
              height: areasBBox.height,
            };
            const combinedCanvas = await rasterizeOn({
              destination: canvas,
              sourceNode: tooltipNode,
              bbox,
            });
            imageData = combinedCanvas.toDataURL();
          } else if (canvas) {
            imageData = canvas.toDataURL();
          }
          return {
            name: d.name,
            text: d.reportText,
            suffix: d.suffix,
            image: imageData,
            included: d.ref.current !== null,
          };
        }
        return {
          name: d.name,
          suffix: d.suffix,
          text: d.reportText,
          image: null,
          included: d.ref.current !== null,
        };
      }),
  );
  const componentData = exportData.reduce((obj, item) => {
    const copy = Object.assign({}, obj);
    const key = item.name.toLowerCase().replace(/\s/g, '_');
    if (item.included) {
      // suffix is assumed to be "projected"; should change this to just a 'projected' boolean
      if (item.suffix) copy.projected[key] = item;
      else copy.current[key] = item;
    }
    return copy;
  }, { current: {}, projected: {} });
  return componentData;
}

async function getPublicExport({
  exportFunctions,
  countryName,
  analysisName,
  analysisDate,
  condition
}) {
  const tooltipNode = document.querySelector('#tooltip-root');
  const tooltipBBox = tooltipNode.getBoundingClientRect();
  const exportData = await Promise.all(
    exportFunctions
      .map(exportFunc => exportFunc())
      .filter(d => ['areas graphic', 'areas legend'].includes(d.name))
      .map(async (d) => {
        if (d.includeImage) {
          const exportFull = d.exportFull || false;
          // append a public export class
          d.ref.current.classList.add("public-export")
          const canvas = await rasterize(d.ref.current, exportFull, [0, 0], true)
          let exportCanvas = null;
          if (d.name === 'areas graphic' && d.ref.current) {
            const areasBBox = d.ref.current.getBoundingClientRect();
            const bbox = {
              x: areasBBox.x - tooltipBBox.x,
              y: areasBBox.y - tooltipBBox.y,
              width: areasBBox.width,
              height: areasBBox.height,
            };
            exportCanvas = await rasterizeOn({
              destination: canvas,
              sourceNode: tooltipNode,
              bbox,
            });
          } else if (canvas) {
            exportCanvas = canvas;
          }
          d.ref.current.classList.remove("public-export");
          return {
            name: d.name,
            text: d.reportText,
            suffix: d.suffix,
            canvas: exportCanvas,
            included: d.ref.current !== null,
          };
        }
        return {
          name: d.name,
          suffix: d.suffix,
          text: d.reportText,
          canvas: null,
          included: d.ref.current !== null,
        };
      }),
  );
  const componentData = exportData.reduce((obj, item) => {
    const copy = Object.assign({}, obj);
    const key = item.name.toLowerCase().replace(/\s/g, '_');
    if (item.included) {
      // suffix is assumed to be "projected"; should change this to just a 'projected' boolean
      if (item.suffix) copy.projected[key] = item;
      else copy.current[key] = item;
    }
    return copy;
  }, { current: {}, projected: {} });
  const headerHeight = analysisName ? 75 : 50;
  const footerHeight = condition === 'C' ? 30 : 0; //75;
  const areasGraphic = componentData.current.areas_graphic || componentData.projected.areas_graphic;
  let w = areasGraphic.canvas.width;
  const padding = 10;
  // if (componentData.projected.areas_graphic && componentData.current.areas_graphic) {
  //   w += componentData.projected.areas_graphic.canvas.width;
  // }
  const finalCanvas = document.createElement('canvas');
  w = w + padding * 2; // padding of 20px on each side
  finalCanvas.width = w;
  finalCanvas.height = areasGraphic.canvas.height + componentData.current.areas_legend.canvas.height + footerHeight + headerHeight;
  const finalCtx = finalCanvas.getContext('2d');
  finalCtx.fillStyle = 'white';
  finalCtx.fillRect(0, 0, finalCanvas.width, finalCanvas.height);
  finalCtx.drawImage(areasGraphic.canvas, padding, headerHeight + padding*1.7);
  // if (componentData.projected.areas_graphic && componentData.current.areas_graphic) {
  //   finalCtx.drawImage(componentData.projected.areas_graphic.canvas, componentData.current.areas_graphic.canvas.width + padding, headerHeight + padding*1.5);
  // }
  const legendScale = Math.min(1, w / componentData.current.areas_legend.canvas.width);
  finalCtx.drawImage(componentData.current.areas_legend.canvas, 0, areasGraphic.canvas.height + headerHeight + padding*2, componentData.current.areas_legend.canvas.width, componentData.current.areas_legend.canvas.height * legendScale);

  finalCtx.beginPath();
  finalCtx.fillStyle = '#125F9A';
  finalCtx.fillRect(0, 0, w, headerHeight);
  finalCtx.stroke();

  finalCtx.fillStyle = '#fff';
  // finalCtx.font = 'bold 28px Rubik, sans-serif';
  finalCtx.font = 'bold 24px arial, lucida grandriale , lucida sans unicode ,tahoma,sans-serif';
  if (countryName && countryName !== 'Total') {
    finalCtx.fillText(countryName, 10, 30);
  } else {
    finalCtx.fillText('IPC', 10, 30);
  }
  if (analysisName) {
    // finalCtx.font = 'bold 22px Rubik, sans-serif';
    finalCtx.font = 'bold 16px arial, lucida grandriale , lucida sans unicode ,tahoma,sans-serif';
    finalCtx.fillText(analysisName, 10, 56);
  }
  // finalCtx.font = 'bold 20px arial, lucida grandriale , lucida sans unicode ,tahoma,sans-serif';
  // finalCtx.fillText('IPC', 15, 36); // <- replace with analysis name

  // const date = analysisDate && ! (componentData.projected.areas_graphic && componentData.current.areas_graphic) ? analysisDate : timeFormat('%B %e, %Y')(new Date());
  const date = analysisDate;
  // finalCtx.font = 'normal 10px Rubik, sans-serif';
  finalCtx.font = 'normal 12px arial,lucida grandriale,lucida sans unicode,tahoma,sans-serif';
  finalCtx.fillText(date, 10, headerHeight - 4);
  
  finalCtx.fillStyle = '#000';
  // finalCtx.font = '9px Rubik, sans-serif';
  finalCtx.font = 'normal 9px arial,lucida grandriale,lucida sans unicode,tahoma,sans-serif';
  finalCtx.fillText('Disclaimer: The information shown on this map does not imply official recognition or endorsement of any physical and political boundaries. All national population figures are based on official country population estimates. IPC estimates are those published in country IPC reports. It is acknowledged that, in some cases, due to rounding and process related issues, figures at subnational level or for specific IPC Levels may not add up to totals.', padding*1.5, areasGraphic.canvas.height + headerHeight + padding*3);
  
  finalCtx.fillStyle = '#000';
  // finalCtx.font = '12px Rubik, sans-serif';
  finalCtx.font = 'normal 12px arial,lucida grandriale,lucida sans unicode,tahoma,sans-serif';
  finalCtx.fillText('Source: Integrated Food Security Phase Classification', padding * 1.5, finalCanvas.height - 10);

  const logos = new Image();
  logos.onload = function onLogoLoad() {
    // const s = Math.min((w - 20) / this.width, 1);
    // finalCtx.drawImage(this, padding, finalCanvas.height - footerHeight - 80, this.width * s, this.height * s);
    const mainLogo = new Image();
    mainLogo.onload = function onMainLogoLoad() {
      const s1 = headerHeight / this.height;
      finalCtx.drawImage(this, w - this.width * s1, 0, this.width * s1, this.height * s1);

      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      finalCanvas.toBlob((blob) => {
        tempLink.href = window.URL.createObjectURL(blob);
        // tempLink.download = `${analysisName || 'ipc-map'}.png`;
        tempLink.download = countryName && analysisName ? `${countryName} - ${analysisName}.png` : 'ipc-map.png';
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }, 'image/png');
    };
    mainLogo.src = ipcLogo;
  };
  logos.src = partners;

  return componentData;
}

function generateReport({
  country,
  analysisId,
  view,
  exportData,
}) {
  const reportData = {
    analysisId,
    view,
    areas: exportData,
  };
  const xhr = new XMLHttpRequest();
  xhr.open('POST', `api/${country}/report`, true);
  xhr.responseType = 'arraybuffer';
  xhr.onload = function onLoad() {
    if (this.status === 200) {
      console.log('cool');
      const contentType = xhr.getResponseHeader('Content-Type');
      const blob = new Blob([this.response], { type: contentType });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = downloadUrl;
      link.download = 'report.pdf';
      link.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
      }, 100);
    } else {
      console.log('not cool');
    }
  };
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify(reportData));
}

export {
  downloadImage,
  getCurrentExport,
  generateReport,
  getPublicExport,
};
