import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faTimes, faPlus } from '@fortawesome/pro-solid-svg-icons';
import './Tab.scss';

function Tab(props) {
  const {
    data,
    // dropdownData,
    dropdown,
    active,
    type,
    onTabRemove,
    // onSelect,
    onTabSelect,
  } = props;

  const { title } = data;
  const className = `tab tab--${type}${active ? ' tab--active' : ''}`;

  const select = dropdown;

  return (
    <div className={className}>
      <div className="tab__close-button" onClick={() => { onTabRemove(data); }}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div
        className="tab__content"
        title={data.title}
        onClick={() => { if (type !== 'more' && type !== 'add') onTabSelect(data); }}
      >
        <div className="tab__flag">
          <FontAwesomeIcon icon={faFlag} />
        </div>
        <div className="tab__plus">
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <span>{title}</span>
        {select}
      </div>
    </div>
  );
}

Tab.defaultProps = {
  active: false,
  type: 'area',
  dropdown: null,
  onTabRemove: () => {},
  onSelect: () => {},
  onTabSelect: () => {},
};

Tab.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  type: PropTypes.string,
  onTabRemove: PropTypes.func,
  onSelect: PropTypes.func,
  onTabSelect: PropTypes.func,
};

export default Tab;
