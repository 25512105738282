import React from 'react';
import PropTypes from 'prop-types';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './AtlasResizable.scss';

class AtlasResizable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDragging: false,
    };
    this.container = React.createRef();
  }

  getHandle = dir => {
    switch (dir) {
      case 'sw':
        return '⌞';
      case 'se':
        return '⌟';
      case 'nw':
        return '⌜';
      case 'ne':
        return '⌝';
      default:
        return '';
    }
  };

  startDrag = () => {
    window.addEventListener('mousemove', this.handleDrag);
    window.addEventListener('mouseup', this.stopDrag);
  };

  stopDrag = () => {
    window.removeEventListener('mousemove', this.handleDrag);
    window.removeEventListener('mouseup', this.stopDrag);
  };

  handleDrag = e => {
    const { x, y } = this.container.current.getBoundingClientRect();
    const { clientX, clientY } = e;
    const { dir } = this.props;
    let { width, height } = this.props;
    if (dir.includes('w')) {
      width += x - clientX + 20;
    } else {
      width = clientX - x + 20;
    }
    if (dir.includes('n')) {
      height += y - clientY + 20;
    } else {
      height = clientY - y + 20;
    }
    this.setState({ width, height });
    this.props.onResize(e, { width, height });
  };

  render() {
    const { dir, width, height } = this.props;
    const renderTooltip = (props) => {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Drag to resize the map inset
        </Tooltip>
        );
      };
    return (
      <div
        ref={this.container}
        style={{ width, height, display: this.props.visible ? 'block' : 'none' }}
        className="resizableContainer"
      >
        <OverlayTrigger overlay={renderTooltip} placement="right">
          <div onMouseDown={this.startDrag} className={`handle ${dir}`}>
            {this.getHandle(dir)}
          </div>
        </OverlayTrigger>
        {this.props.children}
      </div>
    );
  }
}

AtlasResizable.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  dir: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  children: PropTypes.node,
  onResize: PropTypes.func,
};

export default AtlasResizable;
