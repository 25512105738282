import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PublicApp from './PublicApp.jsx';
import PrivateApp from './PrivateApp.jsx';
// import ChronicApp from './ChronicApp.jsx';
import PopulationTrackingToolApp from './PopulationTrackingToolApp.jsx';

const App = () => (
  <Router>
    <Route exact path="/" component={PrivateApp} />
    <Route exact path="/public" component={PublicApp} />
    {/* <Route exact path="/new" component={PublicApp} /> */}
    <Route exact path="/population-tracking-sheet" component={PopulationTrackingToolApp} />
    <Route exact path="/population-tracking-tool" component={PopulationTrackingToolApp} />
    <Route exact path="/ptt" component={PopulationTrackingToolApp} />

    {/** do we need this handle? chronic was implemented inside private and public apps **/}
    {/** <Route exact path="/chronic" component={ChronicApp} /> **/}
  </Router>
);

export default App;
