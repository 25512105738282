import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faLevelUp } from '@fortawesome/pro-solid-svg-icons';

const formatPct = d3.format('.0%');
const formatDec = d3.format(',d');

function getPop(phase) {
  if (!phase) return '';
  return formatDec(phase.population);
}

function getPct(phase) {
  if (!phase) return '';
  return formatPct(phase.population_percentage);
}

function getPhaseHigherPop(area, analysisCondition) {
  let pTotal = 0; // area.phase3.population + area.phase4.population + (area.phase5 && area.phase5.population);
  // if(analysisCondition === 'C') {
  //   if(area.hasOwnProperty('phase2') && area.phase2.hasOwnProperty('population'))
  //     pTotal += area.phase2.population;
  // }

  if (area.hasOwnProperty('phase6') && area.phase3.hasOwnProperty('population')) {
    return formatDec(+area.phase6.population);
  }

  if (area.hasOwnProperty('phase3') && area.phase3.hasOwnProperty('population'))
    pTotal += +area.phase3.population;

  if (area.hasOwnProperty('phase4') && area.phase4.hasOwnProperty('population'))
    pTotal += +area.phase4.population;

  if (analysisCondition === 'A') {
    if (area.hasOwnProperty('phase5') && area.phase5.hasOwnProperty('population'))
      pTotal += +area.phase5.population;
  }

  return formatDec(pTotal);
}

function getPhaseHigherPct(area, analysisCondition) {
  // const p3Total = area.phase3.population_percentage + area.phase4.population_percentage + (area.phase5 && area.phase5.population_percentage);
  let pTotal = 0;
  // if(analysisCondition === 'C') {
  //   if(area.hasOwnProperty('phase2') && area.phase2.hasOwnProperty('population_percentage'))
  //     pTotal += area.phase2.population_percentage;
  // }

  if (area.hasOwnProperty('phase6') && area.phase3.hasOwnProperty('population_percentage')) {
    return formatPct(+area.phase6.population_percentage);
  }
  
  if (area.hasOwnProperty('phase3') && area.phase3.hasOwnProperty('population_percentage'))
    pTotal += +area.phase3.population_percentage;

  if (area.hasOwnProperty('phase4') && area.phase4.hasOwnProperty('population_percentage'))
    pTotal += +area.phase4.population_percentage;

  if (analysisCondition === 'A') {
    if (area.hasOwnProperty('phase5') && area.phase5.hasOwnProperty('population_percentage'))
      pTotal += +area.phase5.population_percentage;
  }
  return formatPct(pTotal);
}

class PopulationRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
  }

  onRowClick(area) {
    const { isSelected, updateSelectedArea } = this.props;
    if (isSelected) {
      updateSelectedArea(null);
    } else {
      updateSelectedArea(area.aar_id);
    }
  }

  onRowMouseOver(area) {
    const { updateHighlightedAreas } = this.props;
    updateHighlightedAreas([area.aar_id]);
  }

  onRowMouseOut() {
    const { updateHighlightedAreas } = this.props;
    updateHighlightedAreas([]);
  }

  getClass() {
    const { isSelected, isFaded, isHighlighted, level } = this.props;

    let rowClass = 'pop-row';

    if (isSelected) {
      rowClass += ' pop-row--selected';
    }

    if (isFaded) {
      rowClass += ' pop-row--faded';
    }

    if (isHighlighted) {
      rowClass += ' pop-row--highlighted';
    }

    rowClass += ` ${level}`;
    return rowClass;
  }

  getGroupName() {
    const { area, isClosed, level } = this.props;

    const icon = !isClosed ? (
      <FontAwesomeIcon icon={faChevronUp} />
    ) : (
      <FontAwesomeIcon icon={faChevronDown} />
    );
    const showHide = isClosed ? 'Show' : 'Hide';
    const text = level === 'level-0' ? `${showHide} Areas` : 'Group';
    return (
      <div className="pop-table__name">
        <div className="pop-table__group-name">{area.title}</div>
        {(level === 'level-0' ||
          (area.view_level && area.view_level.toLowerCase() !== 'group')) && (
          <div className="pop-table__group">
            <span className="pop-table__group-text">{text}</span>
            {icon}
          </div>
        )}
      </div>
    );
  }

  getAreaName() {
    const { area } = this.props;
    return <div>{area.title}</div>;
  }

  getSubAreaName() {
    const { area } = this.props;
    return (
      <div className="pop-tabl__sub-area">
        <FontAwesomeIcon className="pop-tabl__sub-area__icon" icon={faLevelUp} />
        <span className="pop-table__area-text">{area.title}</span>
      </div>
    );
  }

  getName() {
    const { level } = this.props;
    if (level === 'level-1' || level === 'level-0') {
      return this.getGroupName();
    }
    if (level === 'level-2-sub') {
      return this.getSubAreaName();
    }
    return this.getAreaName();
  }

  render() {
    const { area, level, onGroupClick, analysisCondition } = this.props;

    // console.log(area)
    const rowClass = this.getClass();

    // check if level === 'level-1'
    // if so, set mouseover/mouseout to nothing
    const nameBlock = this.getName();
    let mouseOver;
    let mouseOut;
    let click;
    if (level === 'level-1' || level === 'level-0') {
      mouseOver = () => {};
      mouseOut = () => {};
      click = () => onGroupClick(area);
    } else {
      mouseOver = () => this.onRowMouseOver(area);
      mouseOut = this.onRowMouseOut;
      click = () => this.onRowClick(area);
    }
    const analysisConditionPrefix = analysisCondition === 'C' ? 'chronic-' : '';
    return (
      <tr
        className={rowClass}
        onClick={click}
        onMouseOver={mouseOver}
        onFocus={mouseOver}
        onMouseOut={mouseOut}
        onBlur={mouseOut}
      >
        <td className="pop-table__name-col" title={area.title}>
          {nameBlock}
        </td>
        <td className={`${analysisConditionPrefix}phase-${area.overall_phase}-col phase-col-pct`}>
          <div>{area.overall_phase > 5 ? '5' : area.overall_phase}</div>
        </td>
        <td className="phase-col-val">
          <div>{formatDec(area.area_population)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-1-col phase-col-val`}>
          <div>{getPop(area.phase1)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-1-col phase-col-pct`}>
          <div>{getPct(area.phase1)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-2-col phase-col-val`}>
          <div>{getPop(area.phase2)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-2-col phase-col-pct`}>
          <div>{getPct(area.phase2)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-3-col phase-col-val`}>
          <div>{getPop(area.phase3)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-3-col phase-col-pct`}>
          <div>{getPct(area.phase3)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-4-col phase-col-val`}>
          <div>{getPop(area.phase4)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-4-col phase-col-pct`}>
          <div>{getPct(area.phase4)}</div>
        </td>
        {analysisCondition !== 'C' && (
          <React.Fragment>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-val`}>
              <div>{getPop(area.phase5)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-pct`}>
              <div>{getPct(area.phase5)}</div>
            </td>
          </React.Fragment>
        )}
        <td className="phase-index-plus phase-col-val">
          <div>{getPhaseHigherPop(area, analysisCondition)}</div>
        </td>
        <td className="phase-index-plus phase-col-pct">
          <div>{getPhaseHigherPct(area, analysisCondition)}</div>
        </td>
      </tr>
    );
  }
}

PopulationRow.propTypes = {
  area: PropTypes.shape({
    aag_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    aar_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    area_population: PropTypes.number,
    overall_phase: PropTypes.number,
    overall_population: PropTypes.number,
    phase1: PropTypes.object,
    phase2: PropTypes.object,
    phase3: PropTypes.object,
    phase4: PropTypes.object,
    phase5: PropTypes.object,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isFaded: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
  updateHighlightedAreas: PropTypes.func.isRequired,
  updateSelectedArea: PropTypes.func.isRequired,
  analysisCondition: PropTypes.string.isRequired,
};

PopulationRow.defaultProps = {
  isSelected: false,
  isHighlighted: false,
  isFaded: false,
  isClosed: true,
  level: '1',
  updateHighlightedAreas: () => {},
  updateSelectedArea: () => {},
  analysisCondition: 'C',
};

export default PopulationRow;
