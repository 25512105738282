import React from 'react';
import * as d3 from 'd3';
// const _ = require('underscore');

class StackedBarContent extends React.PureComponent {
  static getScales({ analyses, height, padding, chartType }) {
    // const popExtent = d3.extent(analyses.map(d => d3.sum(d.phase, dd => parseFloat(dd.population))));
    let max = 100; // percentage will always be 100%
    if (chartType === 'population') {
      max = d3.max(
        analyses.map(d => d3.sum(d.phase, dd => dd.phase < 6 && parseFloat(dd.population)))
      );
    }
    const popExtent = [0, max];
    const heightScale = d3
      .scaleLinear()
      .domain(popExtent)
      .range([0, height - padding.top - padding.bottom]);
    return { heightScale };
    
  }

  static getStackedBar({ heightScale, phase, d, updateTooltip, removeTooltip, chartType }) {
    const value = chartType === 'percentage' ? phase.population_percentage * 100 : phase.population;
    if (phase.phase > 5 || value === 0) {
      return null;
    }
    const phaseStyle = {
      height: `${heightScale(value)}px`,
    };
    const key = `${phase.anl_id}_${phase.phase}`;
    const analysisConditionPrefix = d.condition === 'C' ? 'chronic-' : '';
    return (
      <div
        key={key}
        className={`stacked-bar__bar stacked-bar__bar--${analysisConditionPrefix}p${phase.phase}`}
        style={phaseStyle}
        onMouseEnter={e => {
          updateTooltip({ e, analysis: d, phase: phase });
        }}
        onMouseOut={removeTooltip}
      />
    );
  }

  // static renderP3Trend({ heightScale, filteredAnalyses, updateTooltip, removeTooltip, chartType}) {
  //   filteredAnalyses.map((d) => {

  //   });

  //   const value = chartType === 'percentage' ? (phase.population_percentage) * 100 : phase.population;
  //   if(phase.phase > 5 || value === 0 ) {
  //     return null
  //   }
  //   const phaseStyle = {
  //     height: `${heightScale(value)}px`,
  //   };
  //   const key = `${phase.anl_id}_${phase.phase}`;
  //   const analysisConditionPrefix = (d.condition === 'C') ? 'chronic-' : '';
  //   return (
  //     <div
  //       key={key}
  //       className={`stacked-bar__bar stacked-bar__bar--${analysisConditionPrefix}p${phase.phase}`}
  //       style={phaseStyle}
  //       onMouseEnter={(e) => {
  //         updateTooltip({ e, analysis: d, phase: phase });
  //       }}
  //       onMouseOut={removeTooltip}
  //     />
  //   );
  // }

  static getXAxisProps({ width, analyses, padding }) {
    const xScaleLength = width - padding.right - padding.left;
    const barSpacing = 5;
    const barBorder = 2;
    let barWidth = xScaleLength / analyses.length - barSpacing - barBorder * 2;
    if (barWidth < 20) {
      barWidth = 20;
    }
    if (barWidth > 50) {
      barWidth = 50;
    }
    return {
      barSpacing,
      barWidth,
      xScaleLength,
    };
  }

  static getFilteredAnalyses({ analyses }) {
    return analyses;
    //   // .filter(d => d.condition === 'A')
    //   .map((d) => {
    //     const record = Object.assign({}, d);
    //     record.phase = d.phase.filter(dd => dd.phase !== 1);
    //     return record;
    //   });
  }

  static getAxisLine({ padding, width, height }) {
    return (
      <line
        x1={padding.left}
        x2={width - padding.right}
        y1={height - padding.bottom - 1}
        y2={height - padding.bottom - 1}
        className="stacked-bar__axis"
      />
    );
  }

  static getTick({ padding, barCount, barWidth, barSpacing, height, d, i, width }) {
    const barUnitWidth = barSpacing + barWidth;
    // const left = (padding.left + width - barCount * barUnitWidth) / 2;
    const x = padding.left + barUnitWidth * i;
    return (
      <g key={`tick${i}`} transform={`translate(${x},${height - padding.bottom})`}>
        <line x1="0" x2="0" y1="-1" y2="5" className="stacked-bar__axis" />
        <text x="0" y="20" textAnchor="middle" className="stacked-bar__tick-label">
          {d.year}
        </text>
      </g>
    );
  }

  static getScanLine({ width, padding, height, tickScale, d, i }) {
    const y = height - tickScale(d) - padding.bottom;
    const lineWidth = width - padding.left - padding.right;

    const formatText = d3.format(',d');
    let value = (formatText(d) > 1 && formatText(d)) || d;
    return (
      <g key={`stacked-bar__scan-${i}`} transform={`translate(${padding.left},${y})`}>
        <text
          y="0"
          x="-5"
          textAnchor="end"
          alignmentBaseline="middle"
          className="stacked-bar__tick-label"
        >
          {value}
        </text>
        <line x1="0" x2={lineWidth} y1="0" y2="0" className="stacked-bar__axis" />
      </g>
    );
  }

  static getYAxis({ height, padding }) {
    return (
      <line
        x1={padding.left}
        x2={padding.left}
        y1={padding.top}
        y2={height - padding.bottom}
        className="stacked-bar__axis"
      />
    );
  }

  renderBars({ heightScale, filteredAnalyses, barWidth, barSpacing }) {
    const {
      padding,
      updateAnalysisId,
      analysisId,
      updateTooltip,
      removeTooltip,
      chartType,
    } = this.props;
    if (filteredAnalyses === null || !filteredAnalyses.length) return <div />;

    const getBarClass = bar => {
      let baseClass = 'stacked-bar__bar-group';

      if (bar.anl_id === analysisId) {
        baseClass += ' stacked-bar__bar-group--selected';
      }
      return baseClass;
    };

    const bars = filteredAnalyses.map(d => {
      const barStyle = {
        width: `${barWidth}px`,
        marginRight: `${barSpacing / 2}px`,
        marginLeft: `${barSpacing / 2}px`,
        marginBottom: `${padding.bottom}px`,
      };

      return (
        <div
          key={d.anl_id}
          onClick={() => updateAnalysisId(d.anl_id)}
          className={getBarClass(d)}
          // onMouseMove={(e) => {
          //   updateTooltip({ e, analysis: d });
          // }}
          // onMouseOut={removeTooltip}
          // onBlur={removeTooltip}
          style={barStyle}
        >
          {(d.phase || []).map(phase =>
            StackedBarContent.getStackedBar({
              heightScale,
              phase,
              d,
              updateTooltip,
              removeTooltip,
              chartType,
            })
          )}
        </div>
      );
    });
    return bars;
  }

  renderAxes({ width, height, barSpacing, barWidth, heightScale, filteredAnalyses }) {
    const { padding, chartType } = this.props;
    if (filteredAnalyses === null) return <div />;

    const getSvgStyle = () => ({
      width: `${width}px`,
      height: `${height}px`,
      position: 'absolute',
      left: '0px',
      bottom: '0px',
      zIndex: '0',
      // background: 'grey',
      pointerEvents: 'none',
    });

    const firstYearInstances = filteredAnalyses.reduce(
      (accumular, d, i) => {
        const dateIndex =
          d.condition === 'C'
            ? 'analysis_date'
            : d.active_period === 'A'
            ? 'second_projected_from_date'
            : d.active_period === 'P'
            ? 'projected_from_date'
            : 'current_from_date';
        const year =
          d.hasOwnProperty(dateIndex) && d[dateIndex]
            ? d[dateIndex].substring(d[dateIndex].length, d[dateIndex].length - 4)
            : '';
        if (!accumular.years.includes(year)) {
          const years = [...accumular.years, year];
          const indexes = [...accumular.indexes, i];
          return { years, indexes };
        }
        return accumular;
      },
      { years: [], indexes: [] }
    );

    const drawUniqueTick = (d, i) => {
      if (firstYearInstances.indexes.includes(i)) {
        return StackedBarContent.getTick({
          d,
          i,
          padding,
          barCount: filteredAnalyses.length,
          barWidth,
          barSpacing,
          height,
          width,
        });
      }
      return <g key={`tick${i}`} />;
    };
    const tickScale = d3
      .scaleLinear()
      // .domain(heightScale.domain().map(d => (d / 1000000)))
      .domain(heightScale.domain().map(d => (chartType === 'percentage' ? d : d / 1000000)))
      .range(heightScale.range());

    const yTicks = tickScale.ticks(chartType === 'percentage' ? 10 : 5);
    return (
      <svg style={getSvgStyle()}>
        {StackedBarContent.getAxisLine({
          padding,
          width,
          height,
        })}
        {filteredAnalyses.map(drawUniqueTick)}
        {StackedBarContent.getYAxis({
          padding,
          height,
        })}
        {yTicks.map((d, i) =>
          StackedBarContent.getScanLine({
            width,
            padding,
            height,
            tickScale,
            d,
            i,
          })
        )}
      </svg>
    );
  }

  render() {
    const { padding, width, height, analyses, chartType } = this.props;
    const filteredAnalyses = StackedBarContent.getFilteredAnalyses({ analyses });
    const { barWidth, barSpacing } = StackedBarContent.getXAxisProps({ width, analyses, padding });
    const { heightScale } = StackedBarContent.getScales({
      analyses: filteredAnalyses,
      height,
      padding,
      chartType,
    });
    const innerContainerStyle = { paddingLeft: `${padding.left}px` };

    return (
      <div className="stacked-bar__inner">
        <div className="stacked-bar__y-outer">
          <div className="stacked-bar__y-text">
            {chartType === 'population' && (
              <React.Fragment>
                Population
                <br />
                (millions of people)
              </React.Fragment>
            )}
            {chartType === 'percentage' && (
              <React.Fragment>
                Population
                <br />
                (%)
              </React.Fragment>
            )}
          </div>
        </div>

        <div style={innerContainerStyle} className="stacked-bar__groups">
          {this.renderBars({
            heightScale,
            filteredAnalyses,
            barWidth,
            barSpacing,
          })}
        </div>
        {this.renderAxes({
          width,
          height,
          barWidth,
          barSpacing,
          heightScale,
          filteredAnalyses,
        })}
      </div>
    );
  }
}

export default StackedBarContent;
