/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { sum, format } from 'd3';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarBorder } from '@fortawesome/pro-regular-svg-icons';

import Tooltip from '../../../Tooltip/Tooltip.jsx';
import TooltipChart from '../TooltipChart/TooltipChartNew.jsx';

const AtlasTooltip = ({ feature, pos, message, disclaimer, persistent, onClose, top, left }) => {
  if (message) {
    return (
      <Tooltip pos={pos}>
        <div className="tooltip__text">{message}</div>
      </Tooltip>
    );
  }

  const {
    show_area_popups,
    analysis_name,
    condition,
    confidence_level,
    country_fullname,
  } = feature.properties;

  let {
    phase1_pop,
    phase2_pop,
    phase3_pop,
    phase4_pop,
    phase5_pop,
    overall_phase,
    title,
  } = feature.properties;
  if (
    feature.properties.group &&
    feature.properties.view_level &&
    feature.properties.view_level.toLowerCase() === 'group'
  ) {
    ({
      phase1_pop,
      phase2_pop,
      phase3_pop,
      phase4_pop,
      phase5_pop,
      overall_phase,
      title,
    } = JSON.parse(feature.properties.group));
  }

  let population = [phase1_pop, phase2_pop, phase3_pop, phase4_pop, phase5_pop];
  let totalPop = 0;

  let threePlus = 0;

  let threePlusPercentage = 0;
  if (feature.layer && feature.layer.id === 'countries') {
    const { phase7_pop, phase6_per, phase6_pop } = feature.properties;
    totalPop = phase7_pop;
    threePlus = phase6_pop;
    threePlusPercentage = phase6_per;
  } else {
    if (condition === 'C') population = population.slice(0, 4);
    totalPop = sum(population);
    threePlus = sum(population.slice(2));
    threePlusPercentage = threePlus / totalPop;
  }
  // if (!totalPop) return '';

  const conditionClassPrefix = condition === 'C' ? 'chronic-' : '';
  const analysisPhaseLabel = condition === 'C' ? 'Level' : 'Phase';

  let phaseText;

  if (overall_phase > 0 && overall_phase < 9) {
    phaseText = (
      <strong>
        {overall_phase === 6 ? 'Phase 5: Famine (with reasonable evidence)' : `${analysisPhaseLabel}: ${overall_phase}`}
      </strong>
    );
  } else if (overall_phase === 0) {
    phaseText = <strong>Not Analyzed</strong>;
  } else if (overall_phase === 9) {
    phaseText = <strong>Inadequate Evidence</strong>;
  }

  const p3Text =
    (overall_phase > 0 && overall_phase < 9) || country_fullname || feature.properties.group
      ? ` ${analysisPhaseLabel} 3+: ${format(',d')(threePlus)} (${format('.0%')(
          threePlusPercentage
        )})`
      : '';

  const confidenceText = confidence_level !== undefined && (
    <div className="star-confidence">
      <strong>Evidence level: </strong>
      <span className="large-star">
        {_.range(0, confidence_level).map(i => (
          <FontAwesomeIcon key={i} icon={faStar} />
        ))}
        {_.range(confidence_level, 3).map(i => (
          <FontAwesomeIcon key={i} icon={faStarBorder} />
        ))}
      </span>
    </div>
  );

  return (
    <Tooltip
      pos={pos}
      phase={overall_phase || 0}
      condition={condition}
      persistent={persistent}
      top={top}
      left={left}
      onClose={onClose}
    >
      <div className={`tooltip__title`}>
        {' '}
        <span className="country__name">
          {country_fullname ? `${country_fullname.toLowerCase()} - ` : ''}
        </span>{' '}
        {title || analysis_name}
      </div>
      {show_area_popups && (
        <TooltipChart population={population} conditionClassPrefix={conditionClassPrefix} />
      )}
      <div className="tooltip__text">
        {phaseText}
        {show_area_popups && p3Text}
        {confidence_level ? confidenceText : ''}

        {disclaimer && <div className="margin-t-5">{disclaimer}</div>}
      </div>
    </Tooltip>
  );
};

AtlasTooltip.propTypes = {
  feature: PropTypes.shape({
    properties: PropTypes.shape({
      phase1_pop: PropTypes.number,
      phase2_pop: PropTypes.number,
      phase3_pop: PropTypes.number,
      phase4_pop: PropTypes.number,
      phase5_pop: PropTypes.number,
      overall_phase: PropTypes.number,
      title: PropTypes.string,
      condition: PropTypes.string,
      confidence_level: PropTypes.number,
      group: PropTypes.string,
    }),
  }),
  message: PropTypes.string,
  pos: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  top: PropTypes.bool,
  left: PropTypes.bool,
  ar: PropTypes.bool,
};

AtlasTooltip.defaultProps = {
  feature: {
    properties: {
      phase1_pop: 0,
      phase2_pop: 0,
      phase3_pop: 0,
      phase4_pop: 0,
      phase5_pop: 0,
      overall_phase: 0,
      title: '',
      condition: 'A',
      confidence_level: null,
      group: null,
    },
  },
  message: null,
  pos: {
    x: 0,
    y: 0,
  },
  top: false,
  left: false,
  ar: false,
};

export default AtlasTooltip;
