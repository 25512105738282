import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

import './PhaseFilter.scss';

class PhaseFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: true,
    };
  }

  handleClick = e => {
    const phase = parseInt(e.currentTarget.getAttribute('data-value'), 10);
    if (phase && !Number.isNaN(phase)) {
      const active = !this.state[`active${phase}`];
      const newState = {};
      newState[`active${phase}`] = active;
      this.setState(newState);
      let phases = [parseInt(phase, 10)];
      if (phase === 5) phases = [...phases, 6];
      this.props.handler(phases, active);
    }
  };

  render() {
    const prefix = this.props.analysisCondition === 'C' ? 'Level' : 'Phase';
    return (
      <div className={`phaseFilter ${this.props.analysisCondition === 'A' ? 'acute' : 'chronic'}`}>
        <span
          className={`badge badge-pill phase-filter phase1 ${
            this.state.active1 ? 'active-filter' : ''
          }`}
          data-value={1}
          onClick={this.handleClick}
        >
          <FontAwesomeIcon icon={faEye} /> {prefix} 1
        </span>
        <span
          className={`badge badge-pill phase-filter phase2 ${
            this.state.active2 ? 'active-filter' : ''
          }`}
          data-value={2}
          onClick={this.handleClick}
        >
          <FontAwesomeIcon icon={faEye} /> {prefix} 2
        </span>
        <span
          className={`badge badge-pill phase-filter phase3 ${
            this.state.active3 ? 'active-filter' : ''
          }`}
          data-value={3}
          onClick={this.handleClick}
        >
          <FontAwesomeIcon icon={faEye} /> {prefix} 3
        </span>
        <span
          className={`badge badge-pill phase-filter phase4 ${
            this.state.active4 ? 'active-filter' : ''
          }`}
          data-value={4}
          onClick={this.handleClick}
        >
          <FontAwesomeIcon icon={faEye} /> {prefix} 4
        </span>
        {this.props.analysisCondition === 'A' && (
          <span
            className={`badge badge-pill phase-filter phase5 ${
              this.state.active5 ? 'active-filter' : ''
            }`}
            data-value={5}
            onClick={this.handleClick}
          >
            <FontAwesomeIcon icon={faEye} /> {prefix} 5
          </span>
        )}
      </div>
    );
  }
}

PhaseFilter.propTypes = {
  handler: PropTypes.func.isRequired,
  analysisCondition: PropTypes.string,
};

PhaseFilter.defaultProps = {
  analysisCondition: 'A',
};

export default PhaseFilter;
