import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import './ConditionFilter.scss';

class ConditionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: props.analysisCondition,
    };
  }

  handleChange = e => {
    const condition = e.target.value;
    this.setState({ condition });
    this.props.handler(condition);
  };

  render() {
    return (
      <Form inline className="conditionFilter">
        {/**<span className="header">IPC Classification: </span> &nbsp;&nbsp;&nbsp; **/}
        <Form.Check
          inline
          checked={this.state.condition === 'A'}
          value="A"
          id="A"
          label="Acute Food Insecurity"
          type="radio"
          name="condition"
          onChange={this.handleChange}
        />
        <Form.Check
          inline
          checked={this.state.condition === 'C'}
          value="C"
          id="C"
          label="Chronic Food Insecurity"
          type="radio"
          name="condition"
          onChange={this.handleChange}
        />
      </Form>
    );
  }
}

ConditionFilter.propTypes = {
  handler: PropTypes.func.isRequired,
  analysisCondition: PropTypes.string,
};

export default ConditionFilter;
