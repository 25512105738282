import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import LegendTable from '../LegendTable/LegendTable.jsx';
import './PhaseIndexPlus.scss';

class PhaseIndexPlusNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
    this.updateHover = this.updateHover.bind(this);
  }

  updateHover(hover) {
    this.setState({ hover });
    const { setHighlightedPhases } = this.props;
    if (hover) {
      setHighlightedPhases([3, 4, 5]);
    } else {
      setHighlightedPhases(null);
    }
  }

  renderBar(populationByPhase) {
    return populationByPhase.map(d => (
      <div
        className={`phase-bar phase${d.phase}`}
        key={d.phase}
        style={{
          width: `${d.percent * 100}%`,
          visibility: d.percent > 0 ? 'visible' : 'hidden',
        }}
        onMouseOver={() => {
          if (d.phase >= 3) this.updateHover(true);
        }}
        onMouseOut={() => {
          if (d.phase >= 3) this.updateHover(false);
        }}
      />
    ));
  }

  render() {
    const {
      population,
      p3plusPercentage,
      p3plusPop,
      overallPhase,
      className,
      analysisCondition,
    } = this.props;

    const totalPop = d3.sum(population);
    const populationByPhase = totalPop
      ? population.map((p, i) => {
          return {
            data: p,
            population: p,
            phase: i + 1,
            percent: p / totalPop,
          };
        })
      : null;
    if (!populationByPhase) return <div />;

    const conditionPhasePlus = 3;
    const analysisPhaseLabel = analysisCondition === 'C' ? 'Level' : 'Phase';
    const analysisPhaseLabelPrefix = analysisCondition === 'C' ? 'L' : 'P';

    const lineStyle = {
      left: `${(1 - p3plusPercentage) * 100}%`,
      visibility: p3plusPercentage < 1 ? 'visible' : 'hidden',
    };
    const populationFormat = d3.format(',d');
    const hasData = !populationByPhase.every(d => d.population === 0);
    const validBarChart = true;
    return (
      <div className={`phase-plus ${className} ${analysisCondition === 'C' ? 'chronic' : 'acute'}`}>
        {(!hasData || overallPhase === 9) && (
          <div className="no-data-chart">
            {overallPhase === 9 ? `Insufficient Evidence/Data` : `Not Analyzed`}
          </div>
        )}

        {!validBarChart && (
          <div className="no-data-chart">
            Total {analysisPhaseLabel} population provided is greater than the estimated area
            population
          </div>
        )}

        {hasData && overallPhase !== 9 && validBarChart && (
          <div className={`phase-bars ${analysisCondition === 'C' ? ' chronic-phase-bars ' : ''}`}>
            {this.renderBar(populationByPhase, conditionPhasePlus)}
            <div className="phase-plus-line" style={lineStyle} />
          </div>
        )}

        {hasData && overallPhase !== 9 && validBarChart && (
          <div>
            <LegendTable header>
              <div className="header-row" key="row1">
                <div className="flex">
                  Population {analysisPhaseLabelPrefix}
                  {conditionPhasePlus}+
                </div>
                <div className="header-row percent">%</div>
              </div>
              <div key="row2">
                <div className="flex">{populationFormat(p3plusPop)}</div>
                <div className="percent">{d3.format('d')(p3plusPercentage * 100)}</div>
              </div>
            </LegendTable>
          </div>
        )}
      </div>
    );
  }
}

PhaseIndexPlusNew.defaultProps = {
  population: [],
  percentages: [],
  p3plusPop: 0,
  p3plusPercentage: 0,
  overallPhase: 3,
  className: '',
  setHighlightedPhases: () => {},
  analysisCondition: 'A',
};

PhaseIndexPlusNew.propTypes = {
  population: PropTypes.arrayOf(PropTypes.number),
  percentages: PropTypes.arrayOf(PropTypes.number),
  p3plusPop: PropTypes.number,
  p3plusPercentage: PropTypes.number,
  overallPhase: PropTypes.number,
  className: PropTypes.string,
  setHighlightedPhases: PropTypes.func,
  analysisCondition: PropTypes.string,
};

export default PhaseIndexPlusNew;
