import React from 'react';
import LegendTable from '../LegendTable/LegendTable';
import './Interventions.scss';

function AcuteInterventions() {
  return (
    <LegendTable className="interventions" header>
      <div key="header">
        <div className="phase">Phase</div>
        <div className="swatch" />
        <div>Intervention and Action</div>
      </div>
      <div key="p5">
        <div className="swatch phase5" />
        <div><span className="phase-name">Phase 5</span>
          People in need of action to prevent total collapse of livelihoods and widespread death.
        </div>
      </div>
      <div key="p4">
        <div className="swatch phase4" />
        <div><span className="phase-name">Phase 4</span>
        People in need of urgent action to save lives and livelihoods.
        </div>
      </div>
      <div key="p3">
        <div className="swatch phase3" />
        <div><span className="phase-name">Phase 3</span>
          People in need of urgent action to reduce food gaps and protect livelihoods.
        </div>
      </div>
      <div key="p2">
        <div className="swatch phase2" />
        <div><span className="phase-name">Phase 2</span>
          People not in need of urgent action – in need of livelihood protection
        </div>
      </div>
      <div key="p1">
        <div className="swatch phase1" />
        <div><span className="phase-name">Phase 1</span>
          { /* People not in need of urgent action – in need of action to build resilience and for disaster risk reduction */ }
          People not in need of urgent action – in need of interventions and actions
        </div>
      </div>
    </LegendTable>
  );
}

export default AcuteInterventions;
