import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSearchPlus, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import './AtlasDropdown.scss';

class AtlasDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchText: '',
      selectedText: '',
    };
    this.onClick = this.onClick.bind(this);
    this.onType = this.onType.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  onClick(e) {
    e.stopPropagation();
    const { open } = this.state;
    if (!open) {
      window.addEventListener('click', this.closeMenu);
      this.setState({
        open: true,
      });
    } else {
      this.closeMenu();
    }
  }

  onType(e) {
    this.setState({
      searchText: e.target.value,
    });
  }

  onSelect(d) {
    const { onMenuClick } = this.props;
    if (d.aar_id === null) return;
    onMenuClick(d);

    this.setState({
      selectedText: d.title,
    });

    this.closeMenu();
  }

  getHeader(value) {
    const {
      selectedText,
    } = this.state;
    value = selectedText || 'Select an area'
    return (
      <div
        className="atlas-dropdown__header"
        onClick={this.onClick}
      >
        <FontAwesomeIcon icon={faSearchPlus} />
        <span>{value}</span>
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    );
  }


  getList() {
    const {
      open,
      searchText,
    } = this.state;
    const {
      menuList,
      // onMenuClick,
      search,
    } = this.props;

    let listClass = 'atlas-dropdown__list';
    if (!open) {
      listClass += ' atlas-dropdown__list--closed';
    }
    let filteredMenuItems;
    if (searchText !== '') {
      filteredMenuItems = menuList
        .filter(d => d.title.toLowerCase().indexOf(searchText) !== -1);
      if (filteredMenuItems.length === 0) {
        filteredMenuItems.push({
          aar_id: null,
          title: `No areas found matching '${searchText}'`,
        });
      }
    } else {
      filteredMenuItems = menuList;
    }

    return (
      <div
        className={listClass}
        onClick={(e) => { e.stopPropagation(); }}
      >
        <div className="atlas-dropdown__list-inner">
          {search && (
          <div className="atlas-dropdown__input-row">
            <input
              className="atlas-dropdown__input"
              placeholder="Search areas..."
              onChange={this.onType}
              value={searchText}
            />
            <FontAwesomeIcon className="atlas-dropdown__input-icon" icon={faSearch} />
          </div>
          )}
          {filteredMenuItems.map(d => (
            <div
              key={d.aar_id}
              className={`atlas-dropdown__list-item${d.aar_id === null ? ' no-result' : ''}`}
              onClick={() => this.onSelect(d)}
            >
              {d.title}
            </div>
          ))}
        </div>
      </div>
    );
  }

  closeMenu() {
    window.removeEventListener('click', this.closeMenu);
    this.setState({
      open: false,
      // searchText: '',
    });
  }


  render() {
    const { className } = this.props;
    return (
      <div className={`atlas-dropdown ${className || ''}`}>
        {this.getHeader()}
        {this.getList()}
      </div>
    );
  }
}


AtlasDropdown.defaultProps = {
  search: true,
  className: null,

};

AtlasDropdown.propTypes = {
  search: PropTypes.bool,
  className: PropTypes.string,
};

export default AtlasDropdown;
