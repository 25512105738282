export default [
  { country: 'BD', bbox: [88.0438690185547, 20.7448196411133, 92.6693420410156, 26.6261405944824] },
  { country: 'HT', bbox: [-74.467789, 18.022778, -71.628891, 20.09222] },
  {
    country: 'ZW',
    bbox: [25.2379150390625, -22.4147644042969, 33.0715942382812, -15.6165285110474],
  },
  { country: 'CD', bbox: [12.2145519256592, -13.4580574035645, 31.3027763366699, 5.3806939125061] },
  { country: 'AF', bbox: [60.5041656494141, 29.4061050415039, 74.9157409667969, 38.4719772338867] },
  {
    country: 'BI',
    bbox: [28.9849987030029, -4.4480562210083, 30.8531913757324, -2.30156254768372],
  },
  { country: 'CF', bbox: [14.418888092041, 2.22125959396362, 27.4597206115723, 11.0008325576782] },
  { country: 'DJ', bbox: [41.7598571777344, 10.9422206878662, 43.4204139709473, 12.7083320617676] },
  {
    country: 'GT',
    bbox: [-92.2467803955078, 13.7458324432373, -88.2147369384766, 17.8211097717285],
  },
  {
    country: 'LS',
    bbox: [27.0139713287354, -30.6505279541016, 29.4555530548096, -28.5706958770752],
  },
  {
    country: 'MG',
    bbox: [43.2368240356445, -25.5883369445801, 50.5013885498047, -11.945556640625],
  },
  {
    country: 'MW',
    bbox: [32.6818695068359, -17.1352806091309, 35.9209671020508, -9.37666797637939],
  },
  {
    country: 'MZ',
    bbox: [30.2130165100098, -26.860279083252, 40.8461074829102, -10.4711112976074],
  },
  { country: 'PH', bbox: [116.949996948242, 5.04916572570801, 126.598037719727, 19.3911075592041] },
  { country: 'PK', bbox: [60.8663024902344, 23.6880493164062, 77.8239288330078, 37.0607872009277] },
  {
    country: 'SO',
    bbox: [40.9886093139648, -1.67486810684204, 51.4113159179688, 11.9791660308838],
  },
  { country: 'SS', bbox: [24.15192986, 3.48099899, 35.86994934, 12.21899986] },
  {
    country: 'SZ',
    bbox: [30.7983322143555, -27.3163909912109, 32.1333999633789, -25.7283363342285],
  },
  {
    country: 'TZ',
    bbox: [29.3408317565918, -11.7404174804688, 40.4368133544922, -0.997222304344177],
  },
  {
    country: 'UG',
    bbox: [29.5743026733398, -1.47611033916473, 35.0097198486328, 4.22277736663818],
  },
  { country: 'YE', bbox: [41.8150793350001, 12.108307349, 54.534055891, 19.0000000000001] },
  {
    country: 'HN',
    bbox: [-89.3504943847656, 12.9851722717285, -83.1318511962891, 16.4358310699463],
  },
  {
    country: 'SV',
    bbox: [-90.1080627441406, 13.1563873291016, -87.6946716308594, 14.4319820404053],
  },
  { country: 'TL', bbox: [124.9325676104, -9.5614069585705, 127.53083421196, -8.0849902776038] },
  { country: 'ZM', bbox: [21.9963874816895, -18.0749225616455, 33.702278137207, -8.1916675567627] },
  { country: 'AO', bbox: [11.7312488555908, -18.0163917541504, 24.0844421386719, -4.388991355896] },
  {
    country: 'KE',
    bbox: [33.9072189331055, -4.66961765289307, 41.9051666259766, 4.62249946594238],
  },
  { country: 'SD', bbox: [21.8389492, 8.67911911000001, 38.8493042, 23.14514732] },
  { country: 'ET', bbox: [32.9917984008789, 3.40666627883911, 47.9882431030273, 14.8836097717285] },
  {
    country: 'NA',
    bbox: [11.7163887023926, -28.9618759155273, 25.2644309997559, -16.9541702270508],
  },
];
