/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import { json } from 'd3';

import ContentBlockPublicAnalyis from './ContentBlockPublicAnalysis.jsx';

import DataContext from '../../DataContext/DataContext';

class ContentBlockPublicAnalyisGroup extends ContentBlockPublicAnalyis {
  componentDidMount() {
    const { group_id } = this.props.params;
    const { activeAnalysisPeriod } = this.state;
    Promise.all([
      json(`${process.env.REACT_APP_S3_API_URL}/api/gsu/active/group/${group_id}?public=true`),
    ]).then(([analysisData]) => {
      const period = analysisData ? analysisData.active_period : activeAnalysisPeriod;
      this.setState({ activeAnalysisPeriod: period });

      const countryName = analysisData.full_country_name;
      const analysisName = analysisData.gsu_analysis_title || analysisData.analysis_name;
      const countryCode = analysisData.country;
      const { periods, show_area_popups } = analysisData;
      const primaryAnalysisCondition =
        analysisData && analysisData.condition ? analysisData.condition : null;

      this.setState({
        isCHCountries: true, // we need to query this from db. Perhaps expand sys_geographic_units adding a region column
        activeAnalysis: analysisData,
        analysisName,
        noData: false,
        periods,
        groupId: group_id,
        legendView: 'key',
        primaryAnalysisCondition,
        activeAnalysisPeriod: period,
        countryName,
        countryCode,
        selectedCountryName: countryName,
        show_area_popups,
      });

      Promise.all([
        json(`${process.env.REACT_APP_S3_API_URL}/api/gsu/active/group/bounds/${group_id}`),
      ]).then(([bounds]) => {
        this.setState({ initialBounds: bounds });
      });
    });
  }
}

ContentBlockPublicAnalyisGroup.contextType = DataContext;

ContentBlockPublicAnalyisGroup.propTypes = {
  params: PropTypes.shape({
    analysisId: PropTypes.string,
    condition: PropTypes.string,
    period: PropTypes.string,
    mapOnly: PropTypes.string,
    includeTable: PropTypes.string,
  }),
};

export default ContentBlockPublicAnalyisGroup;
