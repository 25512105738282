import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import './TooltipChart.scss';
// import DataContext from '../../../../DataContext/DataContext';

class TooltipChart extends React.Component {
  getBar({ phase, barScale, conditionClassPrefix }) {
    const { size } = this.props;
    const { height } = size;

    const barStyle = {
      height: `${height}px`,
      width: `${barScale(phase.population_percentage)}px`,
    };
    return (
      <div
        key={phase.phase}
        className={`tooltip-chart__bar tooltip-chart__bar--${conditionClassPrefix}phase${phase.phase}`}
        style={barStyle}
      />
    );
  }

  getBars({ conditionClassPrefix }) {
    const { size, phases } = this.props;
    const { width } = size;

    const barScale = d3.scaleLinear().domain([0, 1]).range([0, width]);

    return (
      <div className="tooltip-chart__bars">
        {phases.map(phase =>
          this.getBar({
            phase,
            barScale,
            conditionClassPrefix,
          })
        )}
      </div>
    );
  }

  render() {
    const { size, phases, conditionClassPrefix } = this.props;
    const { width, height } = size;
    const chartStyle = {
      width: `${width}px`,
      height: `${height}px`,
      // background: 'grey',
    };
    if (phases.every(d => d.population_percentage === 0)) return '';
    return (
      <div style={chartStyle} className="tooltip-chart tooltip__chart">
        {this.getBars({ conditionClassPrefix })}
      </div>
    );
  }
}

TooltipChart.defaultProps = {
  size: {
    width: 200,
    height: 15,
  },
  conditionClassPrefix: '',
};

// TooltipChart.contextType = DataContext;

TooltipChart.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  phases: PropTypes.arrayOf(PropTypes.object).isRequired,
  conditionClassPrefix: PropTypes.string,
};

export default TooltipChart;
