import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import './AtlasStyleRadio.scss';

class AtlasStyleRadio extends React.Component {
  render() {
    return (
      <Form.Group className="atlasStyleRadio">
        {this.props.buttons.map((b, i) => (
          <Form.Check
            defaultChecked={b.defaultChecked}
            key={i}
            data-id={i}
            label={b.label}
            type="radio"
            name={this.props.name}
            onClick={e => {
              const data = this.props.buttons[e.target.getAttribute('data-id')];
              const linked = data.linked || [];
              this.props.handler(this.props.name, [data, ...linked]);
            }}
          />
        ))}
      </Form.Group>
    );
  }
}

AtlasStyleRadio.propTypes = {
  name: PropTypes.string,
  handler: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.object),
};

export default AtlasStyleRadio;
