import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';
import ChronicPhaseDonut from './ChronicPhaseDonut/ChronicPhaseDonut';
import ChronicPhaseTable from './ChronicPhaseTable/ChronicPhaseTable';
import Exportable from '../Exportable/Exportable';
import './ChronicPhase.scss';

function getPopulationByPhase(filtered) {
  const phaseData = filtered.map(d => d3.range(4).map(i => d[`phase${i + 1}`]));
  // console.log('phaseData', phaseData);
  const byPhase = d3.range(4).map(i => [].concat(phaseData.map(d => (d[i] ? d[i].population : 0))));
  const byPhasePercent = d3
    .range(4)
    .map(i => [].concat(phaseData.map(d => (d[i] ? d[i].population_percentage : 0))));

  //const totalPhasePop = d3.sum(byPhase, d => d3.sum(d));
  const totalPop = filtered && filtered.length > 0 ? filtered[0].area_population : 0;

  if (!totalPop) return null;
  return byPhase.map((p, i) => {
    const sum = d3.sum(p);
    return {
      data: p,
      population: sum,
      phase: i + 1,
      percent: byPhasePercent[i][0],
    };
  });
}

class ChronicPhase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: null };
    this.updateHighlight = this.updateHighlight.bind(this);
  }

  updateHighlight(phase) {
    this.setState({ hover: phase });

    // We disabled this feature;
    
    // const { population } = this.props
    // const areas = (population || []).filter((d) => {
    //   return d.overall_phase === phase;
    // }).map(d => d.aar_id);
    
    // const { updateHighlightedAreas } = this.context;
    // updateHighlightedAreas(areas);
  }

  render() {
    const { hover } = this.state;
    const { analysisPeriod, population } = this.props;
    const projected = analysisPeriod !== 'C';
    const { view, selectedArea, highlightedAreas, view_level } = this.context;
    
    const areaToFilter = selectedArea || ((highlightedAreas && highlightedAreas.length === 1)
          ? highlightedAreas[0] : null);

    let wFiltered = (population || []).find(d => {
      if (!areaToFilter) return +d.aag_id === 0;
      return d.aar_id === areaToFilter;
    });
      
    if (areaToFilter && wFiltered && view_level && view_level.toLowerCase() === 'group') {
      wFiltered = (population || []).find(d => {
        return d.aag_id === wFiltered.aag_id && d.hasOwnProperty('children');
      });
    }

    const filtered = [];
    if (wFiltered) filtered[0] = wFiltered;
    const populationByPhase = getPopulationByPhase(filtered);
    // const condition = filtered[0] && filtered[0].condition ? filtered[0].condition : '';
    const condition = filtered[0] && filtered[0].condition ? ` condition-${filtered[0].condition}` : '';
    const overallPhase = filtered[0] && filtered[0].overall_phase ? filtered[0].overall_phase : null;
    return (
      <div className={`chronic-phase${condition ? ` condition-${condition}` : '' }`}>
        {(populationByPhase === null || overallPhase === 9) && <div className="no-data-chart">{ overallPhase === 9 ? `Insufficient Evidence/Data` : `Not Analyzed`}</div>}
        {populationByPhase !== null && overallPhase !== 9 && (
          <Exportable suffix={projected ? '_projected' : ''} name="chronic phase graphic" className="chronic-phase__graphic">
            <ChronicPhaseDonut
              populationByPhase={populationByPhase}
              hover={hover}
              updateHighlight={this.updateHighlight}
              view={view}
            />
          </Exportable>
        )}
        {populationByPhase !== null && overallPhase !== 9 && (
          <Exportable suffix={projected ? '_projected' : ''} className="export chronic-phase__legend" name="chronic phase legend">
            <ChronicPhaseTable
              populationByPhase={populationByPhase}
              hover={hover}
              updateHighlight={this.updateHighlight}
              view={view}
            />
          </Exportable>
        )}
      </div>
    );
  }
}

ChronicPhase.contextType = DataContext;

ChronicPhase.defaultProps = {
  population: null,
  analysisPeriod: 'C',
};

ChronicPhase.propTypes = {
  population: PropTypes.arrayOf(PropTypes.object),
  analysisPeriod: PropTypes.string,
};

export default ChronicPhase;
