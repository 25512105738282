/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, {
  Source,
  Layer,
  LinearInterpolator,
  WebMercatorViewport,
  NavigationControl,
} from 'react-map-gl';
import bbox from '@turf/bbox';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAfrica, faUndo } from '@fortawesome/pro-solid-svg-icons';
import ReactResizeDetector from 'react-resize-detector';
import AtlasGeneric from './AtlasGeneric.jsx';
import AtlasTooltip from './AtlasTooltip/AtlasTooltip.jsx';
import AtlasButton from './AtlasButton/AtlasButton.jsx';
import AtlasInset from './AtlasInset/AtlasInset.jsx';
import {
  countries,
  chCountries,
  chCountriesBorder,
  countriesHighlight,
  countriesMask,
  areasHighlight,
  areas,
  areasGroup,
  areasFamine,
  areasGroupFamine,
  urbanAreas,
  urbanAreasCenter,
  idp,
  idpHover,
  hhg,
  hhgHover,
  rfg,
  rfgHover,
  urbanAreasGroup,
  urbanAreasCenterGroup,
  idpGroup,
  idpHoverGroup,
  hhgGroup,
  hhgHoverGroup,
  areasBorder,
  areasGroupBorder,
  areasLabels,
  areasGroupLabels,
  bags,
    rfm,
} from './interactive-layers';
import countryBounds from './country-bounds';

import './AtlasNew.scss';

class Atlas extends AtlasGeneric {
  zoomReset = newBounds => {
    let { viewport } = this.state;
    viewport.pitch = 0;
    viewport.bearing = 0;
    viewport = new WebMercatorViewport(viewport);
    const bounds = newBounds || this.state.selectedCountryBounds || this.state.globalBounds;
    const { longitude, latitude, zoom } = viewport.fitBounds(bounds, {
      padding: 40,
    });
    this.setState({
      viewport: {
        ...viewport,
        longitude,
        latitude,
        zoom,
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 500,
      },
    });
  };

  onClick = event => {
    if (!this.state.countryZoomLevel) {
      const feature = _.find(event.features, f => f.sourceLayer === 'countries');
      if (feature !== undefined) {
        const { properties } = feature;
        if (properties && this.props.clickOverride) {
          this.props.clickOverride(properties);
        } else if (feature.properties.mask) {
          this.showMaskDetails(feature, event);
        } else {
          this.zoomToCountry(feature, event);
        }
      } else if (_.find(event.features, f => f.sourceLayer === 'chcountries')) {
        window.open('http://www.food-security.net/visualise/', '_blank');
      }
    }
  };

  onHover = event => {
    const x = event.srcEvent.clientX;
    const y = event.srcEvent.clientY;
    if (this.state.countryZoomLevel && this.state.showMask) {
      const tooltip = (
        <AtlasTooltip
          key="mapTooltip"
          message="To See IPC Map click on ‘View Full Analysis’"
          pos={{ x, y }}
        />
      );
      this.setState({ tooltip });
    } else if (_.find(event.features, f => f.sourceLayer === 'chcountries')) {
      const tooltip = (
        <AtlasTooltip key="mapTooltip" message="Click to open RPCA Website" pos={{ x, y }} />
      );
      this.setState({ tooltip });
    } else {
      const { features } = event;
      const feature = _.find(
        features,
        f => f.layer.id === (this.state.countryZoomLevel ? 'areas' : 'countries') || 'urbanAreas'
      );
      let highlightedFeature;
      if (feature) {
        highlightedFeature = {
          ...feature.properties,
          layer: feature.layer.id,
          id: feature.id,
          sourceLayer: feature.layer['source-layer'],
        };
      }
      this.props.setHighlightedFeature(highlightedFeature);

      if (feature) {
        let popDisclaimer = '';
        if (feature.properties.mask) {
          popDisclaimer = `To See IPC Map click on the country`;
        }
        const tooltip = (
          <AtlasTooltip
            key="mapTooltip"
            feature={feature}
            pos={{ x, y }}
            disclaimer={popDisclaimer}
          />
        );
        this.setState({ tooltip });
      } else {
        this.removeTooltip();
      }
    }
  };

  showMaskDetails = (feature, event) => {
    this.props.setSelectedCountry(feature);
    // calculate the bounding box of the feature
    let [minLng, minLat, maxLng, maxLat] = bbox(feature);
    const bounds = countryBounds.find(
      c => c.country.toLowerCase() === feature.properties.country.toLowerCase()
    );
    if (bounds) [minLng, minLat, maxLng, maxLat] = bounds.bbox;

    let transition = {};
    if (event) {
      transition = {
        transitionInterpolator: new LinearInterpolator({
          around: [event.offsetCenter.x, event.offsetCenter.y],
        }),
        transitionDuration: 500,
      };
    }
    this.zoomToBounds([minLng, minLat, maxLng, maxLat], transition);
    this.setState({
      countryZoomLevel: true,
      selectedCountryBounds: [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      showMask: feature.properties.mask,
      areaCountryFilter: ['==', ['get', 'country'], feature.properties.country],
      negativeCountryFilter: ['!=', ['get', 'country'], feature.properties.country],
      areaLabelsFilter: ['==', ['get', 'country'], feature.properties.country],
    });
  };

  componentDidMount = () => {
    const { saveMapRef } = this.props;
    saveMapRef({ type: `main-C`, position: 'left', map: this.map.current.getMap() });
  };

  componentDidUpdate = prevProps => {
    let newStyle = null;
    Object.keys(this.props.styleOverrides).forEach(key => {
      const style = this.props.styleOverrides[key];
      if (
        !prevProps.styleOverrides[key] ||
        style[0].value !== prevProps.styleOverrides[key][0].value
      ) {
        newStyle = style;
      }
    });
    if (newStyle) {
      this.updateStyle(newStyle);
    }

    if (prevProps.highlightedFeature !== this.props.highlightedFeature) {
      if (prevProps.highlightedFeature) {
        this.map.current.getMap().setFeatureState(
          {
            source: 'ipc',
            sourceLayer: prevProps.highlightedFeature.sourceLayer,
            id: prevProps.highlightedFeature.id,
          },
          { hover: false }
        );
      }
      if (this.props.highlightedFeature) {
        this.map.current.getMap().setFeatureState(
          {
            source: 'ipc',
            sourceLayer: this.props.highlightedFeature.sourceLayer,
            id: this.props.highlightedFeature.id,
          },
          { hover: true }
        );
      } else {
        this.removeTooltip();
      }
    }
    if (prevProps.year !== this.props.year) {
      const [, , country] = this.state.areaCountryFilter;
      if (country) {
        const [feature] = this.map.current.getMap().querySourceFeatures('ipc', {
          sourceLayer: 'countries',
          filter: [
            'all',
            ['==', ['get', 'year'], this.props.year],
            ['==', ['get', 'country'], country],
          ],
          validate: true,
        });
        if (feature) {
          this.setState({ showMask: feature.properties.mask });
          this.props.setSelectedCountry(feature);
        } else {
          this.props.setSelectedCountry(undefined);
        }
      }
    }
  };

  onMapLoad = () => {
    const map = this.map.current.getMap();
    const { initialCountry } = this.props;
    const bounds = map.getBounds();
    this.setState({
      originalStyle: { ...map.getStyle() },
      globalBounds: [
        [bounds.getWest(), bounds.getSouth()],
        [bounds.getEast(), bounds.getNorth()],
      ],
      areaLabelsFilter: ['==', ['get', 'country'], 'NO MATCHES'],
    });
    // if (initialCountry.length > 0) this.setState({ allowGlobal: false });
    if (initialCountry.length === 1) {
      const [feature] = map.querySourceFeatures('ipc', {
        sourceLayer: 'countries',
        filter: [
          'all',
          ['==', ['get', 'country'], this.props.initialCountry[0].toUpperCase()],
          ['==', ['get', 'year'], this.props.year],
        ],
      });
      if (feature) {
        if (feature.properties.mask) {
          this.showMaskDetails(feature);
        } else {
          this.zoomToCountry(feature, null, true);
        }
      }
    } else if (initialCountry.length > 1) {
      const features = map.querySourceFeatures('ipc', {
        sourceLayer: 'countries',
        filter: [
          'all',
          [
            'in',
            ['get', 'country'],
            ['literal', this.props.initialCountry.map(i => i.toUpperCase())],
          ],
          ['==', ['get', 'year'], this.props.year],
        ],
      });
      const boundsArray = features.map(f => bbox(f.geometry));
      const maxBounds = boundsArray.reduce(
        (memo, b) => [
          Math.min(memo[0], b[0]),
          Math.min(memo[1], b[1]),
          Math.max(memo[2], b[2]),
          Math.max(memo[3], b[3]),
        ],
        [Infinity, Infinity, -Infinity, -Infinity]
      );
      this.zoomToBounds(maxBounds);
    }
  };

  // get the data layer properties either for geojson (for a single analysis) or vector tiles
  getLayers = () => {
    const areaPhaseFilter = [
      'match',
      ['get', 'overall_phase'],
      [...this.props.activePhases],
      true,
      false,
    ];

    const areaConditionFilter = ['==', ['get', 'condition'], this.props.activeCondition];

    const areaTimeFilter = this.props.current
      ? ['==', ['get', 'current'], true]
      : ['match', ['get', 'year'], [this.props.year], true, false];

    const layerData = {
      countries: { ...countries },
      chCountries: { ...chCountries },
      chCountriesBorder: { ...chCountriesBorder },
      countriesHighlight: { ...countriesHighlight },
      countriesMask: { ...countriesMask },
      areasHighlight: { ...areasHighlight },
      areas: { ...areas },
      areasGroup: { ...areasGroup },
      areasFamine: { ...areasFamine },
      areasGroupFamine: { ...areasGroupFamine },
      urbanAreas: { ...urbanAreas },
      urbanAreasCenter: { ...urbanAreasCenter },
      idp: { ...idp },
      idpHover: { ...idpHover },
      hhg: { ...hhg },
      hhgHover: { ...hhgHover },
      rfg: { ...rfg },
      rfgHover: { ...rfgHover },
      urbanAreasGroup: { ...urbanAreasGroup },
      urbanAreasCenterGroup: { ...urbanAreasCenterGroup },
      idpGroup: { ...idpGroup },
      idpHoverGroup: { ...idpHoverGroup },
      hhgGroup: { ...hhgGroup },
      hhgHoverGroup: { ...hhgHoverGroup },
      areasNegative: JSON.parse(JSON.stringify(areas)),
      areasBorder: { ...areasBorder },
      areasGroupBorder: { ...areasGroupBorder },
      areasLabels: { ...areasLabels },
      areasGroupLabels: { ...areasGroupLabels },
      bags: { ...bags },
        rfm: {...rfm}
    };
    layerData.areasNegative.id = 'areas-negative';
    layerData.areasNegative.paint['fill-opacity'] = 0;

    const areasFilter = [
      ...this.state.areaDefaultFilter,
      this.state.areaCountryFilter,
      areaPhaseFilter,
      areaConditionFilter,
    ];
    const countriesFilter = [
      ...this.state.areaDefaultFilter,
      areaTimeFilter,
      areaConditionFilter,
      this.state.areaCountryFilter,
    ];

    areasFilter.push(areaTimeFilter);

    return (
      <React.Fragment>
        <Layer beforeId="waterway-shadow" {...layerData.areasGroup} filter={areasFilter} />
        <Layer beforeId="waterway-shadow" {...layerData.areas} filter={areasFilter} />
        <Layer beforeId="waterway-shadow" {...layerData.areasGroupFamine} filter={areasFilter} />
        <Layer beforeId="waterway-shadow" {...layerData.areasFamine} filter={areasFilter} />
        <Layer beforeId="waterway-label" {...layerData.areasBorder} filter={areasFilter} />
        <Layer beforeId="waterway-label" {...layerData.areasGroupBorder} filter={areasFilter} />
        <Layer beforeId="waterway-label" {...layerData.areasHighlight} />
        <Layer
          beforeId="waterway-label"
          {...layerData.urbanAreas}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'urb'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.urbanAreasCenter}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'urb'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.idp}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'idp'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.idpHover}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'idp'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.hhg}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'hhg'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.hhgHover}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'hhg'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.rfg}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'rfg'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.rfgHover}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'rfg'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.urbanAreasGroup}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'urb'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.urbanAreasCenterGroup}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'urb'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.idpGroup}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'idp'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.idpHoverGroup}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'idp'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.hhgGroup}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'hhg'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer
          beforeId="waterway-label"
          {...layerData.hhgHoverGroup}
          filter={[
            'all',
            ['==', ['get', 'admin_type'], 'hhg'],
            areaTimeFilter,
            areaConditionFilter,
            areaPhaseFilter,
          ]}
        />
        <Layer beforeId="settlement-major-label" {...layerData.areasLabels} filter={areasFilter} />
        <Layer
          beforeId="settlement-major-label"
          {...layerData.areasGroupLabels}
          filter={areasFilter}
        />
        {(this.state.showMask || !this.state.countryZoomLevel) && (
          <Layer beforeId="waterway-label" {...layerData.countries} filter={countriesFilter} />
        )}
        {!this.state.countryZoomLevel && (
          <React.Fragment>
            <Layer beforeId="waterway-shadow" {...layerData.chCountries} />
            <Layer beforeId="waterway-shadow" {...layerData.chCountriesBorder} />
            <Layer
              beforeId="waterway-label"
              {...layerData.countriesHighlight}
              filter={countriesFilter}
            />
          </React.Fragment>
        )}
        {/* icon layer has a different source for single analysis, in the main render function */}
        {this.props.activeCondition !== 'C' && (
          <>
            {/*  filter={['all', areaTimeFilter, this.state.areaCountryFilter]} */}
            <Layer
              {...layerData.bags}
              filter={[
                'all',
                ['!=', ['get', 'icon'], 'rfm'],
                areaTimeFilter,
                this.state.areaCountryFilter,
              ]}
            />
            <Layer
              {...layerData.rfm}
              filter={['all', areaTimeFilter, this.state.areaCountryFilter]}
            />
          </>
        )}
      </React.Fragment>
    );
  };

  render() {
    const areaTimeFilter = this.props.current
      ? ['==', ['get', 'current'], true]
      : ['==', ['get', 'year'], this.props.year];

    const { tooltip } = this.state;

    // is this a single analysis/is the data layer from geojson?
    const { insets } = this.props;
    const interactiveLayerIds = [
      'areas',
      'areasGroup',
      'urbanAreas',
      'idp',
      'idpHover',
      'hhg',
      'hhgHover',
    ];
    // countries layer is only interactive outside of a single analysis
    if (this.state.showMask || !this.state.countryZoomLevel)
      interactiveLayerIds.unshift('countries', 'chcountries', 'chcountriesBorder');

    const getSources = () => (
      <Source type="vector" id="ipc" tiles={[`${process.env.REACT_APP_TILE_URL}`]} maxzoom={9}>
        {this.getLayers()}
      </Source>
    );

    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
        <div
          style={{
            height: this.disclaimer.current ? 530 - this.disclaimer.current.clientHeight : '100%',
          }}
          key={`mainMap-${this.props.condition}`}
        >
          <ReactMapGL
            key="mainMap"
            ref={this.map}
            {...this.state.viewport}
            minZoom={1}
            maxZoom={12}
            attributionControl={false}
            onHover={this.onHover}
            onClick={this.onClick}
            // onMouseDown={this.onMousedown}
            onMouseOut={this.removeTooltip}
            dragPan={this.state.allowPan}
            dragRotate={false}
            touchRotate={false}
            interactiveLayerIds={interactiveLayerIds}
            mapboxApiAccessToken="pk.eyJ1IjoiYXhpc21hcHMiLCJhIjoieUlmVFRmRSJ9.CpIxovz1TUWe_ecNLFuHNg"
            mapStyle={`${process.env.PUBLIC_URL}/styles/${this.props.basemapStyle}`}
            onViewportChange={newViewport =>
              this.setState({
                viewport: { ...newViewport },
                bounds: this.map.current ? this.map.current.getMap().getBounds() : null,
              })
            }
            onLoad={this.onMapLoad}
          >
            {getSources()}
            <div key="mainMapControls" style={{ position: 'absolute', left: 10, top: 10 }}>
              <NavigationControl showCompass={false} />
              <AtlasButton
                handler={() => this.zoomReset()}
                icon={<FontAwesomeIcon icon={faUndo} />}
                showButton={true}
              />
            </div>
          </ReactMapGL>
          <div key="disclaimer" className="map-bottom-disclaimer" ref={this.disclaimer}>
            {this.disclaimerText}
          </div>
          {this.state.allowGlobal && (
            <div key="resetControls" style={{ position: 'absolute', top: 0, right: '50%' }}>
              <AtlasButton
                showButton={this.state.countryZoomLevel}
                handler={this.mapReset}
                icon={<FontAwesomeIcon icon={faGlobeAfrica} />}
                buttonText="Back to global view"
              />
            </div>
          )}
          {insets && ( // insets is a boolean but perhaps could instead specify *which* insets
            <React.Fragment key="mapInsets">
              <AtlasInset
                filter={[...this.state.areaDefaultFilter, areaTimeFilter]}
                handle="se"
                position="nw"
                saveMapRef={this.props.saveMapRef}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
                swapZoom
              />
              <AtlasInset
                filter={[...this.state.areaDefaultFilter, areaTimeFilter]}
                handle="sw"
                position="ne"
                saveMapRef={this.props.saveMapRef}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
              />
              <AtlasInset
                filter={[...this.state.areaDefaultFilter, areaTimeFilter]}
                handle="nw"
                position="se"
                saveMapRef={this.props.saveMapRef}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
                swapZoom
              />
              <AtlasInset
                filter={[...this.state.areaDefaultFilter, areaTimeFilter]}
                handle="ne"
                position="sw"
                saveMapRef={this.props.saveMapRef}
                deleteMapRef={this.props.deleteMapRef}
                bounds={this.state.bounds}
                layers={getSources()}
              />
            </React.Fragment>
          )}
        </div>
        {tooltip}
      </ReactResizeDetector>
    );
  }
}

Atlas.propTypes = {
  styleOverrides: PropTypes.object,
  activePhases: PropTypes.arrayOf(PropTypes.number),
  current: PropTypes.bool,
  year: PropTypes.number,
  saveMapRef: PropTypes.func.isRequired,
  deleteMapRef: PropTypes.func.isRequired,
  setHighlightedFeature: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  basemapStyle: PropTypes.string,
  insets: PropTypes.bool,
  highlightedFeature: PropTypes.object,
  activeCondition: PropTypes.string,
  initialCountry: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  clickOverride: PropTypes.func,
};

Atlas.defaultProps = {
  basemapStyle: 'light.json',
  insets: true,
  highlightedFeature: null,
  activeCondition: 'A',
  initialCountry: '',
  clickOverride: null,
};

export default Atlas;
