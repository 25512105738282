import React from 'react';
import GlobalPartners from './IPC15PartnersFR.png';
import Partner from './Partner';
import './Footer.scss';
import DataContext from '../../DataContext/DataContext';

class Footer extends React.Component {
    render() {
        const { partners, countryName} = this.context;
        return (
            <div className="footer">
                <div className="partners-and-co-authoring">
                    <h4 className="title-divider">IPC Analysis Partners</h4>
                    The {countryName} IPC Food Security Analysis.  
                    {!!(partners && partners.length) && partners.map(partner => (
                        <React.Fragment>
                            The Key partners involved in this analysis included &nbsp;
                            <Partner key={partner.id} name={partner.name} />
                        </React.Fragment>
                    ))} 
                    The analysis was { partners && partners.length ? 'then' : '' } vetted by the {countryName} IPC Techical Working Group in conjuction with a technical support team from IPC GSU and RSU.
                </div> 
                <div className="global-partners">
                    <h4 className="title-divider">Global Partners</h4>
                    <img className="img-responsive" src={GlobalPartners} alt="Global Partners" />
                </div>
            </div>
        );
    }
};

Footer.contextType = DataContext;

export default Footer;