import React from 'react';
import Pagination from 'react-js-pagination';
import DataContext from '../../../DataContext/DataContext';
import PopulationTrackingSheetFilters from './PopulationTrackingToolFilters/PopulationTrackingToolFilters';
import PopulationTrackingToolRow from './PopulationTrackingSheetRow/PopulationTrackingToolRow';
import './PopulationTrackingTool.scss';

const _ = require('underscore');

function generateHashString(string) {
  let hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  return hash;
}

class PopulationTrackingTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openGroups: {},
      allClosed: true,
    };
    this.onGroupClick = this.onGroupClick.bind(this);
    this.populationTableChanged = true;
  }

  componentDidUpdate() {
    if (this.populationTableChanged) {
      const { resizeIFrame } = this.context;
      // there are some changes with the population estimates, set the table size for resizing an iframe
      const contentContainerList = document.getElementsByClassName('population-estimates-table');
      let rHeight = 0;
      for (let i = 0; i < contentContainerList.length; i += 1) {
        const wHeight = Math.max(
          contentContainerList[i].clientHeight,
          contentContainerList[i].scrollHeight,
          contentContainerList[i].offsetHeight
        );
        if (wHeight > rHeight) {
          rHeight = wHeight;
        }
      }

      if (resizeIFrame instanceof Function) {
        setTimeout(() => {
          resizeIFrame(rHeight);
          this.populationTableChanged = false;
        }, 0);
      }
    }
  }

  onPageChange = pageNumber => {
    const { updatePagination, page } = this.context;
    if (pageNumber !== page) {
      updatePagination(pageNumber);
    }
  };

  onGroupClick(area, index) {
    const { openGroups } = this.state;

    if (area && index) {
      const _openGroups = openGroups;
      this.populationTableChanged = true;
      if (index === 'anl_id') {
        if (_openGroups.hasOwnProperty('analysis')) {
          if (_openGroups.analysis.includes(area.anl_id)) {
            _openGroups.analysis = _.without(_openGroups.analysis, area.anl_id);
          } else {
            _openGroups.analysis.push(area.anl_id);
          }
        } else {
          _openGroups.analysis = [];
          _openGroups.analysis.push(area.anl_id);
        }
      } else if (_openGroups.hasOwnProperty(area.anl_id)) {
        if (
          _openGroups[area.anl_id].hasOwnProperty('groups') &&
          _openGroups[area.anl_id].groups.includes(area.aag_id)
        ) {
          _openGroups[area.anl_id].groups = _.without(_openGroups[area.anl_id].groups, area.aag_id);
        } else {
          _openGroups[area.anl_id].groups.push(area.aag_id);
        }
      } else {
        _openGroups[area.anl_id] = { groups: [] };
        _openGroups[area.anl_id].groups.push(area.aag_id);
      }
      this.setState({ allClosed: false, openGroups: _openGroups });
    }
  }

  getRow(d, level, columnSpanDefinition) {
    const { anlType, period } = this.context;
    const { openGroups, allClosed } = this.state;
    columnSpanDefinition = columnSpanDefinition || false;

    const key = d.hasOwnProperty('id') && d.id ? d.id : Math.random().toString(36).substring(1);

    if (level !== 'level-0' && allClosed) return null;

    if (
      level !== 'level-0' &&
      !(openGroups.hasOwnProperty('analysis') && openGroups.analysis.includes(d.anl_id))
    )
      return null;

    return (
      <PopulationTrackingToolRow
        key={key}
        area={d}
        {...{
          level,
          onGroupClick: this.onGroupClick,
          analysisCondition: anlType,
          analysisPeriod: period,
          columnSpanDefinition,
          allClosed,
        }}
      />
    );
  }

  getRows() {
    const { population, populationGrandSubTotals, period, anlType } = this.context;
    if (
      populationGrandSubTotals == null ||
      !populationGrandSubTotals.hasOwnProperty('grandtotals') ||
      !populationGrandSubTotals.hasOwnProperty('grouptotals') ||
      population === null ||
      !population.length
    )
      return (
        <tr>
          <td
            colSpan={anlType === 'A' ? 51 : 17}
            className={
              anlType === 'C' ? 'black-thick-border-right' : 'light-grey-thick-border-right'
            }
          >
            <div className="text-left">
              No data exists to display. Try selecting a different country and/or year.
            </div>
          </td>
        </tr>
      );
    let filtered = population;
    if (period) {
      // filtered = filtered.filter(a => {
      //   if(a.period){ return a.period.toLowerCase() === period.toLowerCase() }
      //   return {};
      // });
      filtered = filtered.filter(a => a.period && a.period.toLowerCase() === period.toLowerCase());
    }
    const grandtotals = _.indexBy(populationGrandSubTotals.grandtotals, 'anl_id');
    const grouptotals = _.indexBy(populationGrandSubTotals.grouptotals, 'aag_id');
    const countryPopulation = _.groupBy(_.sortBy(filtered, 'country'), 'country');
    const rows = [];
    _.each(countryPopulation, (a, b) => {
      const distinctCountryAnalysis = _.groupBy(a, 'anl_id');
      const orderedDistinctCountryAnalysis = _.sortBy(
        Object.values(distinctCountryAnalysis),
        function (o) {
          return o[0].fanalysis_date;
        }
      ).reverse();
      _.each(orderedDistinctCountryAnalysis, (k, v) => {
        // grandtotals comes from the manually edited table.
        // we are loading the phase 3+ as phase 6
        // we are loading the estimated pop-figures as phase 7
        const analysisNameRow = grandtotals.hasOwnProperty(k[0].anl_id)
          ? Object.assign({}, grandtotals[k[0].anl_id])
          : {};
        analysisNameRow.area = `${b}: ${k[0].title}`;
        analysisNameRow.aag_id = k[0].aag_id;
        analysisNameRow.id = k[0].anl_id;
        analysisNameRow.estimated_population_current = Object.keys(analysisNameRow).length
          ? analysisNameRow.phase7_C_population
          : null;
        analysisNameRow.estimated_population_projected = Object.keys(analysisNameRow).length
          ? analysisNameRow.phase7_P_population
          : null;
        analysisNameRow.estimated_population_second_projected = Object.keys(analysisNameRow).length
          ? analysisNameRow.phase7_A_population
          : null;

        // console.log('analysisNameRow', analysisNameRow);
        rows.push(this.getRow(analysisNameRow, 'level-0'));

        const splitTables = _.groupBy(k, 'split_table');
        _.each(splitTables, (table, split) => {
          const _groups = _.groupBy(_.sortBy(table, 'group_name'), 'group_name');
          _.each(_groups, (c, d) => {
            // grouptotals comes from the manually edited table.
            // we are loading the phase 3+ as phase 6
            // we are loading the estimated pop-figures as phase 7
            const groupNameRow = grouptotals.hasOwnProperty(c[0].aag_id)
              ? Object.assign({}, grouptotals[c[0].aag_id])
              : {};
            if (d !== null && d !== 'null' && d !== '' && d !== undefined) {
              // damn logic for manually editted analysis
              // we are loading the estimated pop-figures as phase 7
              // damn code that kills the app.
              groupNameRow.country = b;
              groupNameRow.anl_id = c[0].anl_id;
              groupNameRow.view_level = c[0].view_level;
              groupNameRow.condition = c[0].condition;
              groupNameRow.group_name = d;
              groupNameRow.area = d;
              groupNameRow.id = generateHashString(`group${c[0].anl_id}${d}`);
              groupNameRow.census_population = c[0].census_population;
              groupNameRow.estimated_population_current = groupNameRow.phase7_C_population;
              groupNameRow.estimated_population_projected = groupNameRow.phase7_P_population;
              groupNameRow.estimated_population_second_projected = groupNameRow.phase7_A_population;
              rows.push(this.getRow(groupNameRow, 'level-2'));
            }

            if (
              !groupNameRow.hasOwnProperty('view_level') ||
              (groupNameRow.view_level && groupNameRow.view_level.toLowerCase().trim() !== 'group')
            ) {
              const _areas = _.sortBy(c, 'area');
              _areas.forEach((e, f) => {
                return rows.push(this.getRow(e, 'level-3'));
              });
            }
          });
        });
      });
    });
    return rows;
  }

  render() {
    const {
      page,
      perPage,
      pageRange,
      totalAnalysisCount,
      period,
      anlType,
      regions,
      activeCountries,
      countryCode,
      downloadCSV,
      updateYear,
      updatePeriod,
      updateCondition,
      updateSelectedCountry,
      updateSelectedRegion,
    } = this.context;
    const analysisCondition = anlType;
    const analysisConditionPrefix = analysisCondition === 'C' ? 'chronic-' : '';
    const analysisPhaseLabel = analysisCondition === 'C' ? 'Level' : 'Phase';
    return (
      <React.Fragment>
        <PopulationTrackingSheetFilters
          {...{
            regions,
            activeCountries,
            countryCode,
            updateCondition,
            updatePeriod,
            analysisCondition,
            analysisPeriod: period,
            downloadCSV,
            updateSelectedCountry,
            updateSelectedRegion,
            updateYear,
          }}
        />

        <div className="pts-population-estimates-table">
          <table className="pts-pop-table">
            <thead>
              <tr>
                <th
                  rowSpan="3"
                  className="pop-table__name-col analysis-name sticky-column first-column"
                >
                  Country/Analysis Name/Area Name
                </th>
                <th
                  rowSpan="3"
                  className="pop-table__area-phase-col analysis-date sticky-column second-column"
                >
                  Date of
                  <br />
                  Analysis
                </th>
                <th
                  rowSpan="3"
                  className="pop-table__area-phase-col pop-figures sticky-column third-column"
                >
                  Country <br />
                  Population
                </th>
                <th
                  colSpan={analysisCondition === 'A' ? 16 : 14}
                  className="pop-table__area-total-col current-projection black-thick-border-left black-thick-border-right"
                >
                  {analysisCondition === 'C' ? 'Population Figures' : 'Current'}
                </th>
                {analysisCondition === 'A' && (
                  <React.Fragment>
                    <th
                      colSpan="16"
                      className="pop-table__area-total-col first-projection grey-thick-border-right "
                    >
                      First Projection
                    </th>
                    <th
                      colSpan="16"
                      className="pop-table__area-total-col second-projection light-grey-thick-border-right "
                    >
                      Second Projection
                    </th>
                  </React.Fragment>
                )}
              </tr>
              <tr>
                <th colSpan="2" className="pop-table__area-total-col black-thick-border-left">
                  Population
                  <br />
                  Analysed
                </th>
                <th colSpan="1" className="pop-table__area-total-col area-phase">
                  {' '}
                  &nbsp;{' '}
                </th>
                <th colSpan="1" className="pop-table__area-total-col analysis-date">
                  {' '}
                  &nbsp;{' '}
                </th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-1-col`}>
                  {analysisPhaseLabel} 1
                </th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-2-col`}>
                  {analysisPhaseLabel} 2
                </th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-3-col`}>
                  {analysisPhaseLabel} 3
                </th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-4-col`}>
                  {analysisPhaseLabel} 4
                </th>
                {analysisCondition !== 'C' && (
                  <th colSpan="2" className={`${analysisConditionPrefix}phase-5-col`}>
                    {analysisPhaseLabel} 5
                  </th>
                )}
                <th colSpan="2" className="black-thick-border-right">
                  {analysisCondition === 'C' ? 'Level 2+' : 'Phase 3+'}
                </th>
                {analysisCondition === 'A' && (
                  <React.Fragment>
                    <th colSpan="2" className="pop-table__area-total-col pop-figures">
                      Population
                      <br />
                      Analysed
                    </th>
                    <th colSpan="1" className="pop-table__area-total-col area-phase">
                      {' '}
                      &nbsp;{' '}
                    </th>
                    <th colSpan="1" className="pop-table__area-total-col percentage">
                      {' '}
                      &nbsp;{' '}
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-1-col`}>
                      {analysisPhaseLabel} 1
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-2-col`}>
                      {analysisPhaseLabel} 2
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-3-col`}>
                      {analysisPhaseLabel} 3
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-4-col`}>
                      {analysisPhaseLabel} 4
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-5-col`}>
                      {analysisPhaseLabel} 5
                    </th>
                    <th colSpan="2" className="grey-thick-border-right">
                      Phase 3+
                    </th>
                    <th colSpan="2" className="pop-table__area-total-col">
                      Population
                      <br />
                      Analysed
                    </th>
                    <th colSpan="1" className="pop-table__area-total-col area-phase">
                      {' '}
                      &nbsp;{' '}
                    </th>
                    <th colSpan="1" className="pop-table__area-total-col">
                      {' '}
                      &nbsp;{' '}
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-1-col`}>
                      {analysisPhaseLabel} 1
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-2-col`}>
                      {analysisPhaseLabel} 2
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-3-col`}>
                      {analysisPhaseLabel} 3
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-4-col`}>
                      {analysisPhaseLabel} 4
                    </th>
                    <th colSpan="2" className={`${analysisConditionPrefix}phase-5-col`}>
                      {analysisPhaseLabel} 5
                    </th>
                    <th colSpan="2" className="light-grey-thick-border-right ">
                      Phase 3+
                    </th>
                  </React.Fragment>
                )}
              </tr>
              <tr>
                <th className="pop-table__area-total-col black-thick-border-left pop-figures">#</th>
                <th className="pop-table__area-total-col percentage-country-pop">
                  % of country pop
                </th>
                <th className="pop-table__area-total-col pop-figures">Area Phase</th>
                <th className="pop-table__area-total-col analysis-period">Analysis Period</th>
                <th className={`${analysisConditionPrefix}phase-1-col pop-figures`}>#</th>
                <th className={`${analysisConditionPrefix}phase-1-col percentage`}>%</th>
                <th className={`${analysisConditionPrefix}phase-2-col pop-figures`}>#</th>
                <th className={`${analysisConditionPrefix}phase-2-col percentage`}>%</th>
                <th className={`${analysisConditionPrefix}phase-3-col pop-figures`}>#</th>
                <th className={`${analysisConditionPrefix}phase-3-col percentage`}>%</th>
                <th className={`${analysisConditionPrefix}phase-4-col pop-figures`}>#</th>
                <th className={`${analysisConditionPrefix}phase-4-col percentage`}>%</th>
                {analysisCondition !== 'C' && (
                  <React.Fragment>
                    <th className={`${analysisConditionPrefix}phase-5-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-5-col percentage`}>%</th>
                  </React.Fragment>
                )}
                <th className="phase-plus-higher pop-figures">#</th>
                <th className="phase-plus-higher black-thick-border-right percentage">%</th>
                {analysisCondition === 'A' && (
                  <React.Fragment>
                    <th className="pop-table__area-total-col pop-figures">#</th>
                    <th className="pop-table__area-total-col percentage-country-pop">
                      % of country pop
                    </th>
                    <th className="pop-table__area-total-col pop-figures">Area Phase</th>
                    <th className="pop-table__area-total-col analysis-period">Analysis Period</th>
                    <th className={`${analysisConditionPrefix}phase-1-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-1-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-2-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-2-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-3-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-3-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-4-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-4-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-5-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-5-col percentage`}>%</th>
                    <th className="phase-plus-higher pop-figures">#</th>
                    <th className="phase-plus-higher grey-thick-border-right percentage">%</th>
                    <th className="pop-table__area-total-col pop-figures">#</th>
                    <th className="pop-table__area-total-col percentage-country-pop">
                      % of country pop
                    </th>
                    <th className="pop-table__area-total-col pop-figures">Area Phase</th>
                    <th className="pop-table__area-total-col analysis-period">Analysis Period</th>
                    <th className={`${analysisConditionPrefix}phase-1-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-1-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-2-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-2-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-3-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-3-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-4-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-4-col percentage`}>%</th>
                    <th className={`${analysisConditionPrefix}phase-5-col pop-figures`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-5-col percentage`}>%</th>
                    <th className="phase-plus-higher pop-figures">#</th>
                    <th className="phase-plus-higher light-grey-thick-border-right percentage">
                      %
                    </th>
                  </React.Fragment>
                )}
              </tr>
            </thead>
            <tbody>{this.getRows()}</tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={page}
            itemsCountPerPage={perPage}
            totalItemsCount={totalAnalysisCount}
            pageRangeDisplayed={pageRange}
            hideNavigation={true}
            onChange={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

PopulationTrackingTool.contextType = DataContext;

export default PopulationTrackingTool;
