/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  faChevronLeft,
  faChevronRight,
  faMap,
  faTable,
  faDrawPolygon,
  faPlus,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import _ from 'underscore';
import axios from 'axios';
import { json } from 'd3';
import * as topojson from 'topojson-client';
import bbox from '@turf/bbox';

import DataContext from '../../DataContext/DataContext';
import AtlasNew from './Atlas/AtlasNew.jsx';
import AtlasLegend from './Atlas/AtlasLegend/AtlasLegendNew.jsx';
import AtlasStyling from './Atlas/AtlasStyling/AtlasStyling.jsx';
import PhaseFilter from '../Header/PhaseFilter/PhaseFilter.jsx';
import PhaseDonutChart from './AcutePhase/AcutePhaseNew.jsx';
import PhaseIndexPlus from './PhaseIndexPlus/PhaseIndexPlusNew.jsx';
import './ContentBlock.scss';
import PopulationEstimates from './PopulationEstimates/PopulationEstimatesNew.jsx';
import exportMap from '../Export/ExportNew';
import DownloadButton from '../Export/DownloadButton/DownloadButton.jsx';

const DISCLAIMER =
  'Disclaimer: The information shown on this map does not imply that the IPC officially recognizes or endorses physical and political boundaries.';
const CHDISCLAIMER =
  'Disclaimer: The information shown on this map does not imply that the CH officially recognizes or endorses physical and political boundaries.';

const downloadAs = (url, name) => {
  axios
    .get(url, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then(response => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch(err => {
      console.log('error', err);
    });
};

class ContentBlockPublicAnalyis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePhases: [5, 4, 3, 2, 1, 0, 9, 6],
      activeYear: 2018,
      yearCurrent: true,
      legendActive: true,
      legendView: 'details',
      exportMode: false,
      styleActive: false,
      styleOverrides: {},
      basemapStyle: 'light.json',
      periods: [],
      groupId: null,
      isCHCountries: false,
      initialBounds: null,
      activeAnalysis: null,
      activeAnalysisPeriod: props.params.period || 'C',
      highlightedFeature: undefined,
      analysesPickList: null,
      selectedCountry: undefined,
      selectedGeom: null,
      highlightedPhases: null,
      view: props.params.view || 'split',
      view_level: 'area',
      primaryAnalysisCondition: props.params.condition || 'A',
      iframeHeight: 765,
    };
    this.updateLegendView = this.updateLegendView.bind(this);
    this.resizeIFrame = this.resizeIFrame.bind(this);
    this.downloadMapImgae = this.downloadMapImgae.bind(this);
    this.mapboxMaps = {};
  }

  addDataToGeo = (featureCollection, populationData, period) => {
    return {
      ...featureCollection,
      features: featureCollection.features.map((feature, id) => {
        const { properties } = feature;
        const { aar_id } = properties;
        const phaseData = {
          phase1_pop: 0,
          phase2_pop: 0,
          phase3_pop: 0,
          phase4_pop: 0,
          phase5_pop: 0,
          overall_phase: 0,
        };
        const popData = populationData.find(d => +d.aar_id === +aar_id);
        if (popData) {
          const phaseProperties = Object.entries(popData).filter(([key]) => key.match(/^phase\d+/));
          phaseProperties.forEach(([key, value]) => {
            phaseData[`${key}_pop`] = value.population;
            phaseData[`${key}_percentage`] = value.population_percentage;
          });
          phaseData.overall_phase = popData.overall_phase;
          phaseData.confidence_level = popData.confidence_level;
          phaseData.view_level = popData.view_level;
          phaseData.period = period;
        }
        const groupData = populationData.find(
          d => popData && +d.aag_id === +popData.aag_id && !d.hasOwnProperty('aar_id')
        );
        if (groupData) {
          phaseData.group = {
            title: groupData.title,
            period,
          };
          const groupPhase = Object.entries(groupData).filter(([key]) => key.match(/^phase\d+/));
          groupPhase.forEach(([key, value]) => {
            phaseData.group[`${key}_pop`] = value.population;
            phaseData.group[`${key}_percentage`] = value.population_percentage;
          });
        }
        // extend feature properties with phase data
        return {
          ...feature,
          id,
          properties: {
            ...properties,
            ...phaseData,
          },
        };
      }),
    };
  };

  componentDidMount() {
    const { analysisId } = this.props.params;
    const { activeAnalysisPeriod } = this.state;
    Promise.all([
      json(`${process.env.REACT_APP_S3_API_URL}/api/gsu/active/analysis/${analysisId}`),
    ]).then(([analysisData]) => {
      const allPeriods = analysisData ? analysisData.periods : ['C', 'P', 'A'];
      const period = analysisData ? analysisData.active_period : activeAnalysisPeriod;
      const remainingPeriods = allPeriods.filter(p => p !== period);
      const { periods, view_level, show_area_popups } = analysisData;

      this.setState({ activeAnalysisPeriod: period, view_level, show_area_popups });

      Promise.all([
        json(`${process.env.REACT_APP_S3_API_URL}/api/gsu/areas/${analysisId}`),
        json(
          `${process.env.REACT_APP_S3_API_URL}/api/gsu/population/${analysisId}?period=${period}`
        ),
        json(`${process.env.REACT_APP_S3_API_URL}/api/gsu/combined/${analysisId}`),
      ]).then(([areasData, populationData, combinedData]) => {
        const countryName = analysisData.full_country_name;
        const allAreasGeo = topojson.feature(areasData, areasData.objects.output);
        const geojson = this.addDataToGeo(allAreasGeo, populationData, period);
        const initialBoundsCoords = bbox(geojson);
        const initialBounds = [
          [initialBoundsCoords[2], initialBoundsCoords[3]],
          [initialBoundsCoords[0], initialBoundsCoords[1]],
        ];
        const analysisName = analysisData.gsu_analysis_title || analysisData.analysis_name;
        const countryCode = analysisData.country;
        const primaryAnalysisCondition =
          analysisData && analysisData.condition ? analysisData.condition : null;

        let selectedCountry;
        // makes an object with phase populations and title for the country level
        if (populationData.find(d => d.aag_id === 0)) {
          const countryData = populationData.find(d => d.aag_id === 0);
          selectedCountry = {
            title: countryName,
            ...Object.entries(countryData)
              .filter(([key]) => key.match(/^phase\d+/))
              .reduce((phaseData, [key, value]) => {
                return {
                  ...phaseData,
                  [`${key}_pop`]: value.population,
                  [`${key}_percentage`]: value.population_percentage,
                };
              }, {}),
          };
        }

        const currPopulation =
          period === 'A'
            ? 'secondProjectedPopulation'
            : period === 'P'
            ? 'projectedPopulation'
            : 'population';

        const currGeojson =
          period === 'A'
            ? 'secondProjectedGeojson'
            : period === 'P'
            ? 'projectedGeojson'
            : 'geojson';

        this.setState({
          activeAnalysis: analysisData,
          selectedCountry,
          selectedGeom: combinedData,
          analysisName,
          view_level,
          show_area_popups,
          initialBounds,
          [currGeojson]: geojson,
          [currPopulation]: populationData.map(p => Object.assign(p, { countryName })),
          periods,
          noData: false,
          // the following exist within other objects and may not be needed here?
          primaryAnalysisCondition,
          activeAnalysisPeriod: period,
          countryName,
          countryCode,
          selectedCountryName: countryName,
        });

        Promise.all([
          json(`${process.env.REACT_APP_S3_API_URL}/api/gsu/icons/${analysisId}?period=${period}`),
        ]).then(([iconData]) => {
          const currIcons =
            period === 'A' ? 'secondProjectedIcons' : period === 'P' ? 'projectedIcons' : 'icons';
          this.setState({
            // allIcons: iconData,
            [currIcons]: iconData,
            primaryAnalysisCondition,
            countryName,
            countryCode,
            selectedCountryName: countryName,
            loading: null,
          });
        });

        if (remainingPeriods && remainingPeriods.length && remainingPeriods[0]) {
          Promise.all([
            json(
              `${process.env.REACT_APP_S3_API_URL}/api/gsu/population/${analysisId}?period=${remainingPeriods[0]}`
            ),
            json(
              `${process.env.REACT_APP_S3_API_URL}/api/gsu/icons/${analysisId}?period=${remainingPeriods[0]}`
            ),
          ]).then(([additionalPopulationData, projectedIconData]) => {
            const remPopulation =
              remainingPeriods[0] === 'A'
                ? 'secondProjectedPopulation'
                : remainingPeriods[0] === 'P'
                ? 'projectedPopulation'
                : 'population';
            const remIcons =
              remainingPeriods[0] === 'A'
                ? 'secondProjectedIcons'
                : remainingPeriods[0] === 'P'
                ? 'projectedIcons'
                : 'icons';
            const remGeojson =
              remainingPeriods[0] === 'A'
                ? 'secondProjectedGeojson'
                : remainingPeriods[0] === 'P'
                ? 'projectedGeojson'
                : 'geojson';
            const projectedGeojson = this.addDataToGeo(
              allAreasGeo,
              additionalPopulationData,
              remainingPeriods[0]
            );
            this.setState({
              [remIcons]: projectedIconData,
              [remGeojson]: projectedGeojson,
              [remPopulation]: additionalPopulationData.map(p => Object.assign(p, { countryName })),
              loading: null,
            });
          });
        }

        if (remainingPeriods && remainingPeriods.length && remainingPeriods[1]) {
          Promise.all([
            json(
              `${process.env.REACT_APP_S3_API_URL}/api/gsu/population/${analysisId}?period=${remainingPeriods[1]}`
            ),
            json(
              `${process.env.REACT_APP_S3_API_URL}/api/gsu/icons/${analysisId}?period=${remainingPeriods[1]}`
            ),
          ]).then(([secondAdditionalPopulationData, secondProjectedIconData]) => {
            const rem2Population =
              remainingPeriods[1] === 'A'
                ? 'secondProjectedPopulation'
                : remainingPeriods[1] === 'P'
                ? 'projectedPopulation'
                : 'population';
            const rem2Icons =
              remainingPeriods[1] === 'A'
                ? 'secondProjectedIcons'
                : remainingPeriods[1] === 'P'
                ? 'projectedIcons'
                : 'icons';
            const rem2Geojson =
              remainingPeriods[1] === 'A'
                ? 'secondProjectedGeojson'
                : remainingPeriods[1] === 'P'
                ? 'projectedGeojson'
                : 'geojson';
            const secondProjectedGeojson = this.addDataToGeo(
              allAreasGeo,
              secondAdditionalPopulationData,
              remainingPeriods[1]
            );
            this.setState({
              [rem2Icons]: secondProjectedIconData,
              [rem2Geojson]: secondProjectedGeojson,
              [rem2Population]: secondAdditionalPopulationData.map(p =>
                Object.assign(p, { countryName })
              ),
            });
          });
        }

        // Promise.all([json(`/api/gsu/picklist/${analysisId}`)]).then(([otherAnalysesPickList]) => {
        //   this.setState({ analysesPickList: otherAnalysesPickList });
        // });
      });
    });
  }

  renderLegendTooltip = () => {
    return (
      <Tooltip id="button-legend-tooltip">
        {`${this.state.legendActive ? 'Hide Map Legend' : 'Show Map Legend'}`}
      </Tooltip>
    );
  };

  toggleStylePanel = () => {
    this.setState({ styleActive: !this.state.styleActive });
  };

  updateStyle = (key, value) => {
    const newStyle = Array.isArray(value) ? value : [value];
    const styleOverrides = { ...this.state.styleOverrides };
    styleOverrides[key] = newStyle;
    this.setState({ styleOverrides });
  };

  basemapSelect = e => {
    const style = e.target.getAttribute('value');
    if (style) {
      this.setState({ basemapStyle: style });
    }
  };

  getSingleView() {
    const {
      activeAnalysis,
      legendActive,
      legendView,
      exportMode,
      activePhases,
      yearCurrent,
      activeYear,
      highlightedPhases,
      basemapStyle,
      styleActive,
      geojson,
      projectedGeojson,
      secondProjectedGeojson,
      population,
      selectedGeom,
      projectedPopulation,
      secondProjectedPopulation,
      icons,
      groupId,
      projectedIcons,
      secondProjectedIcons,
      activeAnalysisPeriod,
      primaryAnalysisCondition,
      highlightedFeature,
      // analysesPickList,
      isCHCountries,
      initialBounds,
      iframeHeight,
      show_area_popups,
    } = this.state;
    const { analysisId, mapOnly, includeTable } = this.props.params;
    const toggle = () => {
      this.setState({ legendActive: !this.state.legendActive });
    };
    const filterPhases = (phases, active) => {
      let newPhases = [...activePhases];
      if (active) newPhases = [...newPhases, ...phases];
      else newPhases = _.without(newPhases, ...phases);
      this.setState({ activePhases: newPhases });
    };

    const addMapRef = ({ type, position, map }) => {
      this.mapboxMaps = {
        ...this.mapboxMaps,
        [`${type}-${position || ''}`]: { type, position, map },
      };
    };

    const deleteMapRef = ({ type, position }) => {
      delete this.mapboxMaps[`${type}-${position || ''}`];
    };

    const renderCompareTooltip = () => {
      return (
        <Tooltip id="button-compare-tooltip">
          Click to enable comparison of multiple periods
        </Tooltip>
      );
    };

    const renderPeriodsTooltip = () => {
      return <Tooltip id="button-period-tooltip">Click to view the next period</Tooltip>;
    };

    const setHighlightedFeature = feature => {
      this.setState({ highlightedFeature: feature });
    };

    const setSelectedCountry = feature => {
      this.setState({ selectedCountry: feature });
    };

    // get data and period-switching button for the currently active period
    let geojsonToUse;
    let iconsToUse;
    let populationToUse;
    let nextPopulationToUse;
    let nextPeriod;
    let periodButtonText;
    let periodButton;

    if (
      activeAnalysisPeriod === 'A' &&
      secondProjectedGeojson &&
      secondProjectedPopulation.length
    ) {
      // if active period is 2nd projected, and its data exists
      geojsonToUse = secondProjectedGeojson;
      iconsToUse = secondProjectedIcons;
      populationToUse = secondProjectedPopulation;
      nextPeriod = 'C';
      nextPopulationToUse = population;
      periodButtonText = 'Current';
    } else if (activeAnalysisPeriod === 'P' && projectedGeojson && projectedPopulation.length) {
      // first projected, and its data exists
      geojsonToUse = projectedGeojson;
      iconsToUse = projectedIcons;
      populationToUse = projectedPopulation;
      // button content depends on whether there's a second projection
      if (secondProjectedGeojson && secondProjectedPopulation.length) {
        nextPeriod = 'A';
        nextPopulationToUse = secondProjectedPopulation;
        periodButtonText = '2nd Projected';
      } else {
        nextPeriod = 'C';
        nextPopulationToUse = population;
        periodButtonText = 'Current';
      }
    } else {
      // current, or the data conditions above failed
      geojsonToUse = geojson;
      iconsToUse = icons;
      populationToUse = population;
      if (projectedGeojson && projectedPopulation.length) {
        nextPeriod = 'P';
        nextPopulationToUse = projectedPopulation;
        periodButtonText = 'Projected';
      }
    }

    // if there's a projected period to go to, show the button for it
    if (nextPeriod) {
      periodButton = (
        <OverlayTrigger overlay={renderPeriodsTooltip} placement="bottom">
          <Button
            variant="outline-secondary"
            onClick={() => {
              this.setState({ activeAnalysisPeriod: nextPeriod });
              if (nextPopulationToUse) {
                const countryPopToUse = nextPopulationToUse.find(p => p.aag_id === 0);
                if (countryPopToUse) {
                  this.setState({
                    selectedCountry: {
                      ...this.state.selectedCountry,
                      phase1_pop: countryPopToUse.phase1.population,
                      phase2_pop: countryPopToUse.phase2.population,
                      phase3_pop: countryPopToUse.phase3.population,
                      phase4_pop: countryPopToUse.phase4.population,
                      phase5_pop: countryPopToUse.phase5.population,
                      phase6_pop: countryPopToUse.phase6.population,
                      phase7_pop: countryPopToUse.phase7.population,
                    },
                  });
                }
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
            {`View ${periodButtonText}`}
          </Button>
        </OverlayTrigger>
      );
    }

    const from_thru_dates = `${this.state.current_from_date} - ${this.state.current_thru_date}`;
    const dateRange =
      activeAnalysisPeriod === 'A'
        ? 'second_projected_date_range'
        : activeAnalysisPeriod === 'P'
        ? 'projected_date_range'
        : 'current_date_range';
    const dateTextPrefix = `${
      activeAnalysisPeriod === 'A'
        ? '2nd Projected'
        : activeAnalysisPeriod === 'P'
        ? 'Projected'
        : 'Current'
    }`;
    const dateText = `${
      this.state.activeAnalysis && this.state.activeAnalysis[dateRange]
        ? this.state.activeAnalysis[dateRange]
        : from_thru_dates
    }`;
    return (
      <React.Fragment>
        <Container fluid>
          <Row noGutters={true}>
            <Col className="col-12 col-md-4 col">
              <PhaseFilter analysisCondition={primaryAnalysisCondition} handler={filterPhases} />
            </Col>
            <Col className="col-12 col-md-3 text-md-left col"></Col>
            {activeAnalysis &&
              activeAnalysis.periods.length > 1 &&
              (nextPeriod || groupId !== null) && (
                <Col className="col-12 col-md-3 periodButtons text-md-right col">
                  <OverlayTrigger overlay={renderCompareTooltip} placement="right">
                    <ButtonGroup aria-label="Toggle and compare Periods">
                      {periodButton}
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          this.setState({ view: 'split', styleActive: false });
                        }}
                        className="d-none d-md-block"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {groupId ? `Compare Current and Projected` : `Compare`}
                      </Button>
                    </ButtonGroup>
                  </OverlayTrigger>
                </Col>
              )}
          </Row>
          <Row noGutters={true} className="mainContent singleAnalysis">
            <AtlasStyling
              styleActive={styleActive}
              handler={this.updateStyle}
              toggle={this.toggleStylePanel}
              basemapSelect={this.basemapSelect}
              selectedGeom={selectedGeom}
              group={+groupId}
            />
            <Col
              xl={styleActive ? 7 : 10}
              md={styleActive ? 6 : 9}
              sm={12}
              className="atlas-container-wrapper"
            >
              {groupId && (
                <div className="content__col-header__date">
                  <span>
                    {' '}
                    {dateTextPrefix}: &nbsp; <span className="blue">{dateText}</span>
                  </span>
                </div>
              )}
              <div className="atlas-container">
                <AtlasNew
                  show_area_popups={show_area_popups}
                  toggleStyle={this.toggleStylePanel}
                  styleOverrides={this.state.styleOverrides}
                  activePhases={activePhases}
                  activeCondition={primaryAnalysisCondition}
                  activePeriod={activeAnalysisPeriod}
                  current={yearCurrent}
                  year={activeYear}
                  saveMapRef={addMapRef}
                  deleteMapRef={deleteMapRef}
                  setHighlightedFeature={setHighlightedFeature}
                  setSelectedCountry={setSelectedCountry}
                  highlightedPhases={highlightedPhases}
                  basemapStyle={basemapStyle}
                  geojson={geojsonToUse}
                  analysisId={analysisId}
                  group={groupId}
                  icons={iconsToUse}
                  highlightedFeature={highlightedFeature}
                  period={this.state.period}
                  initialBounds={initialBounds}
                  disclaimerText={isCHCountries ? CHDISCLAIMER : DISCLAIMER}
                />
              </div>
              {!!(
                includeTable &&
                activeAnalysis.show_ptt &&
                !mapOnly &&
                Boolean(populationToUse) === true
              ) && (
                <React.Fragment>
                  <PopulationEstimates
                    population={populationToUse}
                    analysisCondition={primaryAnalysisCondition}
                    analysisPeriod="C"
                    resizeIFrame={this.resizeIFrame}
                  />
                </React.Fragment>
              )}
            </Col>
            {!mapOnly && legendActive && (
              <Col xl={2} md={3} sm={12}>
                <AtlasLegend
                  legendView={legendView}
                  updateLegendView={this.updateLegendView}
                  active={legendActive}
                  toggle={toggle}
                  position={exportMode ? 'bottom' : 'right'}
                  publicViewDetails={this.getDetailsPanelNew()}
                  publicMap
                  isCHCountries={isCHCountries}
                  analysisCondition={primaryAnalysisCondition}
                  togglable={false}
                />
                {groupId && this.getDownloadButton()}
              </Col>
            )}
          </Row>
        </Container>
        {!window.location.href.match('http://localhost') && (
          <iframe
            title="cross_title_property"
            height={iframeHeight}
            style={{ display: 'none' }}
            src={`https://www.ipcinfo.org/common/empty/?iframe=true&height=${iframeHeight}`}
          />
        )}
      </React.Fragment>
    );
  }

  getSplitView() {
    const {
      activeAnalysis,
      legendActive,
      legendView,
      styleOverrides,
      activePhases,
      yearCurrent,
      activeYear,
      highlightedPhases,
      basemapStyle,
      geojson,
      projectedGeojson,
      secondProjectedGeojson,
      population,
      projectedPopulation,
      secondProjectedPopulation,
      icons,
      periods,
      groupId,
      projectedIcons,
      secondProjectedIcons,
      primaryAnalysisCondition,
      highlightedFeature,
      isCHCountries,
      iframeHeight,
      styleActive,
      selectedGeom,
      initialBounds,
      show_area_popups,
    } = this.state;

    const { analysisId, mapOnly, includeTable } = this.props.params;

    const extent = [geojson, projectedGeojson, secondProjectedGeojson].reduce(
      (ex, geo) => {
        if (geo) {
          const bb = bbox(geo);
          return [
            [
              ex[0][0] ? Math.min(bb[0], ex[0][0]) : bb[0],
              ex[0][1] ? Math.min(bb[1], ex[0][1]) : bb[1],
            ],
            [
              ex[1][0] ? Math.max(bb[2], ex[1][0]) : bb[2],
              ex[1][1] ? Math.max(bb[1], ex[1][1]) : bb[3],
            ],
          ];
        }
        return ex;
      },
      [[], []]
    );

    const toggle = () => {
      this.setState({ legendActive: !this.state.legendActive, styleActive: false });
    };

    const toggleStylePanel = () => {
      this.setState({ styleActive: !this.state.styleActive });
    };

    const filterPhases = (phases, active) => {
      let newPhases = [...activePhases];
      if (active) newPhases = [...newPhases, ...phases];
      else newPhases = _.without(newPhases, ...phases);
      this.setState({ activePhases: newPhases });
    };

    const addMapRef = ({ type, position, map }) => {
      this.mapboxMaps = {
        ...this.mapboxMaps,
        [`${type}-${position || ''}`]: { type, position, map },
      };
    };

    const deleteMapRef = ({ type, position }) => {
      delete this.mapboxMaps[`${type}-${position || ''}`];
    };

    // const renderCompareTooltip = props => {
    //   return (
    //     <Tooltip id="button-tooltip" {...props}>
    //       Click to disable comparison of multiple periods
    //     </Tooltip>
    //   );
    // };

    const setHighlightedFeature = feature => {
      this.setState({ highlightedFeature: feature });
    };

    const setSelectedCountry = feature => {
      this.setState({ selectedCountry: feature });
    };

    // const mapOnly = analysisId !== null && !includeTable;
    const analysisCondition =
      activeAnalysis && activeAnalysis.condition
        ? activeAnalysis.condition
        : primaryAnalysisCondition;

    let w = 0;
    if (population && population.length) w += 1;
    if (projectedPopulation && projectedPopulation.length) w += 1;
    if (secondProjectedPopulation && secondProjectedPopulation.length) w += 1;
    if (this.state.isCHCountries) w = 2;
    const maxWidth = `${Math.floor(100 / w)}%`;

    return (
      <React.Fragment>
        <Container fluid>
          <Row noGutters={true}>
            <Col className="col-12 col-md-4">
              <PhaseFilter handler={filterPhases} />
            </Col>
            <Col className="col-12 col-md-3 text-md-left"></Col>
            <Col className="col-12 col-md-3 periodButtons text-md-right">
              {/* <OverlayTrigger overlay={renderCompareTooltip} placement="right"> */}
              <Button
                variant="outline-secondary"
                onClick={() => {
                  this.setState({ view: 'single', styleActive: false, legendActive: true });
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
                Hide Compare Periods
              </Button>
              {/* </OverlayTrigger> */}
            </Col>
          </Row>

          <Row noGutters={true} className="mainContent singleAnalysis split align-items-start">
            <AtlasStyling
              styleActive={styleActive}
              handler={this.updateStyle}
              toggle={() => {
                this.setState({
                  legendActive: styleActive,
                });
                this.toggleStylePanel();
              }}
              basemapSelect={this.basemapSelect}
              selectedGeom={selectedGeom}
              group={+groupId}
            />
            <Col
              className={`col-12 ${
                legendActive || styleActive ? 'col-xl-10 col-md-9' : 'col-xl-12 col-md-12'
              } atlas-container`}
            >
              <Row noGutters={true}>
                {periods.includes('C') && (
                  <Col className={`col col-sm atlas-container-wrapper`} style={{ maxWidth }}>
                    <div className="content__col-header__date">
                      <span>
                        Current: &nbsp;
                        <span className="blue">{activeAnalysis.current_date_range}</span>
                      </span>
                    </div>
                    <div className="atlas-container inner-padding-none">
                      <AtlasNew
                        key="split-current"
                        show_area_popups={show_area_popups}
                        toggleStyle={toggleStylePanel}
                        styleOverrides={styleOverrides}
                        activePhases={activePhases}
                        activeCondition={analysisCondition}
                        activePeriod="C"
                        current={yearCurrent}
                        year={activeYear}
                        saveMapRef={addMapRef}
                        deleteMapRef={deleteMapRef}
                        setHighlightedFeature={setHighlightedFeature}
                        setSelectedCountry={setSelectedCountry}
                        highlightedPhases={highlightedPhases}
                        basemapStyle={basemapStyle}
                        geojson={geojson}
                        analysisId={analysisId}
                        group={groupId}
                        icons={icons}
                        insets={false}
                        highlightedFeature={highlightedFeature}
                        compareBounds={extent}
                        initialBounds={initialBounds}
                        disclaimerText={isCHCountries ? CHDISCLAIMER : DISCLAIMER}
                      />
                    </div>
                    {!!(
                      includeTable &&
                      activeAnalysis.show_ptt &&
                      !mapOnly &&
                      Boolean(population) !== false
                    ) && (
                      <PopulationEstimates
                        population={population}
                        analysisCondition={analysisCondition}
                        analysisPeriod="C"
                        resizeIFrame={this.resizeIFrame}
                      />
                    )}
                  </Col>
                )}

                {periods.includes('P') && (
                  <Col className={`col col-sm atlas-container-wrapper`} style={{ maxWidth }}>
                    <div className="content__col-header__date">
                      <span>
                        Projected: &nbsp;
                        <span className="blue">{activeAnalysis.projected_date_range}</span>
                      </span>
                    </div>
                    <div className="atlas-container inner-padding-sm">
                      <AtlasNew
                        key="split-projected"
                        show_area_popups={show_area_popups}
                        styleOverrides={styleOverrides}
                        activePhases={activePhases}
                        activeCondition={analysisCondition}
                        activePeriod="P"
                        current={yearCurrent}
                        year={activeYear}
                        saveMapRef={addMapRef}
                        deleteMapRef={deleteMapRef}
                        setHighlightedFeature={setHighlightedFeature}
                        setSelectedCountry={setSelectedCountry}
                        highlightedPhases={highlightedPhases}
                        basemapStyle={basemapStyle}
                        geojson={projectedGeojson}
                        analysisId={analysisId}
                        group={groupId}
                        icons={projectedIcons}
                        insets={false}
                        highlightedFeature={highlightedFeature}
                        compareBounds={extent}
                        initialBounds={initialBounds}
                        disclaimerText={isCHCountries ? CHDISCLAIMER : DISCLAIMER}
                      />
                    </div>
                    {!!(
                      includeTable &&
                      activeAnalysis.show_ptt &&
                      Boolean(projectedPopulation) !== false &&
                      !mapOnly
                    ) && (
                      <PopulationEstimates
                        population={projectedPopulation}
                        analysisCondition={analysisCondition}
                        analysisPeriod="P"
                        resizeIFrame={this.resizeIFrame}
                      />
                    )}
                  </Col>
                )}

                {periods.includes('A') && (
                  <Col className={`col col-sm atlas-container-wrapper`} style={{ maxWidth }}>
                    <div className="content__col-header__date">
                      <span>
                        2nd Projected: &nbsp;
                        <span className="blue">{activeAnalysis.second_projected_date_range}</span>
                      </span>
                    </div>
                    <div className="atlas-container inner-padding-none">
                      <AtlasNew
                        key="split-second-projected"
                        show_area_popups={show_area_popups}
                        styleOverrides={styleOverrides}
                        activePhases={activePhases}
                        current={yearCurrent}
                        activeCondition={analysisCondition}
                        activePeriod="A"
                        year={activeYear}
                        saveMapRef={addMapRef}
                        deleteMapRef={deleteMapRef}
                        setHighlightedFeature={setHighlightedFeature}
                        setSelectedCountry={setSelectedCountry}
                        highlightedPhases={highlightedPhases}
                        basemapStyle={basemapStyle}
                        geojson={secondProjectedGeojson}
                        analysisId={analysisId}
                        group={groupId}
                        icons={secondProjectedIcons}
                        insets={false}
                        highlightedFeature={highlightedFeature}
                        compareBounds={extent}
                        initialBounds={initialBounds}
                        disclaimerText={isCHCountries ? CHDISCLAIMER : DISCLAIMER}
                      />
                    </div>
                    {!!(
                      includeTable &&
                      Boolean(secondProjectedPopulation) !== false &&
                      activeAnalysis.show_ptt &&
                      !mapOnly
                    ) && (
                      <PopulationEstimates
                        population={secondProjectedPopulation}
                        analysisCondition={analysisCondition}
                        analysisPeriod="A"
                        resizeIFrame={this.resizeIFrame}
                      />
                    )}
                  </Col>
                )}
              </Row>
            </Col>

            {!mapOnly && legendActive && (
              <Col className="col-12 col-xl-2 col-md-3">
                <AtlasLegend
                  legendView={legendView}
                  updateLegendView={this.updateLegendView}
                  active={legendActive}
                  toggle={toggle}
                  publicViewDetails={this.getDetailsPanelNew()}
                  publicMap
                  isCHCountries={isCHCountries}
                  analysisCondition={analysisCondition}
                />
                {groupId && this.getDownloadButton()}
              </Col>
            )}

            {!legendActive && (
              <div
                className="content__col-header__legend-toggle"
                onClick={() => {
                  toggle();
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            )}
          </Row>
        </Container>
        <iframe
          title="cross_title_property"
          height={iframeHeight}
          style={{ display: 'none' }}
          src={`https://www.ipcinfo.org/common/empty/?iframe=true&height=${iframeHeight}`}
        />
      </React.Fragment>
    );
  }

  getDetailsPanelNew() {
    /*
      Example COUNTRY properties available:

      active_period: "P"
      analysis_name: "Acute Food Insecurity October 2019"
      anl_id: "14063811"
      condition: "A"
      country: "NA"
      current_from_date: "2020-03-31T23:00:00.000Z"
      current_thru_date: "2020-09-29T23:00:00.000Z"
      link: "http://www.ipcinfo.org/ipc-country-analysis/details-map/en/c/1152360/"
      mask: false
      notes: "Between October 2019 and March 2020, an estimated 430,000 people are facing severe acute food insecurity (IPC Phase 3+) and require urgent humanitarian action. The most affected regions are Hardap, Kavango West, Khomas, Kunene, Ohangwena, Omaheke, Omusati, and Zambezi, which are classified as IPC Phase 3 (Crisis). Crisis conditions are mainly due to the poor rainfall in the previous planting season which led to a significant decline in cereal production in 2019. Water shortages also contributed to significant livestock deaths in the northwestern and southern regions. In the projection period between April and September 2020, an estimated 360,000 people are expected to experience severe acute food insecurity.  The most affected regions are Hardap, Khomas, Kunene, Omusati and Zambezi, which are classified as IPC Phase 3 (Crisis). A slight improvement of the situation is expected in Kavango West, Ohangwena and Omusati that are attributable to the improved rains, and favourable cereal prospects in 2020. "
      period: "["C","P"]"
      phase1_pop: 1217595
      phase2_pop: 841599
      phase3_pop: 354431
      phase4_pop: 0
      phase5_pop: 0
      pop_graphics: true
      title: "Namibia"
      year: 2020
    */

    /*
      Example AREA properties available:

      aar_id: 13588181
      anl_id: "13588146"
      condition: "A"
      confidence_level: 3
      country: "MZ"
      created_by: "buene"
      current: true
      id: 1824
      ipc_period: "P"
      overall_phase: 3
      phase1_per: 0.3
      phase1_pop: 13301
      phase2_per: 0.25
      phase2_pop: 11084
      phase3_per: 0.35
      phase3_pop: 15518
      phase4_per: 0.1
      phase4_pop: 4434
      phase5_per: 0
      phase5_pop: 0
      population: 44336
      population_min: "19952"
      population_percentage_min: "0.4500"
      population_percentage_phase_worse: "0.4500"
      population_phase_worse: "19952"
      prolonged_crisis: true
      title: "Funhalouro"
      w5s_id: "13588484"
      year: 2019
    */
    const {
      highlightedFeature,
      selectedCountry,
      activeAnalysisPeriod,
      primaryAnalysisCondition,
      groupId,
      view_level,
    } = this.state;

    const { analysisId, mapOnly, includeTable } = this.props.params;

    if (groupId) return null;

    const cfeatureToUse = highlightedFeature || selectedCountry;
    const featureToUse =
      highlightedFeature &&
      view_level &&
      view_level.toLowerCase() === 'group' &&
      cfeatureToUse.group
        ? JSON.parse(cfeatureToUse.group)
        : cfeatureToUse;
    if (
      featureToUse &&
      (highlightedFeature ? ![0, 9].includes(highlightedFeature.overall_phase) : true)
    ) {
      const {
        phase1_pop,
        phase2_pop,
        phase3_pop,
        phase4_pop,
        phase5_pop,
        phase6_pop,
        phase1_percentage,
        phase2_percentage,
        phase3_percentage,
        phase4_percentage,
        phase5_percentage,
        phase6_percentage,
        overall_phase,
      } = featureToUse;

      const period = highlightedFeature ? featureToUse.period : activeAnalysisPeriod;

      const { analysis_date } = this.state.activeAnalysis;
      let population = [phase1_pop, phase2_pop, phase3_pop, phase4_pop, phase5_pop];
      let percentages = [
        phase1_percentage,
        phase2_percentage,
        phase3_percentage,
        phase4_percentage,
        phase5_percentage,
      ];
      if (primaryAnalysisCondition === 'C') {
        population = population.slice(0, 4);
        percentages = percentages.slice(0, 4);
      }
      const p3plusPop =
        phase6_pop === undefined ? population.slice(2).reduce((a, b) => a + b, 0) : phase6_pop;
      const p3plusPercentage =
        phase6_percentage === undefined
          ? percentages.slice(2).reduce((a, b) => a + b, 0)
          : phase6_percentage;

      const setHighlightedPhases = phases => {
        this.setState({ highlightedPhases: phases });
      };

      const downloadData = (type = 'xlsx') => {
        const { country, anl_id, condition, analysis_name, full_country_name, active_period, periods } =
          this.state.activeAnalysis;
        const { activeAnalysisPeriod, view } = this.state;
        const countryUrl = country ? `&country=${country}` : null;
        const conditionUrl = condition ? `&condition=${condition}` : null;

        let url = `${process.env.REACT_APP_S3_API_URL}/api/export/${type}/${anl_id}?export=true`;
        // if split download all periods
        // seems all views are coming in as split, we look for a single view and apply the period parameter
        // this will enable for projection only analysis to download the correct geojson file
        // TODO: this needs to be fixed so that the correct view status is rendered.
        if (view !== 'split' || periods.length <= 1 ) {
          const periodUrl = active_period ? `&period=${active_period}` : null;
          url += periodUrl;
        }
        if (countryUrl) url += countryUrl;
        if (conditionUrl) url += conditionUrl;
        if (type === 'xlsx') {
          // console.log('url', url);
          window.open(url);
        } else {
          downloadAs(
            url,
            `${full_country_name}-${analysis_name}${
              view !== 'split' ? `-${activeAnalysisPeriod}` : ''
            }`
          );
        }
      };

      const downloadMap = () => {
        exportMap(this.mapboxMaps, 8, this.state.activeAnalysis, '', period); // <- inches of width (@300 dpi), defaults to 12
      };

      // excel exports are now required only to be post 2017
      // ofcourse the stupid date format always come to bite;
      // const d = primaryAnalysisCondition === 'C' ? analysis_date :  current_from_date;
      const d = analysis_date;
      const analysisCreateYear = d ? d.substring(d.length, d.length - 4) : 0;
      const iscreatedAfter2016 = analysisCreateYear >= 2017;

      const from_thru_dates = `${this.state.current_from_date} - ${this.state.current_thru_date}`;
      const dateRange =
        period === 'A'
          ? 'second_projected_date_range'
          : period === 'P'
          ? 'projected_date_range'
          : 'current_date_range';
      const dateText =
        primaryAnalysisCondition === 'C'
          ? `Created: ${
              this.state.activeAnalysis.analysis_date
                ? this.state.activeAnalysis.analysis_date
                : this.state.activeAnalysis.current_from_date
            }`
          : `${period === 'A' ? '2nd Projected' : period === 'P' ? 'Projected' : 'Current'}: ${
              this.state.activeAnalysis[dateRange]
                ? this.state.activeAnalysis[dateRange]
                : from_thru_dates
            }`;

      return (
        <div className={`atlas-legend__details`}>
          <div>
            <span className="blue">{dateText}</span>
          </div>
          <React.Fragment>
            {/* PhaseDonutChart is an alias for the AcutePhase component */}

            {!!(
              population &&
              population.length &&
              this.state.activeAnalysis.show_ptt &&
              !mapOnly
            ) && (
              <React.Fragment>
                <PhaseDonutChart
                  population={population}
                  percentages={percentages}
                  overallPhase={overall_phase}
                  className={primaryAnalysisCondition === 'C' ? 'chronic' : 'acute'}
                  setHighlightedPhases={setHighlightedPhases}
                  analysisCondition={primaryAnalysisCondition}
                />
                <div className="margin-t-20">
                  <PhaseIndexPlus
                    population={population}
                    percentages={percentages}
                    p3plusPop={p3plusPop}
                    p3plusPercentage={p3plusPercentage}
                    setHighlightedPhases={setHighlightedPhases}
                    analysisCondition={primaryAnalysisCondition}
                    analysisPeriod={period}
                  />
                </div>
              </React.Fragment>
            )}
            <div className="margin-top-20px">
              <DownloadButton handler={downloadMap} icon={faMap} text="Download map image" />
            </div>
            {!!(
              iscreatedAfter2016 &&
              this.state.activeAnalysis.show_ptt &&
              primaryAnalysisCondition !== 'C'
            ) && (
              <React.Fragment>
                <DownloadButton
                  handler={() => downloadData('xlsx')}
                  icon={faTable}
                  text="Download population excel"
                />
                <DownloadButton
                  handler={() => downloadData('geojson')}
                  icon={faDrawPolygon}
                  text="Download GIS format"
                />
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      );
    }

    return (
      <div className={`atlas-legend__details`}>
        {featureToUse && (
          <React.Fragment>
            <div className="atlas-legend__details__title">
              {featureToUse.title
                ? `${featureToUse.title}: ${this.state.activeAnalysis.analysis_name}`
                : ''}
            </div>
            <div>
              {+featureToUse.overall_phase === 9
                ? 'Insufficient Evidence/Data'
                : 'Area Not analyzed'}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  getCharts() {
    const { view, activeAnalysis } = this.state;
    if (view === 'single' || (activeAnalysis && activeAnalysis.periods.length <= 1)) {
      return this.getSingleView();
    }
    return this.getSplitView();
  }

  getDownloadButton() {
    const { activeAnalysis, primaryAnalysisCondition, groupId, view } = this.state;
    if (groupId === null) return null;

    const downloadMap = () => {
      let dateText = `Current: ${activeAnalysis.current_date_range}`;
      if (view === 'split')
        dateText = `${dateText} Projected: ${activeAnalysis.projected_date_range}`;

      exportMap(this.mapboxMaps, 8, null, dateText, null, primaryAnalysisCondition, true); // <- inches of width (@300 dpi), defaults to 12
    };

    return (
      <div className={`atlas-splash-dowload-buttons inner-padding-md margin-t-20`}>
        {/* {!highlightedFeature && ( */}
        <React.Fragment>
          <DownloadButton handler={downloadMap} icon={faMap} text="Download map image" />
        </React.Fragment>
        {/* )} */}
      </div>
    );
  }

  resizeIFrame(calculatedHeight) {
    this.setState({ iframeHeight: calculatedHeight + 700 });
  }

  togglePeriod(activePeriod) {
    const { updatePeriod } = this.context;

    this.setState({
      activeAnalysisPeriod: activePeriod,
    });

    updatePeriod(`?period=${activePeriod}`);
  }

  updateLegendView(legendView) {
    this.setState({ legendView });
  }

  async downloadMapImgae() {
    const { downloadMap } = this.context;
    await downloadMap();
  }

  render() {
    return this.getCharts();
  }
}

ContentBlockPublicAnalyis.contextType = DataContext;

ContentBlockPublicAnalyis.propTypes = {
  params: PropTypes.shape({
    analysisId: PropTypes.string,
    condition: PropTypes.string,
    period: PropTypes.string,
    mapOnly: PropTypes.string,
    includeTable: PropTypes.string,
  }),
};

export default ContentBlockPublicAnalyis;
