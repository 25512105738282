import React from 'react';
import LegendTable from '../LegendTable/LegendTable';
import './Interventions.scss';

function ChronicInterventions() {
  return (
    <LegendTable className="interventions" header>
      <div key="header">
        <div className="phase">Level</div>
        <div className="swatch" />
        <div>Intervention and Action</div>
      </div>
      <div key="p4">
        <div className="swatch chronic-phase4" />
        <div><span className="level-name">Level 4</span>
        Urgent action required to: Implement safety net programmes to improve the quality and quantity of food consumption. Implement complementary programmes to address
        </div>
      </div>
      <div key="p3">
        <div className="swatch chronic-phase3" />
        <div><span className="level-name">Level 3</span>
          Urgent action required to: Address underlying factors to increase the quality and quantity of food consumption and decrease chronic malnutrition. Consider safety net programmes as needed.
        </div>
      </div>
      <div key="p2">
        <div className="swatch chronic-phase2" />
        <div><span className="level-name">Level 2</span>
          Monitor the food security situation, invest in disaster risk reduction, and protect and strengthen livelihoods as needed. Address underlying factors to increase the quality of food consumption.
        </div>
      </div>
      <div key="p1">
        <div className="swatch chronic-phase1" />
        <div><span className="level-name">Level 1</span>
          Monitor the food security situation, invest in disaster risk reduction, and reinforce livelihoods as needed.
        </div>
      </div>
    </LegendTable>
  );
}

export default ChronicInterventions;
