import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';
import LegendTable from '../LegendTable/LegendTable';
import Tooltip from '../../Tooltip/Tooltip';
import Exportable from '../Exportable/Exportable';
import './LimitingFactors.scss';

class LimitingFactors extends React.Component {
  constructor(props) {
    super(props);
    this.axisRef = React.createRef();
    this.axis = d3.axisLeft().tickSize(230).ticks(4);
    this.factorNames = [
      { name: 'Food Availability', key: 'food_availability' },
      { name: 'Food Access', key: 'food_access' },
      { name: 'Food Utilization', key: 'food_utilization' },
    ];
    this.levels = [
      { key: 'None', text: 'Not a limiting factor' },
      { key: 'Minor', text: 'Minor factor' },
      { key: 'Major', text: 'Major factor' },
      { key: 'Extreme', text: 'Extreme factor' },
    ];
    this.chronicLevels = [
      { key: 'nota_limiting_factor', text: 'Not a limiting factor' },
      { key: 'minor_limiting_factor', text: 'Minor factor' },
      { key: 'major_limiting_factor', text: 'Major factor' },
      { key: 'extreme_limiting_factor', text: 'Extreme factor' },
    ];
    this.getBar = this.getBar.bind(this);
    // this.hover = null;
    this.state = {
      tooltip: null,
    };
  }

  componentDidUpdate() {
    const { qualitative, projectedQualitative, secondProjectedQualitative } = this.context;
    const { analysisPeriod, analysisCondition } = this.props;

    let qualitativeValues = qualitative;
    if (analysisCondition !== 'C') {
      qualitativeValues =
        analysisPeriod === 'A'
          ? secondProjectedQualitative
          : analysisPeriod === 'P'
          ? projectedQualitative
          : qualitativeValues;
    }
    if (!qualitativeValues || !qualitativeValues.length || !this.axisRef.current) return;
    // to do: only update if number of areas has changed
    const numAreas = qualitativeValues.length;
    const scale = d3.scaleLinear().domain([0, numAreas]).range([160, 0]);
    this.axis
      .scale(scale)
      // .ticks(numAreas > 3 ? 4 : 1);
      .ticks(6);
    d3.select(this.axisRef.current).transition().call(this.axis);
  }

  onHover(event, factor, level, count) {
    // const { setHover, analysisPeriod, analysisCondition } = this.props;
    const { setHover } = this.props;
    const { updateHighlightedAreas } = this.context;
    if (!factor || !level) {
      setHover(null);
      // this.hover = null;
      updateHighlightedAreas([]);
      this.removeTooltip();
      return;
    }
    setHover([factor, level]);
    // this.hover = [factor, level];
    // const { qualitative, projectedQualitative, secondProjectedQualitative, } = this.context;

    // commented code below to just remove the reactivity for LFM. Code can be returned anytime so save it here;
    // let qualitativeValues = qualitative;
    // if( analysisCondition !== 'C') {
    //   qualitativeValues = analysisPeriod === 'A' ? secondProjectedQualitative : ( analysisPeriod === 'P' ? projectedQualitative : qualitativeValues )
    // }
    // const matches = d => d[factor] === level || (level === 'None' && d[factor] === 'Ins');
    // const areas = qualitativeValues.filter(matches).map(d => d.aar_id);
    // updateHighlightedAreas(areas);
    this.updateTooltip({ event, factor, level, count });
  }

  getFactors() {
    const {
      qualitative,
      projectedQualitative,
      secondProjectedQualitative,
      analysisCondition,
    } = this.context;
    const { analysisPeriod } = this.props;

    const qualitativeValues =
      analysisPeriod === 'A'
        ? secondProjectedQualitative
        : analysisPeriod === 'P'
        ? projectedQualitative
        : qualitative;
    if (!qualitativeValues || !qualitativeValues.length) return [];

    const factors = this.factorNames.map(factor => {
      const factorData = {
        key: factor.key,
        total: qualitativeValues.length,
      };
      const lfmLevels = analysisCondition === 'C' ? this.chronicLevels : this.levels;
      lfmLevels.forEach(level => {
        const areas = qualitativeValues.filter(d => {
          const included =
            d[factor.key] === level.key ||
            ((level.key === 'None' || level.key === 'nota_limiting_factor') &&
              d[factor.key] === 'Ins');
          return included;
        });
        factorData[level.key] = {
          areas,
          count: areas.length,
        };
      });
      return factorData;
    });
    return factors;
  }

  getBar(factor) {
    const { highlightedAreas, analysisCondition } = this.context;
    const { hover } = this.props;
    const lfmLevels = analysisCondition === 'C' ? this.chronicLevels : this.levels;
    const barSegments = lfmLevels.map(level => {
      const pct = factor.total === 0 ? 0 : factor[level.key].count / factor.total;
      const style = {
        height: `${pct * 100}%`,
        display: pct > 0 ? 'block' : 'none',
      };
      const areaIds = factor[level.key].areas.map(d => d.aar_id);
      const intersection = areaIds.filter(id => (highlightedAreas || []).includes(id));
      const highlight =
        (hover === null && intersection.length) ||
        (hover !== null && [factor.key, level.key].every(d => hover.includes(d)));
      return (
        <div
          className={`limiting-factor-segment ${level.key.toLowerCase()}${
            highlight ? ' hover' : ''
          }`}
          key={level.key}
          style={style}
          onMouseOver={e => {
            this.onHover(e, factor.key, level.key, factor[level.key].count);
          }}
          onMouseOut={() => {
            this.onHover();
          }}
        />
      );
    });
    return (
      <div className="limiting-factor-bar" key={factor.key}>
        {barSegments}
      </div>
    );
  }

  updateTooltip({ event, factor, level, count }) {
    if (!event) return;
    const pos = {
      x: event.clientX,
      y: event.clientY,
    };
    const { analysisCondition } = this.context;
    const lfmLevels = analysisCondition === 'C' ? this.chronicLevels : this.levels;
    const factorName = this.factorNames.find(d => d.key === factor).name;
    const levelData = lfmLevels.find(d => d.key === level);
    const levelName = levelData ? levelData.text : 'Not a limiting factor';

    const tooltip = (
      <Tooltip pos={pos}>
        <div className="tooltip__title">{`${count} area${count !== 1 ? 's' : ''}`}</div>
        <div className="tooltip__text">
          <strong>{`${factorName}: `}</strong>
          {levelName}
        </div>
      </Tooltip>
    );

    this.setState({
      tooltip,
    });
  }

  removeTooltip() {
    this.setState({
      tooltip: null,
    });
  }

  renderTooltip() {
    const { tooltip } = this.state;
    if (tooltip === null) return null;
    return tooltip;
  }

  renderBarChart() {
    const { analysisCondition } = this.context;
    const factors = this.getFactors();
    const bars = factors.map(this.getBar);
    const labels = this.factorNames.map(f => <div key={f.key}>{f.name}</div>);
    const lfmLevels = analysisCondition === 'C' ? this.chronicLevels : this.levels;
    const legend = lfmLevels.map(l => (
      <div key={l.key}>
        <div className={`swatch ${l.key.toLowerCase()}`} />
        <div>{l.text}</div>
      </div>
    ));
    const { analysisPeriod } = this.props;
    const projected = analysisPeriod !== 'C';
    return (
      <div className={`limiting-factors${factors.length ? '' : ' no-data'}`}>
        <Exportable
          className="limiting-factors-graphic"
          suffix={projected ? '_projected' : ''}
          name="limiting factors graphic"
        >
          <div className="limiting-factor-labels">{labels}</div>
          <div className="limiting-factors-y">Number of areas</div>
          <svg className="limiting-factors-axis">
            <g transform="translate(250, 15)" ref={this.axisRef} />
          </svg>
          <div className="limiting-factor-bars">{bars}</div>
        </Exportable>
        <Exportable
          suffix={projected ? '_projected' : ''}
          name="limiting factors legend"
          className="export limiting-factors-legend"
        >
          {legend}
        </Exportable>
        {this.renderTooltip()}
      </div>
    );
  }

  renderSelectedArea() {
    const {
      selectedArea,
      qualitative,
      projectedQualitative,
      secondProjectedQualitative,
      highlightedAreas,
      analysisCondition,
      highlightedFeature,
      view_level,
    } = this.context;
    const { analysisPeriod } = this.props;
    const projected = analysisPeriod !== 'C';
    const qualitativeValues =
      analysisPeriod === 'A'
        ? secondProjectedQualitative
        : analysisPeriod === 'P'
        ? projectedQualitative
        : qualitative;

    let areaData = null;
    if (qualitativeValues) {
      let areasToFilter =
        view_level &&
        view_level.toLowerCase() === 'group' &&
        highlightedFeature &&
        highlightedFeature.group
          ? JSON.parse(highlightedFeature.group).children
          : highlightedAreas;
      areasToFilter = areasToFilter ? areasToFilter.map(i => Number(i)) : [];
      areaData = selectedArea
        ? qualitativeValues.filter(d => d.aar_id === selectedArea)
        : qualitativeValues.filter(d => (areasToFilter || []).includes(d.aar_id));
    }

    if (!areaData || !areaData.length || projected)
      return (
        <div className="limiting-factors no-data">
          <div className="no-data-chart">Not Analyzed</div>
        </div>
      );
    const header = (
      <div key="header">
        <div className="factor">Factor</div>
        <div>Rank</div>
      </div>
    );
    const lfmLevels = analysisCondition === 'C' ? this.chronicLevels : this.levels;
    const rows = this.factorNames.map(d => {
      const level = areaData[0][d.key];
      const levelName = lfmLevels.find(l => l.key === level) || { text: 'Not a limiting factor' };

      return (
        level !== null && (
          <div key={d.key}>
            <div className="factor">{d.name}</div>
            <div className={`swatch ${level.toLowerCase()}`} />
            <div>{levelName.text}</div>
          </div>
        )
      );
    });
    return (
      <Exportable
        suffix={projected ? '_projected' : ''}
        name="limiting factors graphic"
        className="limiting-factors"
      >
        <LegendTable header>{[header].concat(rows)}</LegendTable>
      </Exportable>
    );
  }

  render() {
    // const { selectedArea, highlightedAreas } = this.context;
    // if (!selectedArea && (! highlightedAreas || ! highlightedAreas.length )) return this.renderBarChart();
    const { selectedArea } = this.context;
    if (!selectedArea) return this.renderBarChart();
    return this.renderSelectedArea();
  }
}

LimitingFactors.contextType = DataContext;

LimitingFactors.defaultProps = {
  hover: null,
  setHover: () => {},
  analysisPeriod: 'C',
};

LimitingFactors.propTypes = {
  hover: PropTypes.arrayOf(PropTypes.string),
  setHover: PropTypes.func,
  analysisPeriod: PropTypes.string,
};

export default LimitingFactors;
