import React from 'react';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';
// import LegendTable from '../LegendTable/LegendTable';
import Exportable from '../Exportable/Exportable';
import './KeyDrivers.scss';
const _ = require('underscore');

const noData = <div className="key-drivers__legend"><div className="no-data-chart">Not Analyzed</div></div>;
const chronicAbbrev = {
  "lvst": "Livelihood Strategies",
  "hmcp": "Human Capital",
  "phcp": "Physical Capital",
  "fncp": "Financial Capital",
  "ntcp": "Natural Capital",
  "sccp": "Social Capital",
  "plinpr": "Policies, institutions and processes",
  "rcrs": "Recurrent/usual risks (on-going or usual stresses)",
  "uncr": "Unusual crises",
}
const chronicAbbrevInverted = _.invert(chronicAbbrev);

class KeyDrivers extends React.Component {
  constructor(props) {
    super(props);
    this.onHover = this.onHover.bind(this);
  }

  onHover(driver) {
    const { updateHighlightedAreas } = this.context;
    const { setHover, qualitative } = this.props;
    if (!driver) {
      setHover(null);
      updateHighlightedAreas(null);
    }
    else {
      const chronicdriver = chronicAbbrevInverted.hasOwnProperty(driver) ? chronicAbbrevInverted[driver] : false;
      const areaIds = qualitative && qualitative
        .filter(area => area.key_driver && (area.key_driver.includes(driver.toLowerCase()) || area.key_driver.includes(chronicdriver)))
        .map(area => area.aar_id)

      updateHighlightedAreas(areaIds);
    }
  }

  render() {
    const { highlightedAreas, highlightedFeature, analysisCondition, view_level } = this.context;
    const { qualitative, hover, analysisPeriod } = this.props;
    const projected = analysisPeriod !== 'C';
    if (!qualitative) return noData;

    let allDrivers = qualitative
      .map(d => d.key_driver)
      .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
      .filter(d => d !== null)
      .sort();

    if (analysisCondition && analysisCondition === 'C') {
      allDrivers = allDrivers.map(d => {
        return chronicAbbrev && chronicAbbrev.hasOwnProperty(d) ? chronicAbbrev[d] : d;
      });
    }

    const drivers = [...new Set(allDrivers)].map(d =>
      d.charAt(0).toUpperCase().concat(d.slice(1))
    );            
    if (!drivers.length) return noData;

    const rows = drivers.map(d => {
      const chronicdriver = chronicAbbrevInverted.hasOwnProperty(d)
        ? chronicAbbrevInverted[d]
        : false;
      const areaIds =
        qualitative &&
        qualitative
          .filter(
            area =>
              area.key_driver &&
              (area.key_driver.includes(d.toLowerCase()) ||
                area.key_driver.includes(chronicdriver))
          )
          .map(area => area.aar_id);
          
      let areasToFilter =
        view_level &&
        view_level.toLowerCase() === 'group' &&
        highlightedFeature &&
        highlightedFeature.group
          ? JSON.parse(highlightedFeature.group).children
          : highlightedAreas;
      areasToFilter = areasToFilter ? areasToFilter.map(i => Number(i)) : [];
      const intersection = areaIds && areaIds.filter(id => (areasToFilter || []).includes(+id));
      const highlight =
        hover === d || (hover === null && intersection && intersection.length);
      return (
        <li
          key={d}
          className={`${highlight ? 'hover' : ''}`}
          // onMouseOver={() => { this.onHover(d); }}
          // onMouseOut={() => { this.onHover(false); }}
        >
          <div className="label">{d}</div>
        </li>
      );
    });
    return (
      <Exportable
        suffix={projected ? '_projected' : ''}
        name="key drivers graphic"
        className="key-drivers__legend"
      >
        <ul className="list-unstyled">{rows}</ul>
      </Exportable>
    );
  }
}

KeyDrivers.contextType = DataContext;

KeyDrivers.defaultProps = {
  hover: null,
  setHover: () => {},
  analysisPeriod: 'C',
  qualitative: [],
};

KeyDrivers.propTypes = {
  hover: PropTypes.string,
  setHover: PropTypes.func,
  analysisPeriod: PropTypes.string,
  qualitative: PropTypes.arrayOf(PropTypes.object),
};

export default KeyDrivers;
