import React from 'react';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';

class Exportable extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { registerExportData } = this.context;
    registerExportData({ ref: this.ref, getExportData: this.getExport.bind(this) });
  }

  componentWillUnmount() {
    const { unRegisterExportData } = this.context;
    unRegisterExportData({ ref: this.ref });
  }

  getExport() {
    const {
      reportText,
      name,
      suffix,
      includeImage,
      exportFull,
    } = this.props;
    return {
      ref: this.ref,
      name,
      suffix,
      includeImage,
      reportText,
      exportFull,
    };
  }

  render() {
    const { children, className } = this.props;
    return (
      <div className={className} ref={this.ref}>
        {children}
      </div>
    );
  }
}

Exportable.defaultProps = {
  reportText: null,
  name: Date.now().toString,
  className: '',
  includeImage: true,
  suffix: '',
  exportFull: false,
};

Exportable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string,
  reportText: PropTypes.string,
  className: PropTypes.string,
  includeImage: PropTypes.bool,
  suffix: PropTypes.string,
  exportFull: PropTypes.bool,
};

Exportable.contextType = DataContext;

export default Exportable;
