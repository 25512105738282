import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import axios from 'axios';
import * as topojson from 'topojson-client';
import union from '@turf/union';
import DataContext from './DataContext';
import { getCurrentExport, generateReport } from '../App/Export/Export';

function getUrlParam(name) {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
}

const addDataToGeo = (featureCollection, populationData) => {
  return {
    ...featureCollection,
    features: featureCollection.features.map((feature, id) => {
      const { properties } = feature;
      const { aar_id } = properties;
      // the basic set that some components may require
      const phaseData = {
        phase1_pop: 0,
        phase2_pop: 0,
        phase3_pop: 0,
        phase4_pop: 0,
        phase5_pop: 0,
      };
      const popData = populationData.find(d => +d.aar_id === +aar_id);
      if (popData) {
        // find 'phase1','phase2' etc properties
        const phaseProperties = Object.entries(popData).filter(([key]) => key.match(/^phase\d+/));
        phaseProperties.forEach(([key, value]) => {
          phaseData[`${key}_pop`] = value.population;
          phaseData[`${key}_pop_percentage`] = value.population_percentage;
        });
        phaseData.overall_phase = popData.overall_phase;
        phaseData.confidence_level = popData.confidence_level;
        phaseData.view_level = popData.view_level;
      } else {
        phaseData.overall_phase = 0;
      }
      const groupData = populationData.find(
        d => popData && +d.aag_id === +popData.aag_id && !d.hasOwnProperty('aar_id')
      );
      if (groupData) {
        phaseData.group = {
          title: groupData.title,
          children: groupData.children,
        };
        const groupPhaseProperties = Object.entries(groupData).filter(([key]) =>
          key.match(/^phase\d+/)
        );
        groupPhaseProperties.forEach(([key, value]) => {
          phaseData.group[`${key}_pop`] = value.population;
          phaseData.group[`${key}_percentage`] = value.population_percentage;
        });
      }
      // extend feature properties with phase data
      return {
        ...feature,
        id,
        properties: {
          ...properties,
          ...phaseData,
        },
      };
    }),
  };
};

const downloadAs = (url, name) => {
  axios
    .get(url, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then(response => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch(err => {
      console.log('error', err);
    });
};

class DataContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.updateAnalysisId = this.updateDataFromAnalysisId.bind(this);
    this.exportRefs = new Map();
    this.exportData = new Map();

    const country = getUrlParam('country');
    const anlId = getUrlParam('anl_id') || getUrlParam('analysis_id');
    const mapOnly = getUrlParam('mapOnly') || getUrlParam('maponly');
    const includeTable = getUrlParam('table');
    const view = getUrlParam('view') || 'single';

    this.state = {
      activePhases: [5, 4, 3, 2, 1, 0, 9, 6],
      yearCurrent: true,
      legendActive: true,
      legendView: 'details',
      exportMode: false,
      styleActive: false,
      styleOverrides: {},
      basemapStyle: 'light.json',
      highlightedFeature: undefined,
      analysesPickList: null,
      selectedCountry: undefined,
      selectedGeom: null,
      highlightedPhases: null,
      geojson: null,
      projectedGeojson: null,
      secondProjectedGeojson: null,
      isCountryDashboard: false,
      year: 2020,

      country,
      nodata: false,
      countryName: '',
      selectedName: '',
      confience: 0,
      includeTable,
      mapOnly,
      analysisId: anlId,
      view_level: 'area',
      hideAnalyses: anlId !== null,
      selectedArea: null,
      loading: 'Loading analysis...',
      updateLoading: newLoading => {
        this.setState({
          loading: newLoading,
        });
      },
      highlightedAreas: [],
      atlasHighlights: [],
      population: null,
      projectedPopulation: null,
      secondProjectedPopulation: null,
      qualitative: null,
      projectedQualitative: null,
      secondProjectedQualitative: null,
      analyses: null,
      areasGeo: null,
      icons: null,
      projectedIcons: null,
      secondProjectedIcons: null,
      updateAnalysisId: this.updateAnalysisId,
      activeAtlas: 'normal',
      updateStyle: (key, value) => {
        const newStyle = Array.isArray(value) ? value : [value];
        const styleOverrides = { ...this.state.styleOverrides };
        styleOverrides[key] = newStyle;
        this.setState({ styleOverrides });
      },
      updateActiveAtlas: newActiveAtlas => {
        const { activeAtlas } = this.state;
        if (activeAtlas === newActiveAtlas) return;
        this.setState({
          activeAtlas: newActiveAtlas,
        });
      },
      inactiveAtlasTooltipData: null,
      setInactiveAtlasTooltipData: newTooltip => {
        this.setState({
          inactiveAtlasTooltipData: newTooltip,
        });
      },
      atlasView: {
        center: {
          lat: null,
          lng: null,
        },
        zoom: null,
      },
      atlasHoverArea: null,
      setAtlasHoverArea: area => {
        this.setState({
          atlasHoverArea: area,
        });
      },
      updateAtlasView: newView => {
        this.setState({
          atlasView: newView,
        });
      },
      updateSelectedArea: newAreaId => {
        const { highlightedAreas } = this.state;
        this.setState({
          selectedArea: newAreaId,
          highlightedAreas: newAreaId ? [] : highlightedAreas,
        });
      },
      updateHighlightedAreas: newAreas => {
        const { selectedArea } = this.state;
        if (selectedArea) return;
        this.setState({ highlightedAreas: newAreas || [] });
      },
      updateHighlightedFeature: feature => {
        this.setState({ highlightedFeature: feature });
      },
      updateAtlasHighlights: newAreas => {
        this.setState({
          atlasHighlights: newAreas || [],
        });
      },
      updateAnalysisPeriod: period => {
        const { analysisPeriod } = this.state;
        this.setState({ analysisPeriod: period || analysisPeriod });
      },
      view,
      updateView: newView => {
        this.setState({
          view: newView,
        });
      },
      iframeHeight: 765,
      dimensions: { height: window.innerHeight, width: window.innerWidth },
      reportMode: false,
      toggleReportMode: () => {
        const { reportMode } = this.state;
        this.setState({
          reportMode: !reportMode,
        });
      },
      registerExportData: ({ ref, getExportData }) => {
        this.exportRefs.set(ref, getExportData);
      },
      unRegisterExportData: ({ ref }) => {
        this.exportRefs.delete(ref);
      },
      drawExports: this.drawExports.bind(this),
      addExportData: this.addExportData.bind(this),
      clearExportData: this.clearExportData.bind(this),
      removeExportData: this.removeExportData.bind(this),
      resizeIFrame: this.resizeIFrame.bind(this),
      downloadData: (type, analysisId, analysisPeriod) => {
        if (!analysisId) alert('Analysis data is missing.');

        const { country, activeAnalysis } = this.state;
        // const url =
        //   country === 'gsu'
        //     ? `${process.env.REACT_APP_SERVERLESS_API_URL}/api/export/${type}/${analysisId}?period=${analysisPeriod}`
        //     : `${process.env.REACT_APP_SERVERLESS_API_URL}/api/export/${type}/${country}/${analysisId}?period=${analysisPeriod}`;

        const url =
          country === 'gsu'
            ? `${process.env.REACT_APP_SERVERLESS_API_URL}/api/export/${type}/${analysisId}?period=${analysisPeriod}`
            : `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/export/${type}/${analysisId}?period=${analysisPeriod}`;

        // window.open(url);
        downloadAs(url, `${country}-${activeAnalysis.title}`);
      },
      partners: [],
      analysisCondition: null, // didnt want to override the props
      analysisPeriod: getUrlParam('period') || 'C',
      activeAnalysisDates: null,
      updateData: this.updateData.bind(this),
    };
  }

  componentDidMount() {
    const { country, analysisId } = this.state;
    this.updateData({ country, analysisId });
    this.listenForResize();
  }

  listenForResize() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.setState({ dimensions: { height: window.innerHeight, width: window.innerWidth } });
  }

  resizeIFrame(calculatedHeight = 0) {
    this.setState({ iframeHeight: calculatedHeight + 765 });
  }

  updateDataFromAnalysisId(newId) {
    const { country } = this.state;
    this.setState({ loading: 'Loading analysis...' });
    this.setState({ analysisId: newId });
    this.updateData({ country, analysisId: newId });
  }

  async updateData({ country, analysisId }) {
    /**
     * I dont like the idea of loading all analyses; all the time even when we have an analysis id;
     * http://localhost:3000/?country=mz&anl_id=11659463 should not load  all analysis;
     * AR dashboard is giving a problem; if we load single analysis so add a flag check isCountryDashboard
     * implemented partial loads with promises which hopefully improves this significantly.
     */
    if (!country) {
      this.setState({
        loading: 'No country specified. Please pass a country iso-2 code as a query string.',
        view: 'single',
        noData: false,
      });
    } else {
      const isCountryDashboard = !(getUrlParam('anl_id') || getUrlParam('analysis_id'));
      const params = !isCountryDashboard && analysisId ? `?anl_id=${analysisId}` : '';
      axios
        .get(`${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/analyses${params}`)
        .then(({ data: analysisData }) => {
          const analyses = analysisData.filter(d => d.phase !== undefined);
          // use a default analysis if necessary
          const id = analysisId || (analyses && analyses.length > 0 && analyses[0].anl_id) || 0;
          let _anl = analyses && analyses.length && analyses.filter(d => d.anl_id === id);

          if ((!_anl || !_anl.length) && !analyses.length) {
            this.setState({ loading: 'no analyses found.', nodata: true });
            return false;
          }
          _anl = !_anl || !_anl.length ? [analyses[0]] : _anl;
          const condition = _anl && _anl.length && _anl[0].condition;
          const period = _anl && _anl.length && _anl[0].active_period;
          const analysisCondition = condition ? `&condition=${condition}` : '';
          const analysisPeriods = _anl && _anl.length && _anl[0].periods;
          const view_level = _anl && _anl.length && _anl[0].view_level;
          const show_area_popups = _anl && _anl.length && _anl[0].show_area_popups;
          const remainingPeriods = analysisPeriods.filter(p => p !== period);
          axios
            .get(`${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/metadata/${id}`)
            .then(({ data: metadata }) => {
              const { confidence, country_name } = metadata;
              axios
                .all([
                  axios.get(
                    `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/areas/${id}`
                  ),
                  axios.get(
                    `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/population/${id}?period=${period}`
                  ),
                ])
                .then(
                  axios.spread(({ data: areas }, { data: rawPopulation }) => {
                    // const areasGeo = topojson.feature(areas, areas.objects.areas);
                    const areasGeo = topojson.feature(areas, areas.objects.output);
                    const populationData = rawPopulation.map((d, i) => {
                      const copy = Object.assign({}, d);
                      copy.countryName = country_name;
                      if (copy.overall_phase === 999) {
                        // calculate overall phase here for now, we will move this to a db script latter on
                        if (copy.phase5.population_percentage * 100 >= 20) {
                          copy.overall_phase = 5;
                        } else if (
                          copy.phase5.population_percentage * 100 +
                            copy.phase4.population_percentage * 100 >=
                          20
                        ) {
                          copy.overall_phase = 4;
                        } else if (
                          copy.phase5.population_percentage * 100 +
                            copy.phase4.population_percentage * 100 +
                            copy.phase3.population_percentage * 100 >=
                          20
                        ) {
                          copy.overall_phase = 3;
                        } else if (
                          copy.phase5.population_percentage * 100 +
                            copy.phase4.population_percentage * 100 +
                            copy.phase3.population_percentage * 100 +
                            copy.phase2.population_percentage * 100 >=
                          20
                        ) {
                          copy.overall_phase = 2;
                        } else {
                          copy.overall_phase = 1;
                        }
                      }
                      return copy;
                    });
                    const geojson = addDataToGeo(areasGeo, populationData);

                    let selectedCountry;
                    // makes an object with phase populations and title for the country level
                    if (populationData.find(d => d.aag_id === 0)) {
                      const countryData = populationData.find(d => d.aag_id === 0);
                      selectedCountry = {
                        title: metadata.country,
                        ...Object.entries(countryData)
                          .filter(([key]) => key.match(/^phase\d+/))
                          .reduce((phaseData, [key, value]) => {
                            return {
                              ...phaseData,
                              [`${key}_pop`]: value.population,
                            };
                          }, {}),
                      };
                    }
                    const population =
                      period === 'A'
                        ? 'secondProjectedPopulation'
                        : period === 'P'
                        ? 'projectedPopulation'
                        : 'population';
                    this.setState({
                      loading: null,
                      countryName: metadata.country,
                      analysisCondition: condition,
                      activeAnalysis: _anl[0],
                      analysisPeriod: period,
                      analysisId: id,
                      view: 'single',
                      view_level,
                      show_area_popups,
                      isCountryDashboard,
                      analyses,
                      areasGeo,
                      confidence,
                      [population]: populationData,
                    });
                    axios
                      .all([
                        axios.get(
                          `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/icons/${id}?period=${period}`
                        ),
                        axios.get(
                          `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/qualitative/${id}?period=${period}${analysisCondition}`
                        ),
                      ])
                      .then(
                        axios.spread(({ data: rawIcons }, { data: rawQualitative }) => {
                          const icons =
                            period === 'A'
                              ? 'secondProjectedIcons'
                              : period === 'P'
                              ? 'projectedIcons'
                              : 'icons';
                          const qualitative =
                            period === 'A'
                              ? 'secondProjectedQualitative'
                              : period === 'P'
                              ? 'projectedQualitative'
                              : 'qualitative';

                          const geojsonKey =
                            period === 'A'
                              ? 'secondProjectedGeojson'
                              : period === 'P'
                              ? 'projectedGeojson'
                              : 'geojson';
                          this.setState({
                            loading: null,
                            countryName: metadata.country,
                            analysisCondition: condition,
                            activeAnalysis: _anl[0],
                            analysisPeriod: period,
                            analysisId: id,
                            view: 'single',
                            view_level,
                            show_area_popups,
                            analyses,
                            areasGeo,
                            [geojsonKey]: geojson,
                            selectedGeom:
                              geojson.features > 1 ? union(...geojson.features) : geojson.features,
                            selectedCountry,
                            confidence,
                            [population]: populationData,
                            [icons]: rawIcons,
                            [qualitative]: rawQualitative,
                          });
                        })
                      );

                    if (remainingPeriods && remainingPeriods.length && remainingPeriods[0]) {
                      axios
                        .all([
                          axios.get(
                            `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/population/${id}?period=${remainingPeriods[0]}`
                          ),
                          axios.get(
                            `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/icons/${id}?period=${remainingPeriods[0]}`
                          ),
                          axios.get(
                            `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/qualitative/${id}?period=${remainingPeriods[0]}${analysisCondition}`
                          ),
                        ])
                        .then(
                          axios.spread(
                            (
                              { data: r1rawPopulation },
                              { data: r1rawIcons },
                              { data: r1rawQualitative }
                            ) => {
                              const r1populationData = r1rawPopulation.map(d => {
                                const copy = Object.assign({}, d);
                                d3.range(1, 6).forEach(i => {
                                  copy[`phase${i}`] = copy[`phase${i}`] || {
                                    population: 0,
                                    population_percentage: 0,
                                  };
                                });
                                return copy;
                              });
                              const r1Geojson = addDataToGeo(areasGeo, r1populationData);
                              const r1populationKey =
                                remainingPeriods[0] === 'A'
                                  ? 'secondProjectedPopulation'
                                  : remainingPeriods[0] === 'P'
                                  ? 'projectedPopulation'
                                  : 'population';
                              const r1iconsKey =
                                remainingPeriods[0] === 'A'
                                  ? 'secondProjectedIcons'
                                  : remainingPeriods[0] === 'P'
                                  ? 'projectedIcons'
                                  : 'icons';
                              const r1qualitativeKey =
                                remainingPeriods[0] === 'A'
                                  ? 'secondProjectedQualitative'
                                  : remainingPeriods[0] === 'P'
                                  ? 'projectedQualitative'
                                  : 'qualitative';
                              const r1geojsonKey =
                                remainingPeriods[0] === 'A'
                                  ? 'secondProjectedGeojson'
                                  : remainingPeriods[0] === 'P'
                                  ? 'projectedGeojson'
                                  : 'geojson';
                              this.setState({
                                [r1populationKey]: r1populationData,
                                [r1iconsKey]: r1rawIcons,
                                [r1qualitativeKey]: r1rawQualitative,
                                [r1geojsonKey]: r1Geojson,
                              });
                            }
                          )
                        );
                    }

                    if (remainingPeriods && remainingPeriods.length && remainingPeriods[1]) {
                      axios
                        .all([
                          axios.get(
                            `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/population/${id}?period=${remainingPeriods[1]}`
                          ),
                          axios.get(
                            `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/icons/${id}?period=${remainingPeriods[1]}`
                          ),
                          axios.get(
                            `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/qualitative/${id}?period=${remainingPeriods[1]}${analysisCondition}`
                          ),
                        ])
                        .then(
                          axios.spread(
                            (
                              { data: r2rawPopulation },
                              { data: r2rawIcons },
                              { data: r2rawQualitative }
                            ) => {
                              const r2populationData = r2rawPopulation.map(d => {
                                const copy = Object.assign({}, d);
                                d3.range(1, 6).forEach(i => {
                                  copy[`phase${i}`] = copy[`phase${i}`] || {
                                    population: 0,
                                    population_percentage: 0,
                                  };
                                });
                                return copy;
                              });
                              const r2Geojson = addDataToGeo(areasGeo, r2populationData);
                              const r2populationKey =
                                remainingPeriods[1] === 'A'
                                  ? 'secondProjectedPopulation'
                                  : remainingPeriods[1] === 'P'
                                  ? 'projectedPopulation'
                                  : 'population';
                              const r2iconsKey =
                                remainingPeriods[1] === 'A'
                                  ? 'secondProjectedIcons'
                                  : remainingPeriods[1] === 'P'
                                  ? 'projectedIcons'
                                  : 'icons';
                              const r2qualitativeKey =
                                remainingPeriods[1] === 'A'
                                  ? 'secondProjectedQualitative'
                                  : remainingPeriods[1] === 'P'
                                  ? 'projectedQualitative'
                                  : 'qualitative';
                              const r2geojsonKey =
                                remainingPeriods[1] === 'A'
                                  ? 'secondProjectedGeojson'
                                  : remainingPeriods[1] === 'P'
                                  ? 'projectedGeojson'
                                  : 'geojson';
                              this.setState({
                                [r2populationKey]: r2populationData,
                                [r2iconsKey]: r2rawIcons,
                                [r2qualitativeKey]: r2rawQualitative,
                                [r2geojsonKey]: r2Geojson,
                              });
                            }
                          )
                        );
                    }

                    axios
                      .get(
                        `${process.env.REACT_APP_SERVERLESS_API_URL}/api/${country}/partners/${id}`
                      )
                      .then(({ data: partners }) => {
                        this.setState({ partners });
                      });
                  })
                );
            });
        });
    }
  }

  drawExports() {
    const { country, analysisId, view } = this.state;
    const exportData = Array.from(this.exportData.entries()).reduce(
      (main, [key, value]) => ({ ...main, [key]: value }),
      {}
    );
    generateReport({ country, analysisId, view, exportData });
  }

  async addExportData() {
    const { selectedArea, country } = this.state;
    const exportFunctions = Array.from(this.exportRefs.values());
    const data = await getCurrentExport({ exportFunctions });
    const id = selectedArea || country;
    this.exportData.set(id, data);
  }

  removeExportData({ aar_id }) {
    this.exportData.delete(aar_id);
  }

  clearExportData() {
    this.exportData.clear();
  }

  render() {
    const { children } = this.props;
    return <DataContext.Provider value={this.state}>{children}</DataContext.Provider>;
  }
}

DataContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default DataContextProvider;
