import React from 'react';
import DataContextProvider from '../DataContext/DataContextProvider.jsx';
import Header from './Header/Header.jsx';
import ContentBlock from './ContentBlock/ContentBlock.jsx';
import Footer from './Footer/Footer.jsx';
import AnalysesBlock from './AnalysesBlock/AnalysesBlock.jsx';
import ReportTabs from './ReportTabs/ReportTabs.jsx';
import LoadScreen from './LoadScreen/LoadScreen.jsx';
import './App.scss';
import DataContext from '../DataContext/DataContext';

const App = () => (
  <DataContextProvider>
    <DataContext.Consumer>
      {context => (
        <div className="outer-container">
          <Header />
          {context.reportMode && <ReportTabs />}
          <AnalysesBlock />
          <ContentBlock />
          <Footer />
          {context.loading !== null && <LoadScreen loading={context.loading} />}
        </div>
      )}
    </DataContext.Consumer>
  </DataContextProvider>
);

export default App;
