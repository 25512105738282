/* eslint-disable no-underscore-dangle */
import React from 'react';
import { BaseControl } from 'react-map-gl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './AtlasButton.scss';

class AtlasButton extends BaseControl {
  constructor(props) {
    super(props);
    this.renderTooltip = this.renderTooltip.bind(this);
  }

  renderTrigger(child) {
    if (this.props.tooltip) {
      return (
        <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip} placement={this.props.tooltipPosition}>
          {child}
        </OverlayTrigger>
      );
    }
    return child;
  }

  renderTooltip(props) {
    if (this.props.tooltip) {
      return (
        <Tooltip id="button-tooltip" {...props}>
          {this.props.tooltip}
        </Tooltip>
      );
    }
    return null;
  }

  _render() {
    let style = {};
    style.display = this.props.showButton ? 'block' : 'none';
    if (this.props.position) {
      style.position = 'absolute';
      style.zIndex = 99;
      style = { ...style, ...this.props.position };
    }
    return this.renderTrigger(
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group" style={style}>
        <button
          className={`mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-reset ${
            this.props.buttonText ? 'text' : ''
          }`}
          onClick={this.props.handler}
        >
          {this.props.icon} {this.props.buttonText}
        </button>
      </div>
    );
  }
}

export default AtlasButton;
