import React from 'react';

const DataContext = React.createContext({
  population: {},
  analyses: {},
  areasGeo: {},
  dimensions: { height: window.innerHeight, width: window.innerWidth },
  updateAnalysisId: () => {},
  country: null,
  analysisId: null,
  view: 'single', // single or split
  partners: [], // single or split
});

export default DataContext;
