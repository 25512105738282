/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { LinearInterpolator, WebMercatorViewport } from 'react-map-gl';
import bbox from '@turf/bbox';
import countryBounds from './country-bounds';

import './AtlasNew.scss';

class AtlasGeneric extends React.Component {
  constructor(props) {
    super(props);

    let longitude = 0;
    let latitude = 0;
    let zoom = 2.5;

    if (this.props.initialBounds && this.props.initialBounds.length) {
      const vp = new WebMercatorViewport({ width: 600, height: 800 }).fitBounds(
        this.props.initialBounds
      );
      ({ longitude, latitude, zoom } = vp);
    }

    this.state = {
      // allowPan: false,
      allowGlobal: true,
      showMask: false,
      areaDefaultFilter: ['all'],
      areaCountryFilter: ['!=', ['get', 'country'], ''],
      negativeCountryFilter: ['==', ['get', 'country'], ''],
      areaLabelsFilter: ['==', ['get', 'country'], 'NO MATCHES'],
      countryActiveFilter: ['in', 'country', ''],
      countryZoomLevel: false,
      originalStyle: {},
      bounds: null,
      selectedCountryBounds: null,
      stickyPopup: {},
      showTooltip: true,
      tooltip: null,
      globalBounds: null,
      viewport: {
        width: 600,
        height: 800,
        latitude,
        longitude,
        zoom,
        rotation: 0,
        bearing: 0,
      },
    };
    this.mapReset = this.mapReset.bind(this);
    this.onResize = this.onResize.bind(this);
    this.zoomToCountry = this.zoomToCountry.bind(this);
    this.map = React.createRef();
    this.disclaimer = React.createRef();
  }

  mapReset = () => {
    this.zoomReset(this.state.globalBounds);
    this.setState({
      countryZoomLevel: false,
      selectedCountryBounds: null,
      areaCountryFilter: ['!=', ['get', 'country'], ''],
      areaLabelsFilter: ['==', ['get', 'country'], 'NO MATCHES'],
    });
    this.props.setSelectedCountry(undefined);
  };

  onResize = (width, height) => {
    const viewport = { ...this.state.viewport };
    viewport.width = width;
    if (this.disclaimer.current) viewport.height = height - this.disclaimer.current.clientHeight;
    this.setState({ viewport });
  };

  zoomToCountry = (feature, event, skipZoom) => {
    this.props.setSelectedCountry(feature);
    // calculate the bounding box of the feature
    let [minLng, minLat, maxLng, maxLat] = bbox(feature);
    const bounds = countryBounds.find(
      c => c.country.toLowerCase() === feature.properties.country.toLowerCase()
    );
    if (bounds) [minLng, minLat, maxLng, maxLat] = bounds.bbox;

    let transition = {};
    if (event) {
      transition = {
        transitionInterpolator: new LinearInterpolator({
          around: [event.offsetCenter.x, event.offsetCenter.y],
        }),
        transitionDuration: 500,
      };
    }
    if (!skipZoom) this.zoomToBounds([minLng, minLat, maxLng, maxLat], transition);
    this.setState({
      countryZoomLevel: true,
      selectedCountryBounds: [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      showMask: feature.properties.mask,
      areaCountryFilter: ['==', ['get', 'country'], feature.properties.country],
      negativeCountryFilter: ['!=', ['get', 'country'], feature.properties.country],
      areaLabelsFilter: ['==', ['get', 'country'], feature.properties.country],
    });
  };

  removeTooltip = () => {
    this.setState({
      tooltip: null,
    });
  };

  zoomToBounds = ([minLng, minLat, maxLng, maxLat], transitionOptions = {}) => {
    // construct a viewport instance from the current state
    const viewport = new WebMercatorViewport(this.state.viewport);
    const { longitude, latitude, zoom } = viewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: 40,
      }
    );

    this.setState({
      viewport: {
        ...this.state.viewport,
        longitude,
        latitude,
        zoom,
        ...transitionOptions,
      },
    });
  };

  updateStyle = newStyles => {
    const map = this.map.current.getMap();
    newStyles.forEach(newStyle => {
      const { layers, type, attributes, value } = newStyle;
      attributes.forEach(attribute => {
        const styles = map.getStyle();
        styles.layers = styles.layers.map(l => {
          if (layers.includes(l.id)) {
            const originalLayer = JSON.parse(JSON.stringify(this.state.originalStyle)).layers.find(
              layer => layer.id === l.id
            );
            if (attribute === 'visibility') {
              if (!l[type]) l[type] = {};
              l[type][attribute] = value;
            } else if (Array.isArray(originalLayer[type][attribute])) {
              if (originalLayer[type][attribute][0] === 'coalesce') {
                l[type][attribute] = [...originalLayer[type][attribute]];
                l[type][attribute].splice(1, 0, JSON.parse(value));
              } else if (!originalLayer[type][attribute].find(i => Array.isArray(i))) {
                l[type][attribute] = JSON.parse(value);
              } else {
                l[type][attribute] = originalLayer[type][attribute].map((a, i) => {
                  if (Array.isArray(a)) {
                    if (a[0] === 'step') {
                      for (let n = 2; n < a.length; n += 2) {
                        a[n] *= parseFloat(value);
                      }
                    }
                  } else if (typeof a === 'number' && i % 2 === 0) {
                    a *= parseFloat(value);
                  }
                  return a;
                });
              }
            } else if (Array.isArray(value)) {
              l[type][attribute] = value;
            } else {
              l[type][attribute] = originalLayer[type][attribute]
                ? originalLayer[type][attribute] * parseFloat(value)
                : parseFloat(value);
            }
          }
          return l;
        });
        map.setStyle(styles);
      });
    });
  };

  disclaimerText =
    this.props.disclaimerText ||
    `Disclaimer: The information shown on this map does not imply that the IPC and CH officially recognize or endorse physical and political boundaries.`;
}

AtlasGeneric.propTypes = {
  initialBounds: PropTypes.arrayOf(PropTypes.array),
  setSelectedCountry: PropTypes.func.isRequired,
  disclaimerText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

AtlasGeneric.defaultProps = {
  initialBounds: null,
  disclaimerText: false,
};

export default AtlasGeneric;
