import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import AcutePhaseDonut from './AcutePhaseDonut/AcutePhaseDonut.jsx';
import AcutePhaseTable from './AcutePhaseTable/AcutePhaseTable.jsx';
import './AcutePhase.scss';

class AcutePhase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: null };
    this.updateHighlight = this.updateHighlight.bind(this);
  }

  updateHighlight(phase) {
    this.setState({ hover: phase });

    const { setHighlightedPhases } = this.props;

    if (phase) {
      setHighlightedPhases([phase]);
    } else {
      setHighlightedPhases(null);
    }
    // const { updateHighlightedAreas } = this.context;
    // const { population } = this.props
    // const areas = (population || []).filter((d) => {
    //   return d.overall_phase === phase;
    // }).map(d => d.aar_id);
    // updateHighlightedAreas(areas);
  }

  render() {
    const { hover } = this.state;
    const { population, percentages, overallPhase, className, analysisCondition } = this.props;
    const totalPop = d3.sum(population);
    const populationByPhase = totalPop
      ? population.map((p, i) => {
          return {
            data: p,
            population: p,
            phase: i + 1,
            percent: percentages[i],
          };
        })
      : null;

    return (
      <div className={`acute-phase ${className}`}>
        {(populationByPhase === null || overallPhase === 9) && (
          <div className="no-data-chart">
            {overallPhase === 9 ? `Insufficient Evidence/Data` : `Not Analyzed`}
          </div>
        )}
        {populationByPhase !== null && overallPhase !== 9 && (
          <AcutePhaseDonut
            populationByPhase={populationByPhase}
            hover={hover}
            analysisCondition={analysisCondition}
            updateHighlight={this.updateHighlight}
            // view={view}
          />
        )}
        {populationByPhase !== null && overallPhase !== 9 && (
          <AcutePhaseTable
            populationByPhase={populationByPhase}
            hover={hover}
            analysisCondition={analysisCondition}
            updateHighlight={this.updateHighlight}
            //= {view}
          />
        )}
      </div>
    );
  }
}

AcutePhase.defaultProps = {
  population: [],
  percentages: [],
  overallPhase: 3,
  className: '',
  analysisCondition: 'A',
  setHighlightedPhases: () => {},
};

AcutePhase.propTypes = {
  population: PropTypes.arrayOf(PropTypes.number),
  percentages: PropTypes.arrayOf(PropTypes.number),
  overallPhase: PropTypes.number,
  className: PropTypes.string,
  setHighlightedPhases: PropTypes.func,
  analysisCondition: PropTypes.string,
};

export default AcutePhase;
