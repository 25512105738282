import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import Exportable from '../ContentBlock/Exportable/Exportable';
import { downloadImage } from '../Export/Export';
import AnalysesMenu from './AnalysesMenu/AnalysesMenu';
import StackedBarChart from './StackedBarChart/StackedBarChart';
import DataContext from '../../DataContext/DataContext';
import './AnalysesBlock.scss';

import _ from 'underscore';

class AnalysesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blockIsOpen: true,
      visualizationCondition: 'A',
      chartType: 'percentage',
    };
    this.boxRef = React.createRef();
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidUpdate(prevProp, prevState) {
    const { analysisCondition } = this.context;
    if (
      !this.state.visualizationCondition &&
      prevState.visualizationCondition !== analysisCondition
    ) {
      this.setState({ visualizationCondition: analysisCondition });
    }
  }

  getCurrentAnalysis() {
    const { analysisId, analyses } = this.context;
    if (analyses === null || analyses === undefined) return {};
    return analyses.find(d => d.anl_id === analysisId);
  }

  getTitle() {
    const analysis = this.getCurrentAnalysis();
    if (analysis && 'title' in analysis) {
      return analysis.title;
    }
    return '';
  }

  getToggleText() {
    // const { blockIsOpen, } = this.state;
    // const icon = blockIsOpen
    //   ? <FontAwesomeIcon icon={faChevronUp} />
    //   : <FontAwesomeIcon icon={faChevronDown} />;
    return (
      <div>
        {/* <span className="analyses-block__toggle-text">
          //Change analysis
        //</span>
        //{icon}
        */}
      </div>
    );
  }

  toggleMenu() {
    const { blockIsOpen } = this.state;
    this.setState({ blockIsOpen: !blockIsOpen });
  }

  export = title => {
    const filename = title.toLowerCase().replace(/\s/g, '-');
    downloadImage(this.boxRef.current, filename, true);
  };

  changeChartType = e => {
    this.setState({ chartType: e.target.value });
  };

  changeCondition = e => {
    const condition = e.target.value;
    const { analysisCondition } = this.context;
    if (condition !== analysisCondition) {
      const { analyses, updateAnalysisId } = this.context;
      // update seleted analysis to match condition;
      let filteredAnalyses = analyses.filter(d => d.condition === condition);
      filteredAnalyses = _.sortBy(filteredAnalyses, 'fanalysis_date').reverse();
      if (filteredAnalyses && filteredAnalyses.length) {
        const analysis_id = filteredAnalyses[0].anl_id;
        updateAnalysisId(analysis_id);
      }
    }
    this.setState({ visualizationCondition: condition });
  };

  setActivePeriod = period => {
    const { updateAnalysisPeriod } = this.context;
    updateAnalysisPeriod(period);
  };

  render() {
    const { reportMode, hideAnalyses } = this.context;
    const { blockIsOpen } = this.state;
    const getMainClass = () => {
      let className = 'analyses-block__main';
      if (!blockIsOpen) className += ' analyses-block__main--hidden';
      return className;
    };
    return (
      <div className="analyses-block">
        <div className="container-fluid analyses-block__inner">
          {!reportMode && !hideAnalyses && 1 === 0 && (
            <div className="analyses-block__top">
              <div className="analyses-block__top-title">{this.getTitle()}</div>
              <div onClick={this.toggleMenu} className="analyses-block__toggle-button">
                {this.getToggleText()}
              </div>
            </div>
          )}
          {!reportMode && !hideAnalyses && (
            <div className={getMainClass()}>
              <AnalysesMenu />
              <div className="stacked-bar-wrapper">
                <div className="analyses-block__header row">
                  <div className="col-md-6 text-left">
                    Chart By &nbsp;
                    <input
                      type="radio"
                      name="type"
                      checked={this.state.chartType === 'percentage'}
                      value="percentage"
                      onChange={this.changeChartType}
                    />{' '}
                    % &nbsp;
                    <input
                      type="radio"
                      name="type"
                      checked={this.state.chartType === 'population'}
                      value="population"
                      onChange={this.changeChartType}
                    />{' '}
                    #
                  </div>
                  <div className="col-md-6 text-right">
                    <input
                      type="radio"
                      name="condition"
                      checked={this.state.visualizationCondition === 'A'}
                      value="A"
                      onChange={this.changeCondition}
                    />{' '}
                    Acute Food Insecurity &nbsp;
                    <input
                      type="radio"
                      name="condition"
                      checked={this.state.visualizationCondition === 'C'}
                      value="C"
                      onChange={this.changeCondition}
                    />{' '}
                    Chronic Food Insecurity &nbsp; &nbsp;
                    <button
                      className="badge badge-primary"
                      title="Download Image"
                      onClick={() => {
                        this.export('Data visualization');
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                  </div>
                </div>
                <section ref={this.boxRef}>
                  <Exportable includeImage={false} name="Data visualization">
                    <StackedBarChart
                      analysisCondition={this.state.visualizationCondition}
                      chartType={this.state.chartType}
                    />
                  </Exportable>
                </section>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

AnalysesBlock.contextType = DataContext;

export default AnalysesBlock;
