import React from 'react';
import * as d3 from 'd3';
// import { v4 as uuidv4 } from 'uuid';
// import axiosRetry from 'axios-retry';
import axios from 'axios';
import DataContext from './DataContext';

function getUrlParam(name) {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
}

const regions = {
  Asia: [
    { code: 'AF', name: 'Afghanistan' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'NP', name: 'Nepal' },
    { code: 'PH', name: 'Philippines' },
    { code: 'PK', name: 'Pakistan' },
    { code: 'TJ', name: 'Tajikistan' },
    { code: 'TL', name: 'Timor Leste' },
  ],
  'East and Central Africa': [
    { code: 'BI', name: 'Burundi' },
    { code: 'CD', name: 'Democratic Republic of Congo' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'KE', name: 'Kenya' },
    { code: 'RW', name: 'Rwanda' },
    { code: 'SD', name: 'Sudan' },
    { code: 'SO', name: 'Somalia' },
    { code: 'SS', name: 'South Sudan' },
    { code: 'TZ', name: 'Tanzania' },
    { code: 'UG', name: 'Uganda' },
    { code: 'CG', name: 'Congo' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'TD', name: 'Chad' },
  ],
  'Latin America and the Caribbean': [
    { code: 'HN', name: 'Honduras' },
    { code: 'HT', name: 'Haiti' },
    { code: 'AR', name: 'Argentina' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'LAC', name: 'LAC Region (Tri-National)' },
    { code: 'NI', name: 'Nicaragua' },
    { code: 'PA', name: 'Papua New Guinea' },
    { code: 'SV', name: 'El Salvador' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'MX', name: 'Mexico' },
    { code: 'PE', name: 'Peru' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'PY', name: 'Paraguay' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'JM', name: 'Jamaica' },
    { code: 'DO', name: 'Dominican Republic' },
  ],
  'Middle East and North Africa': [
    { code: 'YE', name: 'Yemen' },
    { code: 'SY', name: 'Syria' },
    { code: 'LB', name: 'Lebanon' },
    { code: 'PS', name: 'Palestine (Gaza Strip)' },
  ],
  'Southern Africa': [
    { code: 'AO', name: 'Angola' },
    { code: 'BW', name: 'Botswana' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'SZ', name: 'Eswatini' },
    { code: 'MW', name: 'Malawi' },
    { code: 'ZW', name: 'Zimbabwe' },
    { code: 'ZM', name: 'Zambia' },
    { code: 'MZ', name: 'Mozambique' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'NA', name: 'Namibia' },
    { code: 'ZA', name: 'South Africa' },
  ],
  // 'West Africa': [
  //   { code: 'TD', name: 'Chad' },
  //   { code: 'GN', name: 'Guinea' },
  //   { code: 'SN', name: 'Senegal' },
  //   { code: 'ML', name: 'Mali' },
  //   { code: 'MR', name: 'Mauritania' },
  //   { code: 'GM', name: 'Gambia' },
  //   { code: 'BF', name: 'Burkina Faso' },
  //   { code: 'GW', name: 'Guinea-Bissau' },
  //   { code: 'NE', name: 'Niger ' },
  //   { code: 'NG', name: 'Nigeria' },
  //   { code: 'GH', name: 'Ghana' },
  //   { code: 'BJ', name: 'Benin' },
  //   { code: 'LR', name: 'Liberia' },
  //   { code: 'SL', name: 'Sierra Leone' },
  //   { code: 'TG', name: 'Togo' },
  //   { code: 'CI', name: "Côte d'Ivoire" },
  //   { code: 'CM', name: 'Cameroon' },
  // ],
};

// function getPageHeight() {
//   let pageHeight = 0;
//   const findHighestNode = nodesList => {
//     for (let i = nodesList.length - 1; i >= 0; i--) {
//       if (nodesList[i].scrollHeight && nodesList[i].clientHeight) {
//         const elHeight = Math.max(nodesList[i].scrollHeight, nodesList[i].clientHeight);
//         pageHeight = Math.max(elHeight, pageHeight);
//       }
//       if (nodesList[i].childNodes.length) findHighestNode(nodesList[i].childNodes);
//     }
//   };
//   findHighestNode(document.documentElement.childNodes);
//   return pageHeight;
// }


const downloadAs = (url, name) => {
  axios.get(url, { responseType: 'blob' }).then(response => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

class DataContextPTTProvider extends React.Component {
  constructor(props) {
    super(props);

    // Sets up our initial state
    const region = getUrlParam('region');
    const countryParam = getUrlParam('country');
    const countries = countryParam ? countryParam.split(',') : null;
    const allCountries = countries || (region ? regions[region].map(e => e.code).sort() : null);
    const anlType = getUrlParam('anl_type') || getUrlParam('analysis_type'); // analysis condition
    const anlYear = +getUrlParam('anl_year') || +getUrlParam('year'); // analysis year
    const period = getUrlParam('period');
    const currentYear = new Date().getFullYear();
    const startYear = 2017;
    this.state = {
      publicView: true,
      country: 'gsu',
      regions,
      year: anlYear || `${startYear},${currentYear}`,
      countryCode: allCountries ? allCountries.map(d => d.toLowerCase()) : null,
      activeCountries: null,
      anlType: anlType || 'A',
      period: period || '',
      loading: 'Loading data...',
      hasMoreAnalysis: 1,
      page: 1,
      // perPage: getPageHeight > 2000 ? 35 : getPageHeight > 1250 ? 25 : 20,
      perPage: 20,
      pageRange: 5,
      totalAnalysisCount: 0,
      population: [],
      populationGrandSubTotals: [],
      noData: false,
      updateLoading: newLoading => {
        this.setState({ loading: newLoading });
      },
      updatePagination: async pageNumber => {
        await this.setState({ population: [], page: pageNumber, loading: 'Loading data...' });
        this.updateData(pageNumber);
      },
      updateYear: async year => {
        await this.setState({
          population: [],
          year: year.join(','),
          page: 1,
          loading: 'Loading data...',
        });
        this.updateData();
      },
      updateSelectedRegion: async region => {
        let countries = [];
        if (region) countries = regions[region].map(e => e.code).sort();
        await this.setState({
          population: [],
          loading: 'Loading data...',
          page: 1,
          countryCode: countries,
        });
        this.updateData();
      },
      updateSelectedCountry: async country => {
        const countries = country ? country.split(',') : [];
        await this.setState({
          population: [],
          loading: 'Loading data...',
          page: 1,
          countryCode: countries,
        });
        this.updateData();
      },
      updateCondition: async condition => {
        await this.setState({ population: [], loading: 'Loading data...', anlType: condition });
        this.updateData();
      },
      updatePeriod: async period => {
        await this.setState({ period });
        // this.updateData();
      },
      downloadCSV: () => {
        this.downloadCSV();
      },
      dimensions: { height: window.innerHeight, width: window.innerWidth },
      resizeIFrame: this.resizeIFrame.bind(this),
    };
  }

  componentDidUpdate() {
    // https://reactjs.org/docs/hooks-effect.html
    document.title = 'Population Tracking Tool';
  }

  componentDidMount() {
    // https://reactjs.org/docs/hooks-effect.html
    document.title = 'Population Tracking Tool ';

    this.getCountryCodes();
    this.getTotalAnalysisCount();
    this.updateData();
    this.listenForResize();
  }

  listenForResize() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.setState({ dimensions: { height: window.innerHeight, width: window.innerWidth } });
  }

  async getCountryCodes() {
    const countries = await d3.json(
      `${process.env.REACT_APP_S3_API_URL}/api/public/active/country/codes`
    );
    const activeCountries = countries.map(e => e.code);
    this.setState({
      activeCountries,
    });
  }

  async getTotalAnalysisCount() {
    const { year, period, countryCode, anlType } = this.state;
    const fCondition = anlType ? `&condition=${anlType}` : '';
    const fCountry = countryCode && countryCode.length ? `&country=${countryCode.join(',')}` : '';
    const fPeriod = period ? `&period=${period}` : '';
    const result = await d3.json(
      `${process.env.REACT_APP_S3_API_URL}/api/public/analysis/count/${year}/?${fCondition}${fCountry}${fPeriod}`
    );
    this.setState({
      totalAnalysisCount: +result.count,
    });
  }

  async updateData(offset) {
    const { year, period, countryCode, anlType, perPage } = this.state;
    offset = offset || 1;
    const fCondition = anlType ? `&condition=${anlType}` : '';
    const fCountry = countryCode && countryCode.length ? `&country=${countryCode.join(',')}` : '';
    const fPeriod = period ? `&period=${period}` : '';
    // first get total count with no pagination
    if (offset === 1) {
      this.getTotalAnalysisCount();
    }
    // const nextAnalysisPopulationFigures = await d3.json(
    //   `${process.env.REACT_APP_S3_API_URL}/api/public/population-tracking-tool/data/${year}/?page=${offset}&limit=${perPage}${fCondition}${fCountry}${fPeriod}`
    // );
    // const grandandSubtotalPopulationFigures = await d3.json(
    //   `${process.env.REACT_APP_S3_API_URL}/api/public/analysis/ptt-totals/${year}/?totals=true${fCondition}${fCountry}${fPeriod}`
    // );

    Promise.all([
      d3.json(
        `${process.env.REACT_APP_PTT_API_URL}/api/public/population-tracking-tool/data/${year}/?page=${offset}&limit=${perPage}${fCondition}${fCountry}${fPeriod}`
      ),
      d3.json(
        `${process.env.REACT_APP_PTT_API_URL}/api/public/analysis/ptt-totals/${year}/?totals=true${fCondition}${fCountry}${fPeriod}`
      ),
    ])
      .then(([nextAnalysisPopulationFigures, grandandSubtotalPopulationFigures]) => {
        this.setState({
          population: nextAnalysisPopulationFigures,
          populationGrandSubTotals: grandandSubtotalPopulationFigures,
          loading: null,
          view: 'single',
          noData: false,
        });
      })
      .catch(error => {
        console.log('error', error);
        this.setState({
          loading: null,
          view: 'single',
          noData: false,
        });
      });
  }

  downloadData(type = 'xlsx') {
    const { year, period, countryCode, anlType } = this.state;
    const queryString = ['export=true'];
    if (anlType) queryString.push(`condition=${anlType}`);
    if (countryCode && countryCode.length) queryString.push(`country=${countryCode.join(',')}`);
    if (period) queryString.push(`period=${period}`);

    const pttQueryString = queryString.length ? `?${queryString.join('&')}` : '';
    // const url = `${process.env.REACT_APP_S3_API_URL}/api/public/population-tracking-tool/data/${year}/?export=true${fCondition}${fCountry}${fPeriod}`;
    const url = `${process.env.REACT_APP_PTT_API_URL}/api/public/population-tracking-tool/data/${year}/${pttQueryString}`;
    window.open(url);

    // this is the serverless api download
    // const uuId = uuidv4();
    // const url = `${process.env.REACT_APP_S3_API_URL}/api/public/population-tracking-tool/export/${year}/${uuId}${pttQueryString}`;
    // const country = countryCode && countryCode.length ? countryCode.join('-') : 'All Countries';

    // this.setState({ loading: 'Generating excel download ' });
    // axios.get(url).then(res => {
    //   axiosRetry(axios, {
    //     retries: 40,
    //     retryDelay: replyDelay => replyDelay * 1000,
    //   });
    //   axios
    //     .get(`${process.env.REACT_APP_S3_API_URL}/api/download/${uuId}`)
    //     .then(({ data }) => {
    //       // console.log('response', data);
    //       downloadAs(data.url, `${country}-${year.replace(/,/, '-')}`);
    //       this.setState({ loading: null });
    //     })
    //     .catch(err => {
    //       this.setState({ loading: null });
    //       console.log('error', err);
    //     });
    // });
  }

  downloadCSV() {
    this.downloadData('xlsx');
  }

  resizeIFrame(calculatedHeight) {
    calculatedHeight = calculatedHeight || 85;
    try {
      // window.top.frameResize(rHeight);
      if (!window.location.href.match('http://localhost')) {
        const rHeight = calculatedHeight + 700;
        document.domain = 'ipcinfo.org';
        const iframe = document.getElementById('hiddenIframe');
        iframe.src = `http://www.ipcinfo.org/index.php?id=93852&height=${rHeight}`;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const { children } = this.props;
    return <DataContext.Provider value={this.state}>{children}</DataContext.Provider>;
  }
}

export default DataContextPTTProvider;
