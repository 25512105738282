import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import './LayoutBoxToggle.scss';

function LayoutBoxToggle(props) {
  const {
    title,
    active,
    onToggle,
  } = props;
  return (
    <div className="layout-box-toggle">
      <div className="layout-box-toggle__title">{title}</div>
      <div className="layout-box-toggle__icon" onClick={() => { onToggle(!active); }}>
        {active && <FontAwesomeIcon icon={faToggleOn} />}
        {!active && <FontAwesomeIcon icon={faToggleOff} />}
      </div>
    </div>
  );
}

LayoutBoxToggle.defaultProps = {
  title: '',
  active: false,
  onToggle: () => {},
};

LayoutBoxToggle.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default LayoutBoxToggle;
