import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faChartPie,
  faKey,
  // faCampground,
  faExclamation,
  // faCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import TooltipChart from '../TooltipChart/TooltipChart.jsx';
// import Exportable from '../../Exportable/Exportable.jsx';

import { ReactComponent as Bag } from '../../../../icons/bag.svg';
import { ReactComponent as Bag2 } from '../../../../icons/bag2.svg';
import { ReactComponent as Idp } from '../../../../icons/idp.svg';
import { ReactComponent as Hhg } from '../../../../icons/hhg.svg';
import { ReactComponent as Urb } from '../../../../icons/urb.svg';
import { ReactComponent as Rfg } from '../../../../icons/rfg.svg';
import { ReactComponent as RiskFamine } from '../../../../icons/rfm.svg';
import './AtlasLegend.scss';

class AtlasLegend extends React.Component {
  renderMapKey(analysisCondition, chcountries) {
    if (chcountries) return this.renderCHMapKey();
    return analysisCondition === 'C' ? this.renderChronicMapKey() : this.renderAcuteMapKey();
  }

  // eslint-disable-next-line class-methods-use-this
  renderCHMapKey() {
    const barPhases = [
      {
        phase: 1,
        population_percentage: 0.1,
      },
      {
        phase: 2,
        population_percentage: 0.2,
      },
      {
        phase: 3,
        population_percentage: 0.4,
      },
      {
        phase: 4,
        population_percentage: 0.2,
      },
      {
        phase: 5,
        population_percentage: 0.1,
      },
    ];
    return (
      <div className="atlas-legend__inner">
        <div className="atlas-legend__map">
          <div className="atlas-legend__header mapkey">
            CH Map Key: Acute Food and Nutrition Insecurity
          </div>
          <div className="margin-t-5 margin-b-8">
            Mapped Phase represents highest severity affecting at least 20% of the population
          </div>
          <div className="atlas-legend__section">
            <div className="legend-table-row">
              <div className="swatch phase1" />
              <div>1-Minimal</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase2" />
              <div>2-Stressed</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase3" />
              <div>3-Crisis</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase4" />
              <div>4-Emergency</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase5" />
              <div>5-Famine</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch no-evidence" />
              <div>Not analysed</div>
            </div>
          </div>
        </div>
        <div className="atlas-legend__other">
          <div className="atlas-legend__section">
            <div className="atlas-legend__header">Area-specific Call Out Box</div>
            <div>Area Name</div>
            <div className="atlas-legend__chart-container">
              <TooltipChart phases={barPhases} />
              <div className="atlas-legend__bar-legend">
                <div className="atlas-legend__bar-legend__pct">
                  <span>0%</span>
                </div>
                <div className="atlas-legend__bar-legend__pct">
                  <span>100%</span>
                </div>
              </div>
            </div>
            <div className="margin-t-20">% of population in each phase</div>
            <div>Area Phase, Population in Phase 3 or above # and %</div>
          </div>
        </div>
      </div>
    );
  }

  renderAcuteMapKey() {
    const barPhases = [
      {
        phase: 1,
        population_percentage: 0.1,
      },
      {
        phase: 2,
        population_percentage: 0.2,
      },
      {
        phase: 3,
        population_percentage: 0.4,
      },
      {
        phase: 4,
        population_percentage: 0.2,
      },
      {
        phase: 5,
        population_percentage: 0.1,
      },
    ];
    return (
      <div className="atlas-legend__inner">
        <div className="atlas-legend__map">
          <div className="atlas-legend__header mapkey">
            {this.props.splash && 'IPC '}Map Key: Acute Food Insecurity
          </div>
          <div className="margin-t-5 margin-b-8">
            Mapped Phase represents highest severity affecting at least 20% of the population
          </div>
          <div className="atlas-legend__section">
            <div className="legend-table-row">
              <div className="swatch phase1" />
              <div>1-Minimal</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase2" />
              <div>2-Stressed</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase3" />
              <div>3-Crisis</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase4" />
              <div>4-Emergency</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch phase5" />
              <div>5-Famine (with solid evidence)</div>
            </div>
            <div className="legend-table-row">
              <img
                width="14px"
                className="atlas-legend-icon"
                src="/icons/famine-likely.png"
                alt="5-Famine (with reasonable evidence)"
              />
              <span className="atlas-legend-icon-text">5-Famine (with reasonable evidence)</span>
            </div>
            <div className="legend-table-row">
              <div className="swatch no-evidence" />
              <div>Areas with inadequate evidence</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch no-data" />
              <div>Areas not analyzed</div>
            </div>
          </div>
        </div>
        <div className="atlas-legend__other">
          <div className="atlas-legend__section">
            <div className="atlas-legend__header">Map Symbols</div>
            <div className="legend-table-row">
              <span className="atlas-legend-icon">
                <Urb className="icon-urb" alt="Urban Settlement Classification" />
              </span>
              <span className="atlas-legend-icon-text">Urban Settlement Classification</span>
            </div>
            <div className="legend-table-row">
              <span className="atlas-legend-icon">
                <Idp className="icon-idp" alt="IDPs/Other Settlement Classification" />
              </span>
              <span className="atlas-legend-icon-text">IDPs/Other Settlement Classification</span>
            </div>
            <div className="legend-table-row">
              <Hhg
                className="atlas-legend-icon icon-hhg"
                alt="HouseHold Group Settlement Classification"
              />
              <span className="atlas-legend-icon-text">
                HouseHold Group Settlement Classification
              </span>
            </div>
            <div className="legend-table-row">
              <span className="atlas-legend-icon">
                <Rfg className="icon-rfg" alt="Refugees Classification" />
              </span>
              <span className="atlas-legend-icon-text">Refugees Classification</span>
            </div>
            <div className="legend-table-row">
              <span className="atlas-legend-icon">
                <RiskFamine
                  className="atlas-legend-icon icon-risk-famine"
                  alt="Risk Famine Classification"
                />
              </span>
              <span className="atlas-legend-icon-text">Risk of Famine</span>
            </div>
          </div>
          <section>
            <div className="margin-b-8">
              <strong>Area recieves significant humanitarian food assistance</strong>(accounted for
              in Phase Classification)
            </div>
            <div className="legend-table-row">
              <span className="atlas-legend-icon">
                <Bag width="15px" height="15px" className="icon-bag" />
              </span>
              <span className="atlas-legend-icon-text">
                At least 25% of households meet 25-50% of caloric needs from humanitarian food
                assistance
              </span>
            </div>
            <div className="legend-table-row">
              <span className="atlas-legend-icon">
                <Bag2 width="15px" height="15px" className="icon-bag2" />
              </span>
              <span className="atlas-legend-icon-text">
                At least 25% of households meet over 50% of caloric needs from humanitarian food
                assistance
              </span>
            </div>
            {!this.props.splash && (
              <div className="legend-table-row">
                <FontAwesomeIcon className="fad atlas-legend-icon" icon={faExclamation} />
                <span className="atlas-legend-icon-text">
                  Area would likely be at least one Phase worse without the effects of humanitarian
                  assistance
                </span>
              </div>
            )}
          </section>
        </div>
        <div className="atlas-legend__other">
          <div className="atlas-legend__section">
            <div className="atlas-legend__header">Area-specific Call Out Box</div>
            <div>Area Name</div>
            <div className="atlas-legend__chart-container">
              <TooltipChart phases={barPhases} />
              <div className="atlas-legend__bar-legend">
                <div className="atlas-legend__bar-legend__pct">
                  <span>0%</span>
                </div>
                <div className="atlas-legend__bar-legend__pct">
                  <span>100%</span>
                </div>
              </div>
            </div>
            <div className="margin-t-20">% of population in each phase</div>
            <div>Area Phase, Population in Phase 3 or above # and %</div>
          </div>
          {!this.props.splash && (
            <div className="atlas-legend__section">
              <div className="atlas-legend__header">Evidence Level of Analysis</div>
              <div className="atlas-legend__text">
                <strong>*</strong> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Acceptable
              </div>
              <div className="atlas-legend__text">
                <strong>**</strong> &nbsp; &nbsp;&nbsp; Medium
              </div>
              <div className="atlas-legend__text">
                <strong>***</strong> &nbsp;&nbsp; High
              </div>
              {/* <div className="legend-table-row margin-t-5">
                <img className="atlas-legend-icon" src="/icons/scarce_evidence.png" alt="bag" />
                <span className="atlas-legend-icon-text">Limited humanitarian access</span>
              </div> */}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderChronicMapKey() {
    const barLevels = [
      {
        phase: 1,
        population_percentage: 0.1,
      },
      {
        phase: 2,
        population_percentage: 0.2,
      },
      {
        phase: 3,
        population_percentage: 0.4,
      },
      {
        phase: 4,
        population_percentage: 0.2,
      },
    ];
    return (
      <div className="atlas-legend__inner">
        <div className="atlas-legend__map">
          <div className="atlas-legend__header mapkey">
            {this.props.splash && 'IPC '}Map Key: Chronic Food Insecurity
          </div>
          <div className="margin-t-5 margin-b-8">
            Mapped Level represents highest severity affecting at least 20% of the population
          </div>

          <div className="atlas-legend__section">
            <div className="legend-table-row">
              <div className="swatch chronic-phase1" />
              <div>1: Minimal CFI</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch chronic-phase2" />
              <div>2: Mild CFI</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch chronic-phase3" />
              <div>3: Moderate CFI</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch chronic-phase4" />
              <div>4: Severe CFI</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch chronic-no-evidence" />
              <div>Areas with inadequate evidence</div>
            </div>
            <div className="legend-table-row">
              <div className="swatch chronic-no-data" />
              <div>Areas not analyzed</div>
            </div>
            <div className="legend-table-row">
              <FontAwesomeIcon className="fad atlas-legend-icon" icon={faSyncAlt} />
              <span className="atlas-legend-icon-text">
                Area classified as crisis or worse during at least 3 years in previous 10 years
              </span>
            </div>
          </div>
        </div>
        <div className="atlas-legend__other">
          <div className="atlas-legend__section">
            <div className="atlas-legend__header">Area-specific Call Out Box</div>
            <div>Area Name</div>
            <div className="atlas-legend__chart-container">
              <TooltipChart phases={barLevels} conditionClassPrefix="chronic-" />
              <div className="atlas-legend__bar-legend">
                <div className="atlas-legend__bar-legend__pct">
                  <span>0%</span>
                </div>
                <div className="atlas-legend__bar-legend__pct">
                  <span>100%</span>
                </div>
              </div>
            </div>
            <div className="margin-t-20">% of population in each phase</div>
            <div>Area Level, Population in Level 3 or above # and %</div>
          </div>
          {!this.props.splash && (
            <div className="atlas-legend__section">
              <div className="atlas-legend__header">Evidence Level</div>
              <div className="atlas-legend__text">
                <strong>*</strong> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Acceptable
              </div>
              <div className="atlas-legend__text">
                <strong>**</strong> &nbsp; &nbsp;&nbsp; Medium
              </div>
              <div className="atlas-legend__text">
                <strong>***</strong> &nbsp;&nbsp; High
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      active,
      position,
      toggle,
      publicViewDetails,
      legendView,
      updateLegendView,
      // analysisPeriod,
      analysisCondition,
      publicMap,
      togglable,
      isCHCountries,
    } = this.props;

    // const projected = analysisPeriod !== 'C';
    const viewToShow = publicViewDetails === null ? 'key' : legendView;

    const className = `atlas-legend${active ? ' active' : ' hidden'} ${viewToShow} ${
      position === 'bottom' ? ' bottom' : ''
    }${publicMap ? ' public' : 'client'}`;
    return (
      <React.Fragment>
        <div className={className}>
          {publicViewDetails !== null && (
            <div className="atlas-legend__top">
              <section
                className={`atlas-legend__details-button ${
                  viewToShow === 'details' ? 'active' : ''
                }`}
                onClick={() => {
                  updateLegendView('details');
                }}
              >
                <FontAwesomeIcon icon={faChartPie} />
                <span>Details</span>
              </section>
              <section
                className={`atlas-legend__key-button ${viewToShow === 'key' ? 'active' : ''}`}
                onClick={() => {
                  updateLegendView('key');
                }}
              >
                <FontAwesomeIcon icon={faKey} />
                <span>Key</span>
              </section>
            </div>
          )}

          {this.renderMapKey(analysisCondition, isCHCountries)}

          {viewToShow === 'details' && publicViewDetails}
          {togglable && (
            <Button
              size="lg"
              variant="light"
              className="atlas-legend__close"
              onClick={() => {
                toggle();
              }}
            >
              {active ? (
                <FontAwesomeIcon icon={faChevronRight} />
              ) : (
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

AtlasLegend.defaultProps = {
  active: false,
  position: 'right',
  toggle: () => {},
  publicViewDetails: null,
  legendView: 'details',
  updateLegendView: () => {},
  analysisPeriod: 'C',
  analysisCondition: 'A',
  publicMap: false,
  togglable: true,
  isCHCountries: false,
  splash: false,
};

AtlasLegend.propTypes = {
  active: PropTypes.bool,
  position: PropTypes.string,
  toggle: PropTypes.func,
  publicViewDetails: PropTypes.node,
  legendView: PropTypes.string,
  updateLegendView: PropTypes.func,
  analysisPeriod: PropTypes.string,
  analysisCondition: PropTypes.string,
  publicMap: PropTypes.bool,
  togglable: PropTypes.bool,
  splash: PropTypes.bool,
  isCHCountries: PropTypes.bool,
};

export default AtlasLegend;
