import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import './Tooltip.scss';

const tooltipRoot = document.getElementById('tooltip-root');

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.anchorRef = React.createRef();
    this.state = {
      dragPos: props.pos,
    };
    this.dragging = false;
    this.dragOffset = {
      x: 0,
      y: 0,
    };
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  componentDidMount() {
    tooltipRoot.appendChild(this.el);
    const { containerRef } = this.props;
    const { dragPos } = this.state;
    const newPos = {};
    if (containerRef && containerRef.current) {
      const bbox = containerRef.current.getBoundingClientRect();
      const minX = bbox.left - 50;
      const minY = bbox.top + window.scrollY - 50;
      const maxX = bbox.right - 350;
      const maxY = bbox.bottom + window.scrollY - 175;
      newPos.x = Math.max(minX, Math.min(dragPos.x, maxX));
      newPos.y = Math.max(minY, Math.min(dragPos.y, maxY));
    }
    this.setState({
      dragPos: newPos,
    });
    window.requestAnimationFrame(this.componentDidUpdate.bind(this)); // this seems bad
  }

  componentDidUpdate() {
    const { persistent, updateEndAnchor } = this.props;
    if (!persistent) return;
    const refBBox = this.anchorRef.current.getBoundingClientRect();
    const { x, y } = refBBox;
    const endAnchor = { x, y: y + window.scrollY };
    updateEndAnchor({ endAnchor });
  }

  componentWillUnmount() {
    tooltipRoot.removeChild(this.el);
  }

  onMouseDown(event) {
    const { persistent, containerRef } = this.props;
    if (!persistent) return;
    event.preventDefault();
    const { pageX, pageY } = event;
    const { dragPos } = this.state;

    this.dragOffset.x = pageX - dragPos.x;
    this.dragOffset.y = pageY - dragPos.y;
    this.dragging = true;
    const drag = e => {
      const x = dragPos.x + e.pageX - pageX;
      const y = dragPos.y + e.pageY - pageY;
      const newPos = { x, y };
      if (containerRef && containerRef.current) {
        const bbox = containerRef.current.getBoundingClientRect();
        const minX = bbox.left - 50;
        const minY = bbox.top + window.scrollY - 50;
        const maxX = bbox.right - 350;
        const maxY = bbox.bottom + window.scrollY - 175;
        newPos.x = Math.max(minX, Math.min(newPos.x, maxX));
        newPos.y = Math.max(minY, Math.min(newPos.y, maxY));
      }
      this.setState({
        dragPos: newPos,
      });
    };
    const dragEnd = () => {
      window.removeEventListener('mouseup', dragEnd);
      window.removeEventListener('mousemove', drag);
    };
    window.addEventListener('mouseup', dragEnd);
    window.addEventListener('mousemove', drag);
  }

  render() {
    const { children, persistent, onClose, pos, phase, className, top, left } = this.props;
    const { dragPos } = this.state;
    const style = {};
    const posToUse = persistent ? dragPos : pos;
    if (!persistent && posToUse.x > window.innerWidth)
      style.right = `${window.innerWidth / 0.5 - posToUse.x}px`;
    else if (!persistent && posToUse.x > window.innerWidth / 2)
      style.right = `${window.innerWidth - posToUse.x}px`;
    else style.left = `${posToUse.x}px`;

    if (!persistent && posToUse.y > 200) style.bottom = `${window.innerHeight - posToUse.y}px`;
    else style.top = `${posToUse.y}px`;
    const tooltip = (
      <div
        // eslint-disable-next-line prettier/prettier
        className={`tooltip${persistent ? ' tooltip--persistent' : ''}${top ? ' top' : ''}${
          left ? ' left' : ''
        }`}
        style={style}
        draggable
        onMouseDown={this.onMouseDown}
      >
        {persistent && (
          <div
            className="tooltip__close-button"
            onClick={() => {
              console.log('CLICK');
              onClose();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        <div className="tooltip__content">
          <div
            className={`tooltip__phase ${className}${
              this.props.condition === 'C' ? 'chronic-' : ''
            }phase${phase}`}
          >
            {persistent && <div className="tooltip__handle" />}
          </div>
          <div className="tooltip__inner">{children}</div>
        </div>
        {persistent && <div className="tooltip__anchor" ref={this.anchorRef} />}
      </div>
    );
    return tooltip;
  }
}

Tooltip.defaultProps = {
  persistent: false,
  onClose: () => {},
  updateEndAnchor: () => {},
  containerRef: null,
  phase: 0,
  className: '',
  condition: 'A',
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  pos: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  persistent: PropTypes.bool,
  onClose: PropTypes.func,
  updateEndAnchor: PropTypes.func,
  containerRef: PropTypes.objectOf(PropTypes.object),
  phase: PropTypes.number,
  className: PropTypes.string,
  condition: PropTypes.string,
};

export default Tooltip;
