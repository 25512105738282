import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';
import LegendTable from '../LegendTable/LegendTable';
import Exportable from '../Exportable/Exportable';
import './PhaseIndexPlus.scss';

function getPopulationByPhase(filtered) {
  const range = filtered && filtered.length > 0 && filtered[0].condition === 'C' ? 4 : 5;
  const phaseData = filtered.map(d => d3.range(range).map(i => d[`phase${i + 1}`]));
  const byPhase = d3
    .range(range)
    .map(i => [].concat(phaseData.map(d => (d[i] ? d[i].population : 0))));

  const totalPop = filtered && filtered.length > 0 ? filtered[0].area_population : 0;
  const populationByPhase = byPhase.map((p, i) => {
    const sum = d3.sum(p);
    return {
      data: p,
      population: sum,
      phase: i + 1,
      percent: sum / totalPop,
      estimated_area_population: totalPop,
    };
  });
  return populationByPhase;
}

function getPopulationP3Plus(filtered) {
  const totals = filtered && filtered.length > 0 && filtered[0].aag_id === 0 ? filtered[0] : {};
  if (totals && totals.hasOwnProperty('phase6')) {
    return {
      population: totals.phase6.population,
      percent: totals.phase6.population_percentage,
      estimated_area_population: totals.phase7.population,
    };
  }
  return false;
}

class PhaseIndexPlus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
    this.updateHover = this.updateHover.bind(this);
  }

  getContainerClass() {
    const { hover } = this.state;
    const { view } = this.context;
    const { condition } = this.props;
    let className = `phase-bars ${condition === 'C' ? ' chronic-phase-bars ' : ''}`;
    if (hover) {
      className += ' hover';
    }
    if (view === 'split') {
      className += ' phase-bars--split';
    }
    return className;
  }

  updateHover(hover) {
    this.setState({ hover });
  }

  renderBar(populationByPhase, conditionPhasePlus) {
    return populationByPhase.map(d => (
      <div
        className={`phase-bar phase${d.phase}`}
        key={d.phase}
        style={{
          width: `${d.percent * 100}%`,
          visibility: d.percent > 0 ? 'visible' : 'hidden',
        }}
        onMouseOver={() => {
          /** if (d.phase >= conditionPhasePlus) this.updateHover(true); **/
        }}
        onMouseOut={() => {
          /** if (d.phase >= conditionPhasePlus) this.updateHover(false); **/
        }}
      />
    ));
  }

  render() {
    const { analysisPeriod, population, condition } = this.props;
    const projected = analysisPeriod !== 'C';
    const { selectedArea, highlightedAreas, view_level } = this.context;
    const areaToFilter =
      selectedArea ||
      (highlightedAreas && highlightedAreas.length === 1 ? highlightedAreas[0] : null);
    let wFiltered = (population || []).find(d => {
      if (!areaToFilter) return +d.aag_id === 0;
      return d.aar_id === areaToFilter;
    });
    
    if (areaToFilter && wFiltered && view_level && view_level.toLowerCase() === 'group') {
      /** if group views return group data **/
      wFiltered = (population || []).find(d => {
        return d.aag_id === wFiltered.aag_id && d.hasOwnProperty('children');
      });
    }

    const filtered = [];
    if (wFiltered) filtered[0] = wFiltered;

    const populationByPhase = getPopulationByPhase(filtered);
    const analysisCondition =
      filtered[0] && filtered[0].condition ? filtered[0].condition : condition;

    // const conditionPhasePlus = analysisCondition && analysisCondition === 'C' ? 2 : 3;
    const conditionPhasePlus = 3;
    const analysisPhaseLabel = analysisCondition && analysisCondition === 'C' ? 'Level' : 'Phase';
    const analysisPhaseLabelPrefix = analysisCondition && analysisCondition === 'C' ? 'L' : 'P';

    // calculate the level 3+ or phase 3 +, using total area population
    // GSU Data often has  this data saved; comes as phase6
    // area totals comes as phase7
    let conditionIndexPlus, conditionIndexPlusPercent, estimateAreaPopulation;
    const populationIndexPlus = getPopulationP3Plus(filtered);
    if (populationIndexPlus) {
      conditionIndexPlus = populationIndexPlus.population;
      conditionIndexPlusPercent = populationIndexPlus.percent;
      estimateAreaPopulation = populationIndexPlus.estimated_area_population;
    } else {
      const sliceIndex = 2;
      conditionIndexPlus = d3.sum(populationByPhase.slice(sliceIndex), d => d.population);
      conditionIndexPlusPercent = d3.sum(populationByPhase.slice(sliceIndex), d => d.percent);
      estimateAreaPopulation =
        populationByPhase[0] && populationByPhase[0].estimated_area_population
          ? populationByPhase[0].estimated_area_population
          : 0;
    }

    const lineStyle = {
      left: `${(1 - conditionIndexPlusPercent) * 100}%`,
      visibility: conditionIndexPlusPercent < 1 ? 'visible' : 'hidden',
    };
    const populationFormat = d3.format(',d');
    const hasData = !populationByPhase.every(d => d.population === 0);
    const overallPhase =
      filtered[0] && filtered[0].overall_phase ? filtered[0].overall_phase : null;

    const totalPhasePopulation = d3.sum(populationByPhase, d => d.population);
    const validBarChart = totalPhasePopulation <= estimateAreaPopulation * 1.05;
    return (
      <div className={`phase-plus${analysisCondition ? ` condition-${analysisCondition}` : ''}`}>
        {(!hasData || overallPhase === 9) && (
          <div className="no-data-chart">
            {overallPhase === 9 ? `Insufficient Evidence/Data` : `Not Analyzed`}
          </div>
        )}

        {!validBarChart && (
          <div className="no-data-chart">
            Total {analysisPhaseLabel} population provided is greater than the estimated area
            population
          </div>
        )}

        {hasData && overallPhase !== 9 && validBarChart && (
          <Exportable
            suffix={projected ? '_projected' : ''}
            name={`${analysisPhaseLabel} ${conditionPhasePlus} or higher graphic`}
            className={this.getContainerClass()}
          >
            {this.renderBar(populationByPhase, conditionPhasePlus)}
            <div className="phase-plus-line" style={lineStyle} />
          </Exportable>
        )}

        {hasData && overallPhase !== 9 && validBarChart && (
          <Exportable
            suffix={projected ? '_projected' : ''}
            name={`${analysisPhaseLabel} ${conditionPhasePlus} or higher legend`}
            className="export phase-plus__legend"
          >
            <LegendTable header>
              <div className="header-row" key="row1">
                <div className="flex">
                  Pop. {analysisPhaseLabelPrefix}
                  {conditionPhasePlus}+
                </div>
                <div className="percent">%</div>
              </div>
              <div key="row2">
                <div className="flex">{populationFormat(conditionIndexPlus)}</div>
                <div className="percent">{d3.format('d')(conditionIndexPlusPercent * 100)}</div>
              </div>
            </LegendTable>
          </Exportable>
        )}
      </div>
    );
  }
}

PhaseIndexPlus.contextType = DataContext;

PhaseIndexPlus.defaultProps = {
  population: null,
  analysisPeriod: 'C',
  condition: '',
};

PhaseIndexPlus.propTypes = {
  population: PropTypes.arrayOf(PropTypes.object),
  analysisPeriod: PropTypes.string,
  condition: PropTypes.string,
};

export default PhaseIndexPlus;
