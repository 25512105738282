/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import DataContext from '../../DataContext/DataContext';
import LayoutBox from './Layout/LayoutBox.jsx';
import LayoutBoxToggle from './Layout/LayoutBoxToggle/LayoutBoxToggle.jsx';
import AcutePhase from './AcutePhase/AcutePhase.jsx';
import ChronicPhase from './ChronicPhase/ChronicPhase.jsx';
import PhaseIndexPlus from './PhaseIndexPlus/PhaseIndexPlus.jsx';
import KeyDrivers from './KeyDrivers/KeyDrivers.jsx';
import LimitingFactors from './LimitingFactors/LimitingFactors.jsx';
import AcuteInterventions from './Interventions/AcuteInterventions.jsx';
import ChronicInterventions from './Interventions/ChronicInterventions.jsx';
import PopulationEstimates from './PopulationEstimates/PopulationEstimates.jsx';
import Metadata from './Metadata/Metadata.jsx';
import AtlasLegend from './Atlas/AtlasLegend/AtlasLegend.jsx';
import AtlasStyling from './Atlas/AtlasStyling/AtlasStyling.jsx';
import './ContentBlock.scss';
import AtlasNew from './Atlas/AtlasNew.jsx';

class ContentBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitingFactorsHover: null,
      keyDriversHover: null,
      legendActive: false,
      editing: false,
      styleActive: false,
    };
    this.setLimitingFactorsHover = this.setLimitingFactorsHover.bind(this);
    this.setKeyDriversHover = this.setKeyDriversHover.bind(this);
    this.toggleSplit = this.toggleSplit.bind(this);
    this.mapboxMaps = {};
  }

  setLimitingFactorsHover(status) {
    this.setState({
      limitingFactorsHover: status,
    });
  }

  setKeyDriversHover(status) {
    this.setState({
      keyDriversHover: status,
    });
  }

  toggleSplit() {
    const { updateView, view, updateAnalysisPeriod } = this.context;
    const analysis = this.getCurrentAnalysis();
    updateAnalysisPeriod(analysis.active_period);

    updateView(view !== 'single' ? 'single' : 'split');
  }

  setActivePeriod = period => {
    const { updateAnalysisPeriod } = this.context;
    updateAnalysisPeriod(period);
  };

  getCurrentAnalysis() {
    const { analysisId, analyses, activeAnalysis } = this.context;
    return activeAnalysis || (analyses ? analyses.find(d => d.anl_id === analysisId) : {});
  }

  activeCondition() {
    const { analysisCondition } = this.context;
    if (analysisCondition) return analysisCondition;

    const activeAnalysis = this.getCurrentAnalysis();
    return activeAnalysis ? activeAnalysis.condition : 'A';
  }

  activePeriod() {
    const { analysisPeriod } = this.context;
    const activeAnalysis = this.getCurrentAnalysis();
    return analysisPeriod || (activeAnalysis ? activeAnalysis.active_period : 'C');
  }

  activeAnalysisPeriods() {
    const { analysisPeriod } = this.context;
    const activeAnalysis = this.getCurrentAnalysis();
    return activeAnalysis ? activeAnalysis.periods : [analysisPeriod];
  }

  activePopulation() {
    const { population, projectedPopulation, secondProjectedPopulation } = this.context;
    if (this.activeCondition() === 'C') return population;

    const period = this.activePeriod();
    return period === 'A'
      ? secondProjectedPopulation
      : period === 'P'
      ? projectedPopulation
      : population;
  }

  activeIcons() {
    const { icons, projectedIcons, secondProjectedIcons } = this.context;
    const period = this.activePeriod();
    return period === 'A' ? secondProjectedIcons : period === 'P' ? projectedIcons : icons;
  }

  activeQualitative() {
    const { qualitative, projectedQualitative, secondProjectedQualitative } = this.context;
    if (this.activeCondition() === 'C') return qualitative;
    const period = this.activePeriod();
    return period === 'A'
      ? secondProjectedQualitative
      : period === 'P'
      ? projectedQualitative
      : qualitative;
  }

  activeGeoJson() {
    const { geojson, projectedGeojson, secondProjectedGeojson } = this.context;
    if (this.activeCondition() === 'C') return geojson;
    const period = this.activePeriod();
    return period === 'A' ? secondProjectedGeojson : period === 'P' ? projectedGeojson : geojson;
  }

  areaName() {
    const { selectedArea, countryName } = this.context;
    const pp = this.activePopulation();
    return selectedArea && pp ? pp.find(d => +d.aar_id === +selectedArea).title : countryName;
  }

  areaQualitative() {
    const { selectedArea, highlightedAreas } = this.context;
    const q = this.activeQualitative();
    if (selectedArea) {
      return q ? q.find(d => +d.aar_id === +selectedArea) : null;
    }

    if (highlightedAreas && highlightedAreas.length) {
      return q ? q.find(d => +d.aar_id === +highlightedAreas[0]) : null;
    }
    return null;
  }

  areaConfidence() {
    const { confidence } = this.context;
    const q = this.areaQualitative();
    return q ? q.confidence : confidence;
  }

  activeAnalysisDates() {
    const { analysisCondition } = this.context;
    const wAnalysis = this.getCurrentAnalysis();
    const projectedActiveAnalysisDates =
      wAnalysis && 'projected_from_date' in wAnalysis && wAnalysis.projected_from_date
        ? `Projected: ${wAnalysis.projected_date_range}`
        : '';
    const secondProjectedActiveAnalysisDates =
      wAnalysis && 'second_projected_from_date' in wAnalysis && wAnalysis.second_projected_from_date
        ? `2nd Projected: ${wAnalysis.second_projected_date_range}`
        : '';
    const currentActiveAnalysisDates =
      wAnalysis && 'current_from_date' in wAnalysis && wAnalysis.current_from_date
        ? analysisCondition === 'C'
          ? `Create date ${wAnalysis.analysis_date}`
          : `Current: from ${wAnalysis.current_date_range}`
        : '';
    const period = this.activePeriod();
    return period === 'A'
      ? secondProjectedActiveAnalysisDates
      : period === 'P'
      ? projectedActiveAnalysisDates
      : currentActiveAnalysisDates;
  }

  getSingleView() {
    const {
      reportMode,
      country,
      selectedArea,
      updateSelectedArea,
      analysisId,
      analyses,
      downloadData,
      activeAnalysis,
      activePhases,
      isCountryDashboard,
      view_level,
      styleOverrides,
    } = this.context;

    const toggleStylePanel = () => {
      this.setState({
        styleActive: !this.state.styleActive,
        legendActive: this.state.styleActive === true ? false : this.state.legendActive,
      });
    };

    const basemapSelect = e => {
      const basemapStyle = e.target.getAttribute('value');
      if (basemapStyle) {
        this.setState({ basemapStyle });
      }
    };

    const updateStyle = (key, value) => {
      const { updateStyle } = this.context;
      updateStyle(key, value);
    };

    const { keyDriversHover, limitingFactorsHover } = this.state;
    const areaName = this.areaName();
    const areaConfidence = this.areaConfidence();
    const activeAnalysisDates = this.activeAnalysisDates();
    const activePopulation = this.activePopulation();
    const analysisPeriods = this.activeAnalysisPeriods();
    const hasProjected = analysisPeriods && analysisPeriods.includes('P');
    const hasSecondProjected = analysisPeriods && analysisPeriods.includes('A');
    const activeGeoJson = this.activeGeoJson();

    const { legendActive, styleActive } = this.state;
    const onLegendToggle = active => {
      this.setState({
        legendActive: active,
        styleActive: active === true ? false : this.state.styleActive,
      });
    };
    const legendToggle = reportMode ? null : (
      <LayoutBoxToggle title="Map Key" active={legendActive} onToggle={onLegendToggle} />
    );
    const editToggle = !isCountryDashboard ? (
      <LayoutBoxToggle
        title="Editable"
        active={this.state.editing}
        onToggle={() => {
          if (this.state.editing) this.context.updateData({ country, analysisId });
          this.setState({ editing: !this.state.editing });
        }}
      />
    ) : null;
    const wAnalysis = activeAnalysis || (analyses && analyses.find(d => d.anl_id === analysisId));
    const analysisCondition = wAnalysis ? wAnalysis.condition : '';
    const conditionPhasePlus = 3;

    // excel exports are now required only to be post 2017
    // ofcourse the stupid date format always come to bite;
    const analysisCreateYear = wAnalysis
      ? wAnalysis.analysis_date.substring(
          wAnalysis.analysis_date.length,
          wAnalysis.analysis_date.length - 4
        )
      : 0;
    const iscreatedAfter2016 = analysisCreateYear >= 2017;

    const setHighlightedFeature = feature => {
      const highlighted = feature && [feature.aar_id];
      this.context.updateHighlightedAreas(highlighted);
      this.context.updateHighlightedFeature(feature);
    };

    const setSelectedCountry = feature => {
      this.setState({ selectedCountry: feature });
    };

    const addMapRef = ({ type, position, map }) => {
      this.mapboxMaps = {
        ...this.mapboxMaps,
        [`${type}-${position || ''}`]: { type, position, map },
      };
    };

    const deleteMapRef = ({ type, position }) => {
      delete this.mapboxMaps[`${type}-${position || ''}`];
    };

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-9 col-md-9">
            <Metadata
              name={areaName}
              confidence={areaConfidence}
              population={activePopulation}
              selectedArea={selectedArea}
              updateSelectedArea={updateSelectedArea}
              analysisPeriod={this.activePeriod()}
              analysisCondition={analysisCondition}
            />
          </div>
          <div className="col-lg-3 col-md-3 text-right">
            <div className="inner-padding">
              {this.activePeriod() === 'C' && hasProjected && (
                <button
                  onClick={() => this.setActivePeriod('P')}
                  className="btn btn-outline-secondary"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                  &nbsp; View Projected
                </button>
              )}

              {this.activePeriod() === 'P' && hasSecondProjected && (
                <button
                  onClick={() => this.setActivePeriod('A')}
                  className="btn btn-outline-secondary"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                  &nbsp; View 2nd Projected
                </button>
              )}

              {!!['A'].includes(this.activePeriod()) &&
                analysisPeriods &&
                analysisPeriods.includes('C') && (
                  <button
                    onClick={() => this.setActivePeriod('C')}
                    className="btn btn-outline-secondary"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                    &nbsp; View Current
                  </button>
                )}

              {!!['P'].includes(this.activePeriod()) &&
                !hasSecondProjected &&
                analysisPeriods &&
                analysisPeriods.includes('C') && (
                  <button
                    onClick={() => this.setActivePeriod('C')}
                    className="btn btn-outline-secondary"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                    &nbsp; View Current
                  </button>
                )}

              {analysisPeriods && analysisPeriods.length > 1 && (
                <button onClick={this.toggleSplit} className="btn btn-outline-secondary">
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp; Compare
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-lg-9 col-md-9">
            {/*
               https://stackoverflow.com/questions/33381029/react-how-to-pass-html-tags-in-props#answer-59212609 
               passing a mapTitle because React Fragment is an object. Title is required by export component as a string
            */}
            <LayoutBox
              country={country}
              mapboxMaps={this.mapboxMaps}
              analysis={wAnalysis}
              title={wAnalysis ? wAnalysis.title : ''}
              mapTitle={
                <React.Fragment>
                  {wAnalysis ? wAnalysis.title : ''} <span>{activeAnalysisDates}</span>
                </React.Fragment>
              }
              reportMode={reportMode}
              toggle={legendToggle}
              editToggle={editToggle}
              analysisId={parseInt(analysisId, 10)}
              analysisPeriod={this.activePeriod()}
              downloadData={downloadData}
              exportImage={!this.state.editing}
              geojsonExport={true}
            >
              <Row noGutters={true} className="atlas-container">
                {!isCountryDashboard && (
                  <AtlasStyling
                    ar
                    styleActive={styleActive}
                    handler={updateStyle}
                    toggle={toggleStylePanel}
                    basemapSelect={basemapSelect}
                    selectedGeom={this.state.selectedGeom}
                  />
                )}
                <Col
                  xl={legendActive ? 10 : 12}
                  md={legendActive ? 9 : 12}
                  style={{ height: '100%' }}
                >
                  {activeGeoJson && (
                    <AtlasNew
                      show_area_popups={this.context.show_area_popups}
                      toggleStyle={this.toggleStylePanel}
                      styleOverrides={styleOverrides}
                      activePhases={activePhases}
                      activeCondition={analysisCondition}
                      activePeriod={this.activePeriod()}
                      saveMapRef={addMapRef}
                      deleteMapRef={deleteMapRef}
                      setHighlightedFeature={setHighlightedFeature}
                      setSelectedCountry={setSelectedCountry}
                      highlightedPhases={this.context.highlightedPhases}
                      basemapStyle={this.context.basemapStyle}
                      geojson={activeGeoJson}
                      analysisId={analysisId}
                      icons={this.activeIcons()}
                      highlightedFeature={this.context.highlightedFeature}
                      highlightedAreas={this.context.highlightedAreas}
                      period={this.activePeriod()}
                      showStickyPopups={!isCountryDashboard}
                      year={this.context.year}
                      draggable={!isCountryDashboard && this.state.editing}
                    />
                  )}
                </Col>
                {(legendActive || reportMode) && (
                  <Col xl={2} md={3}>
                    <AtlasLegend
                      active={legendActive || reportMode}
                      position={reportMode ? 'bottom' : null}
                      analysisPeriod={this.activePeriod()}
                      analysisCondition={analysisCondition}
                    />
                  </Col>
                )}
              </Row>
            </LayoutBox>
            <LayoutBox
              title="Area Population Estimates"
              analysisId={parseInt(analysisId, 10)}
              xlsxExport={iscreatedAfter2016}
              analysisPeriod={this.activePeriod()}
              downloadData={downloadData}
              reportMode={reportMode}
              exportFull
            >
              <PopulationEstimates
                view_level={view_level}
                population={activePopulation}
                analysisPeriod={this.activePeriod()}
                analysisCondition={analysisCondition}
              />
            </LayoutBox>
            <div className={analysisCondition === 'C' ? `condition-C` : ''}>
              <LayoutBox
                title="Interventions and Actions"
                exportable={false}
                reportMode={reportMode}
              >
                {analysisCondition === 'C' ? <ChronicInterventions /> : <AcuteInterventions />}
              </LayoutBox>
            </div>
          </div>
          <div className={`col-lg-3 col-md-3 ${analysisCondition === 'C' ? 'condition-C' : ''}`}>
            <div className="inner-padding">
              <LayoutBox
                title={analysisCondition === 'C' ? `Chronic Phases` : `Acute Phases`}
                reportMode={reportMode}
              >
                {analysisCondition === 'C' ? (
                  <ChronicPhase
                    population={activePopulation}
                    analysisPeriod={this.activePeriod()}
                  />
                ) : (
                  <AcutePhase population={activePopulation} analysisPeriod={this.activePeriod()} />
                )}
              </LayoutBox>
              <LayoutBox
                title={
                  analysisCondition === 'C'
                    ? `Level ${conditionPhasePlus} or Higher `
                    : `Phase ${conditionPhasePlus} or Higher `
                }
                reportMode={reportMode}
              >
                <PhaseIndexPlus
                  population={activePopulation}
                  condition={analysisCondition}
                  analysisPeriod={this.activePeriod()}
                />
              </LayoutBox>
              <LayoutBox
                title={this.activePeriod() !== 'C' ? 'Assumptions for Projection' : 'Key Drivers'}
                reportMode={reportMode}
              >
                <KeyDrivers
                  qualitative={this.activeQualitative()}
                  analysisPeriod={this.activePeriod()}
                  hover={keyDriversHover}
                  setHover={this.setKeyDriversHover}
                />
              </LayoutBox>
              <LayoutBox
                title={this.activePeriod() !== 'C' ? 'Risk Factors to Monitor' : 'Limiting Factors'}
                reportMode={reportMode}
              >
                <LimitingFactors
                  hover={limitingFactorsHover}
                  analysisPeriod={this.activePeriod()}
                  analysisCondition={analysisCondition}
                  setHover={this.setLimitingFactorsHover}
                />
              </LayoutBox>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getSplitView() {
    const {
      population,
      projectedPopulation,
      secondProjectedPopulation,
      icons,
      projectedIcons,
      secondProjectedIcons,
      reportMode,
      countryName,
      country,
      confidence,
      selectedArea,
      qualitative,
      projectedQualitative,
      secondProjectedQualitative,
      updateSelectedArea,
      analysisId,
      analyses,
      downloadData,
      isCountryDashboard,
      view_level,
    } = this.context;

    const { keyDriversHover, limitingFactorsHover, styleActive } = this.state;

    const toggleStylePanel = () => {
      this.setState({ styleActive: !this.state.styleActive });
    };

    const updateStyle = (key, value) => {
      const { updateStyle } = this.context;
      updateStyle(key, value);
    };

    const basemapSelect = e => {
      const basemapStyle = e.target.getAttribute('value');
      if (basemapStyle) {
        this.setState({ basemapStyle });
      }
    };

    const areaName =
      selectedArea && population
        ? population.find(d => d.aar_id === selectedArea).title
        : countryName;

    const areaQualitative =
      selectedArea && qualitative ? qualitative.find(d => d.aar_id === selectedArea) : null;

    const projectedAreaQualitative =
      selectedArea && projectedQualitative
        ? projectedQualitative.find(d => d.aar_id === selectedArea)
        : null;

    const secondProjectedAreaQualitative =
      selectedArea && secondProjectedQualitative
        ? secondProjectedQualitative.find(d => d.aar_id === selectedArea)
        : null;

    const areaConfidence =
      selectedArea && areaQualitative ? areaQualitative.confidence : confidence;

    const projectedAreaConfidence =
      selectedArea && projectedAreaQualitative ? projectedAreaQualitative.confidence : confidence;

    const secondProjectedAreaConfidence =
      selectedArea && secondProjectedAreaQualitative
        ? secondProjectedAreaQualitative.confidence
        : confidence;

    const { legendActive } = this.state;
    const onLegendToggle = active => {
      this.setState({ legendActive: active });
    };
    const legendToggle = reportMode ? null : (
      <LayoutBoxToggle title="Map Key" active={legendActive} onToggle={onLegendToggle} />
    );

    const editToggle = !isCountryDashboard ? (
      <LayoutBoxToggle
        title="Editable"
        active={this.state.editing}
        onToggle={() => {
          if (this.state.editing) this.context.updateData({ country, analysisId });
          this.setState({ editing: !this.state.editing });
        }}
      />
    ) : null;

    const wAnalysis = analyses && analyses.find(d => d.anl_id === analysisId);
    const analysisCondition = wAnalysis ? wAnalysis.condition : '';
    const conditionPhasePlus = 3;

    const analysisTitle = wAnalysis ? wAnalysis.title : '';
    const projectedActiveAnalysisDates =
      wAnalysis && 'projected_from_date' in wAnalysis && wAnalysis.projected_from_date
        ? `Projected: ${wAnalysis.projected_date_range}`
        : '';
    const secondProjectedActiveAnalysisDates =
      wAnalysis && 'second_projected_from_date' in wAnalysis && wAnalysis.second_projected_from_date
        ? `2nd Projected: ${wAnalysis.second_projected_date_range}`
        : '';
    const currentActiveAnalysisDates =
      wAnalysis && 'current_from_date' in wAnalysis && wAnalysis.current_from_date
        ? analysisCondition === 'C'
          ? `Create date ${wAnalysis.analysis_date}`
          : `Current: from ${wAnalysis.current_date_range}`
        : '';

    // excel exports are now required only to be post 2017
    // ofcourse the stupid date format always come to bite;
    const analysisCreateYear = wAnalysis
      ? wAnalysis.analysis_date.substring(
          wAnalysis.analysis_date.length,
          wAnalysis.analysis_date.length - 4
        )
      : 0;
    const iscreatedAfter2016 = analysisCreateYear >= 2017;

    const setHighlightedFeature = feature => {
      const highlighted = feature && [feature.aar_id];
      this.context.updateHighlightedAreas(highlighted);
      this.context.updateHighlightedFeature(feature);
    };

    const setSelectedCountry = feature => {
      this.setState({ selectedCountry: feature });
    };

    const addMapRef = ({ type, position, period, map }) => {
      this.mapboxMaps = {
        ...this.mapboxMaps,
        [`${type}-${position || ''}-${period || ''}`]: { type, position, map },
      };
    };

    const deleteMapRef = ({ type, position }) => {
      delete this.mapboxMaps[`${type}-${position || ''}`];
    };

    let w = 0;
    if (population && population.length) w += 1;
    if (projectedPopulation && projectedPopulation.length) w += 1;
    if (secondProjectedPopulation && secondProjectedPopulation.length) w += 1;
    if (this.state.isCHCountries) w = 2;
    const maxWidth = `${Math.floor(100 / w)}%`;

    return (
      <Container fluid>
        <Row noGutters={true}>
          {!!(population && population.length) && (
            <Col className="col col-sm" style={{ maxWidth }}>
              <Metadata
                name={areaName}
                confidence={areaConfidence}
                population={population}
                selectedArea={selectedArea}
                updateSelectedArea={updateSelectedArea}
                analysisCondition={analysisCondition}
                analysisPeriod="C"
              />
              <LayoutBox
                mapboxMaps={this.mapboxMaps}
                analysis={wAnalysis}
                country={country}
                title={analysisTitle}
                mapTitle={
                  <React.Fragment>
                    {analysisTitle} <span>{currentActiveAnalysisDates}</span>
                  </React.Fragment>
                }
                reportMode={reportMode}
                toggle={legendToggle}
                editToggle={editToggle}
                analysisId={parseInt(analysisId, 10)}
                downloadData={downloadData}
                exportImage={!this.state.editing}
                geojsonExport={true}
                analysisPeriod="C"
              >
                <Row noGutters={true} className="atlas-container">
                  {!isCountryDashboard && (
                    <AtlasStyling
                      ar
                      styleActive={styleActive}
                      handler={updateStyle}
                      toggle={toggleStylePanel}
                      basemapSelect={basemapSelect}
                      selectedGeom={this.state.selectedGeom}
                    />
                  )}
                  <Col style={{ height: '100%' }}>
                    {this.context.geojson && (
                      <AtlasNew
                        show_area_popups={this.context.show_area_popups}
                        toggleStyle={this.toggleStylePanel}
                        styleOverrides={this.context.styleOverrides}
                        activePhases={this.context.activePhases}
                        activeCondition={analysisCondition}
                        activePeriod="C"
                        saveMapRef={addMapRef}
                        deleteMapRef={deleteMapRef}
                        setHighlightedFeature={setHighlightedFeature}
                        setSelectedCountry={setSelectedCountry}
                        highlightedPhases={this.context.highlightedPhases}
                        basemapStyle={this.context.basemapStyle}
                        geojson={this.context.geojson}
                        analysisId={analysisId}
                        icons={icons}
                        highlightedFeature={this.context.highlightedFeature}
                        highlightedAreas={this.context.highlightedAreas}
                        period="C"
                        showStickyPopups={!isCountryDashboard}
                        year={this.context.year}
                        draggable={!isCountryDashboard && this.state.editing}
                      />
                    )}
                  </Col>
                  <Col md="300px">
                    <AtlasLegend
                      active={legendActive || reportMode}
                      position={reportMode ? 'bottom' : null}
                      analysisPeriod="C"
                      analysisCondition={analysisCondition}
                    />
                  </Col>
                </Row>
              </LayoutBox>
              <LayoutBox
                title="Area Population Estimates"
                analysisId={parseInt(analysisId, 10)}
                xlsxExport={iscreatedAfter2016}
                downloadData={downloadData}
                reportMode={reportMode}
                exportFull
                analysisPeriod="C"
              >
                <PopulationEstimates
                  population={population}
                  view_level={view_level}
                  split
                  analysisCondition={analysisCondition}
                  analysisPeriod="C"
                />
              </LayoutBox>
              <LayoutBox
                title="Interventions and Actions"
                reportMode={reportMode}
                analysisPeriod="C"
              >
                {analysisCondition === 'C' ? <ChronicInterventions /> : <AcuteInterventions />}
              </LayoutBox>
              <LayoutBox
                title={analysisCondition === 'C' ? `Chronic Phases` : `Acute Phases`}
                reportMode={reportMode}
                analysisPeriod="C"
              >
                {analysisCondition === 'C' ? (
                  <ChronicPhase population={population} />
                ) : (
                  <AcutePhase population={population} />
                )}
              </LayoutBox>
              <LayoutBox
                title={
                  analysisCondition === 'C'
                    ? `Level ${conditionPhasePlus} or Higher `
                    : `Phase ${conditionPhasePlus} or Higher `
                }
                reportMode={reportMode}
                analysisPeriod="C"
              >
                <PhaseIndexPlus
                  population={population}
                  condition={analysisCondition}
                  analysisPeriod="C"
                />
              </LayoutBox>
              <LayoutBox title="Key Drivers" reportMode={reportMode} analysisPeriod="C">
                <KeyDrivers
                  qualitative={qualitative}
                  hover={keyDriversHover}
                  setHover={this.setKeyDriversHover}
                  analysisPeriod="C"
                />
              </LayoutBox>
              <LayoutBox title="Limiting Factors" reportMode={reportMode} analysisPeriod="C">
                <LimitingFactors
                  hover={limitingFactorsHover}
                  setHover={this.setLimitingFactorsHover}
                  analysisPeriod="C"
                  analysisCondition={analysisCondition}
                />
              </LayoutBox>
            </Col>
          )}

          {!!(projectedPopulation && projectedPopulation.length) && (
            <Col className="col col-sm" style={{ maxWidth }}>
              <div className="inner-padding">
                <Row>
                  <Col className="col-md-11">
                    <Metadata
                      name={areaName}
                      confidence={projectedAreaConfidence}
                      population={projectedPopulation}
                      selectedArea={selectedArea}
                      updateSelectedArea={updateSelectedArea}
                      analysisCondition={analysisCondition}
                      analysisPeriod="P"
                    />
                  </Col>
                  {!(secondProjectedPopulation && secondProjectedPopulation.length) && (
                    <Col
                      onClick={this.toggleSplit}
                      className="analyses-block__projected-button col-md-1"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Col>
                  )}
                </Row>
                <LayoutBox
                  mapboxMaps={this.mapboxMaps}
                  analysis={wAnalysis}
                  country={country}
                  title={analysisTitle}
                  mapTitle={
                    <React.Fragment>
                      {analysisTitle} <span>{projectedActiveAnalysisDates}</span>
                    </React.Fragment>
                  }
                  reportMode={reportMode}
                  toggle={legendToggle}
                  editToggle={editToggle}
                  analysisId={parseInt(analysisId, 10)}
                  downloadData={downloadData}
                  exportImage={!this.state.editing}
                  geojsonExport={true}
                  analysisPeriod="P"
                >
                  <div className="atlas-container inner-padding-none">
                    <AtlasNew
                      show_area_popups={this.context.show_area_popups}
                      styleOverrides={this.context.styleOverrides}
                      activePhases={this.context.activePhases}
                      activeCondition={analysisCondition}
                      activePeriod="P"
                      saveMapRef={addMapRef}
                      deleteMapRef={deleteMapRef}
                      setHighlightedFeature={setHighlightedFeature}
                      setSelectedCountry={setSelectedCountry}
                      highlightedPhases={this.context.highlightedPhases}
                      basemapStyle={this.context.basemapStyle}
                      geojson={this.context.projectedGeojson}
                      analysisId={analysisId}
                      icons={projectedIcons}
                      highlightedFeature={this.state.highlightedFeature}
                      highlightedAreas={this.context.highlightedAreas}
                      period="P"
                      showStickyPopups={!isCountryDashboard}
                      year={this.context.year}
                      draggable={!isCountryDashboard && this.state.editing}
                    />
                    <AtlasLegend
                      active={legendActive || reportMode}
                      position={reportMode ? 'bottom' : null}
                      analysisPeriod="P"
                      analysisCondition={analysisCondition}
                    />
                  </div>
                </LayoutBox>
                <LayoutBox
                  title="Area Population Estimates"
                  analysisId={parseInt(analysisId, 10)}
                  xlsxExport={iscreatedAfter2016}
                  downloadData={downloadData}
                  analysisPeriod="P"
                  reportMode={reportMode}
                >
                  <PopulationEstimates
                    view_level={view_level}
                    population={projectedPopulation}
                    analysisCondition={analysisCondition}
                    analysisPeriod="P"
                    exportFull
                    split
                  />
                </LayoutBox>
                <LayoutBox
                  title="Interventions and Actions"
                  analysisPeriod="P"
                  reportMode={reportMode}
                >
                  {analysisCondition === 'C' ? <ChronicInterventions /> : <AcuteInterventions />}
                </LayoutBox>
                <LayoutBox
                  title={analysisCondition === 'C' ? `Chronic Phases` : `Acute Phases`}
                  analysisPeriod="P"
                  reportMode={reportMode}
                >
                  <AcutePhase population={projectedPopulation} projected />
                </LayoutBox>
                <LayoutBox
                  title={
                    analysisCondition === 'C'
                      ? `Level ${conditionPhasePlus} or Higher `
                      : `Phase ${conditionPhasePlus} or Higher `
                  }
                  analysisPeriod="P"
                  reportMode={reportMode}
                >
                  <PhaseIndexPlus population={projectedPopulation} analysisPeriod="P" />
                </LayoutBox>
                <LayoutBox
                  title="Assumptions for Projection"
                  analysisPeriod="P"
                  reportMode={reportMode}
                >
                  <KeyDrivers
                    qualitative={projectedQualitative}
                    hover={keyDriversHover}
                    setHover={this.setKeyDriversHover}
                    analysisPeriod="P"
                  />
                </LayoutBox>
                <LayoutBox
                  title="Risk Factors to Monitor"
                  analysisPeriod="P"
                  reportMode={reportMode}
                >
                  <LimitingFactors
                    hover={limitingFactorsHover}
                    setHover={this.setLimitingFactorsHover}
                    analysisPeriod="P"
                    analysisCondition={analysisCondition}
                  />
                </LayoutBox>
              </div>
            </Col>
          )}

          {!!(
            secondProjectedPopulation &&
            secondProjectedPopulation.length &&
            secondProjectedActiveAnalysisDates
          ) && (
            <Col className="col col-sm" style={{ maxWidth }}>
              <div className="inner-padding">
                <Row>
                  <Col className="col-md-11">
                    <Metadata
                      name={areaName}
                      confidence={secondProjectedAreaConfidence}
                      population={secondProjectedPopulation}
                      selectedArea={selectedArea}
                      updateSelectedArea={updateSelectedArea}
                      analysisCondition={analysisCondition}
                      analysisPeriod="A"
                    />
                  </Col>
                  <Col
                    onClick={this.toggleSplit}
                    className="analyses-block__projected-button col-md-1"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Col>
                </Row>

                {/*
            https://stackoverflow.com/questions/33381029/react-how-to-pass-html-tags-in-props#answer-59212609 
            passing a mapTitle because React Fragment is an object. Title is required by export component as a string
            */}
                <LayoutBox
                  mapboxMaps={this.mapboxMaps}
                  analysis={wAnalysis}
                  country={country}
                  title={analysisTitle}
                  mapTitle={
                    <React.Fragment>
                      {analysisTitle} <span>{secondProjectedActiveAnalysisDates}</span>
                    </React.Fragment>
                  }
                  reportMode={reportMode}
                  toggle={legendToggle}
                  editToggle={editToggle}
                  analysisId={parseInt(analysisId, 10)}
                  downloadData={downloadData}
                  exportImage={!this.state.editing}
                  geojsonExport={true}
                  analysisPeriod="A"
                >
                  <div className="atlas-container">
                    <AtlasNew
                      show_area_popups={this.context.show_area_popups}
                      styleOverrides={this.context.styleOverrides}
                      activePhases={this.context.activePhases}
                      activeCondition={analysisCondition}
                      activePeriod="A"
                      saveMapRef={addMapRef}
                      deleteMapRef={deleteMapRef}
                      setHighlightedFeature={setHighlightedFeature}
                      setSelectedCountry={setSelectedCountry}
                      highlightedPhases={this.context.highlightedPhases}
                      basemapStyle={this.context.basemapStyle}
                      geojson={this.context.secondProjectedGeojson}
                      analysisId={analysisId}
                      icons={secondProjectedIcons}
                      highlightedFeature={this.state.highlightedFeature}
                      highlightedAreas={this.context.highlightedAreas}
                      period="A"
                      showStickyPopups={!isCountryDashboard}
                      year={this.context.year}
                      draggable={!isCountryDashboard && this.state.editing}
                    />
                    <AtlasLegend
                      active={legendActive || reportMode}
                      position={reportMode ? 'bottom' : null}
                      analysisPeriod="P"
                      analysisCondition={analysisCondition}
                    />
                  </div>
                </LayoutBox>
                <LayoutBox
                  title="Area Population Estimates"
                  analysisId={parseInt(analysisId, 10)}
                  xlsxExport={iscreatedAfter2016}
                  downloadData={downloadData}
                  analysisPeriod="A"
                  reportMode={reportMode}
                >
                  <PopulationEstimates
                    view_level={view_level}
                    population={secondProjectedPopulation}
                    analysisCondition={analysisCondition}
                    analysisPeriod="A"
                    exportFull
                    split
                  />
                </LayoutBox>
                <LayoutBox
                  title="Interventions and Actions"
                  analysisPeriod="A"
                  reportMode={reportMode}
                >
                  {analysisCondition === 'C' ? <ChronicInterventions /> : <AcuteInterventions />}
                </LayoutBox>
                <LayoutBox
                  title={analysisCondition === 'C' ? `Chronic Phases` : `Acute Phases`}
                  analysisPeriod="A"
                  reportMode={reportMode}
                >
                  <AcutePhase population={secondProjectedPopulation} analysisPeriod="A" />
                </LayoutBox>
                <LayoutBox
                  title={
                    analysisCondition === 'C'
                      ? `Level ${conditionPhasePlus} or Higher `
                      : `Phase ${conditionPhasePlus} or Higher `
                  }
                  analysisPeriod="A"
                  reportMode={reportMode}
                >
                  <PhaseIndexPlus population={secondProjectedPopulation} analysisPeriod="A" />
                </LayoutBox>
                <LayoutBox
                  title="Assumptions for Projection"
                  analysisPeriod="A"
                  reportMode={reportMode}
                >
                  <KeyDrivers
                    qualitative={secondProjectedQualitative}
                    hover={keyDriversHover}
                    setHover={this.setKeyDriversHover}
                    analysisPeriod="A"
                  />
                </LayoutBox>
                <LayoutBox
                  title="Risk Factors to Monitor"
                  analysisPeriod="A"
                  reportMode={reportMode}
                >
                  <LimitingFactors
                    hover={limitingFactorsHover}
                    setHover={this.setLimitingFactorsHover}
                    analysisPeriod="A"
                    analysisCondition={analysisCondition}
                  />
                </LayoutBox>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    );
  }

  getNoDataView() {
    const noPopData = (
      <div className="inner-padding margin-t-20 margin-b-10">
        <p>No Population Data for this analysis.</p>
      </div>
    );
    return (
      <React.Fragment>
        <Metadata />
        <div className="row no-gutters">
          <div className="col-lg-9 col-md-9">
            <LayoutBox title="Map View" exportable={false}>
              {noPopData}
            </LayoutBox>
            <LayoutBox title="Population Data" exportable={false}>
              {noPopData}
            </LayoutBox>
            <div>
              <LayoutBox title="Interventions and Actions" exportable={false}>
                {noPopData}
              </LayoutBox>
            </div>
          </div>
          <div className={`col-lg-3 col-md-3`}>
            <div className="inner-padding">
              <LayoutBox title="Acute/Chronic Phases" exportable={false}>
                {noPopData}
              </LayoutBox>
              <LayoutBox title="Phase/Level 3 or Higher" exportable={false}>
                {noPopData}
              </LayoutBox>
              <LayoutBox title="Key Drivers" exportable={false}>
                {noPopData}
              </LayoutBox>
              <LayoutBox title="Limiting Factors" exportable={false}>
                {noPopData}
              </LayoutBox>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getCharts() {
    const { view, nodata } = this.context;

    if (nodata) return this.getNoDataView();

    if (view === 'split') {
      return this.getSplitView();
    }
    return this.getSingleView();
  }

  render() {
    return (
      <div className="content">
        <div className="content__main">{this.getCharts()}</div>
      </div>
    );
  }
}

ContentBlock.contextType = DataContext;

export default ContentBlock;
